<template>
  <div class="marquee" ref="container" @mouseover="speedFactor = 1" @mouseleave="speedFactor = 1">
    <div class="content" ref="content" :style="translateX">
      {{ playText }}
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        speed: 1.2,
        totalX: 0,
        offsetX: 0,
        isPlaying: false,
        raf: null,
        speedFactor: 1, // 0 ~ 1 float
        playText: '',
        stackText: [],
      };
    },
    computed: {
      translateX() {
        return {
          transform: `translateX(${this.offsetX}px)`,
        };
      },
    },
    watch: {
      stackText: {
        handler() {
          if (!this.isPlaying) {
            this.updatePlayMarquee();
          }
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      play() {
        this.isPlaying = true;
      },
      pause() {
        this.isPlaying = false;
      },
      stop() {
        this.isPlaying = false;
        this.offsetX = 0;
      },
      pushStack(data) {
        this.stackText.push(data);
      },
      updatePlayMarquee() {
        if (this.stackText.length !== 0) {
          this.stop();
          this.play();
          if (this.stackText[0][0].length !== 0) {
            this.playText = this.stackText[0][0];
          }
        } else {
          this.isPlaying = false;
        }
      },
    },
    mounted() {
      const maxFPS = 60;
      const fpsInterval = 1000 / maxFPS;
      let elapsed = 0;
      let then = Date.now();
      const updateFunc = (timestamp) => {
        this.raf = requestAnimationFrame(updateFunc);

        const now = Date.now();
        elapsed = now - then;
        if (elapsed > fpsInterval) {
          then = now - (elapsed % fpsInterval);

          if (this.isPlaying) {
            const { container, content } = this.$refs;
            this.totalX = container.clientWidth + content.clientWidth;
            this.offsetX -= this.speed * this.speedFactor;
            if (this.offsetX < -this.totalX) {
              this.offsetX = 0;

              this.stackText[0].shift();
              if (this.stackText[0].length === 0) {
                this.stackText.shift();
              }
              this.updatePlayMarquee();
            }
          }
        }
      };
      requestAnimationFrame(updateFunc);
    },
    beforeDestroy() {
      cancelAnimationFrame(this.raf);
    },
  };
</script>

<style lang="scss" scoped>
  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;

    color: #000;
    font-size: 1rem;
    line-height: normal;
    white-space: nowrap;

    .content {
      display: inline-block !important;
      position: relative;
      left: 100%;
      transform: translateX(0%);
    }
  }
</style>
