<template>
  <div v-if="hasAnyTeamInLeague" class="mBoldTable" :class="{ closed: !isExpanded }">
    <div class="leagueNameBar" @click="toggleCollapse()">
      {{ source.LeagueNameStr }}
      <img class="arrow" src="@/assets/img/mobile/btn_arrow_w.svg" />
    </div>
    <div v-if="isExpanded" class="teams">
      <template v-if="isBoldGame">
        <div v-for="(teamData, teamIndex) in boldTeamData" :key="teamIndex" class="team">
          <div class="info-bar">
            <div class="time">
              {{ $lib.timeFormatMMDD(teamData.ScheduleTimeStr) }}
              {{ $lib.timeFormatHHmm(teamData.ScheduleTimeStr) }}
            </div>
            <div class="info">
              <span>
                {{ teamData.HomeTeamStr }}
              </span>
              <span> &nbsp; vs &nbsp; </span>
              <span>
                {{ teamData.AwayTeamStr }}
              </span>
            </div>
            <div
              class="star"
              :class="starCSSJudge(teamData.EvtID)"
              @click="addFavoriteHandler(teamData.EvtID)"
            />
          </div>
          <table>
            <tbody>
              <tr class="title">
                <td>{{ $t('Common.Home') }}</td>
                <td>{{ $t('Common.Drew') }}</td>
                <td>{{ $t('Common.Away') }}</td>
              </tr>
              <tr v-for="(_, i) in maxRows" :key="i">
                <td v-for="(template, j) in boldTemplatesFilter" :key="j">
                  <div
                    v-if="template[i] !== undefined"
                    class="btnBet"
                    :class="{
                      isSelected: isSelected(teamData.boldOddMapData[template[i]], template[i]),
                    }"
                    @click="
                      goBoldBet(
                        boldOddMapDataDrewOdds(teamData.boldOddMapData[template[i]]),
                        teamData.boldOddMapData[template[i]],
                        teamData
                      )
                    "
                  >
                    <div class="betName"> {{ template[i] }} </div>
                    <div class="betOdds">
                      {{ boldOddMapDataDrewOdds(teamData.boldOddMapData[template[i]]) }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-else-if="isGoals">
        <div v-for="(teamData, teamIndex) in source.Team" :key="teamIndex" class="team">
          <div class="info-bar">
            <div class="time">
              {{ $lib.timeFormatMMDD(teamData.ScheduleTimeStr) }}
              {{ $lib.timeFormatHHmm(teamData.ScheduleTimeStr) }}
            </div>
            <div class="info">
              <span>
                {{ teamData.HomeTeamStr }}
              </span>
              <span> &nbsp; vs &nbsp; </span>
              <span>
                {{ teamData.AwayTeamStr }}
              </span>
            </div>
            <div
              class="star"
              :class="starCSSJudge(teamData.EvtID)"
              @click="addFavoriteHandler(teamData.EvtID)"
            />
          </div>
          <table>
            <tbody>
              <tr>
                <td class="fix4">
                  <div
                    v-for="(headData, headIndex) in goalsHead"
                    :key="headIndex"
                    class="btnBet"
                    :class="{
                      isSelected: isSelected2(goalsWagerOdds(goalsWager(teamData)[headIndex])),
                    }"
                    @click="goBet(goalsWager(teamData)[headIndex], teamData)"
                  >
                    <div class="betName">
                      {{ headData.showName }}
                    </div>
                    <div class="betOdds">
                      {{ adjustedOdds(goalsWagerOddsDrew(goalsWager(teamData)[headIndex])) }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-else-if="isHalfAll">
        <div v-for="(teamData, teamIndex) in source.Team" :key="teamIndex" class="team">
          <div class="info-bar">
            <div class="time">
              {{ $lib.timeFormatMMDD(teamData.ScheduleTimeStr) }}
              {{ $lib.timeFormatHHmm(teamData.ScheduleTimeStr) }}
            </div>
            <div class="info">
              <span>
                {{ teamData.HomeTeamStr }}
              </span>
              <span> &nbsp; vs &nbsp; </span>
              <span>
                {{ teamData.AwayTeamStr }}
              </span>
            </div>
            <div
              class="star"
              :class="starCSSJudge(teamData.EvtID)"
              @click="addFavoriteHandler(teamData.EvtID)"
            />
          </div>
          <table>
            <tbody>
              <tr>
                <td class="fix5">
                  <div
                    v-for="(headData, headIndex) in bestHead[0]"
                    :key="headIndex"
                    class="btnBet"
                    :class="{
                      isSelected: isSelected2(teamData.Wager[headIndex].Odds[0]),
                    }"
                    @click="goBet(teamData.Wager[headIndex], teamData)"
                  >
                    <div class="betName">
                      {{ headData.showName }}
                    </div>
                    <div class="betOdds">
                      {{ adjustedOdds(teamData.Wager[headIndex].Odds[0].DrewOdds) }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'mBoldTable',
    props: {
      source: {
        type: Object,
        default: null,
      },
      bestHead: {
        type: Array,
        default() {
          return [];
        },
      },
      isExpanded: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        limit: 0.01,
        templates: [
          ['1-0', '2-0', '2-1', '3-0', '3-1', '3-2', '4-0', '4-1', '4-2', '4-3'],
          ['0-0', '1-1', '2-2', '3-3', '4-4', 'other'],
          ['0-1', '0-2', '1-2', '0-3', '1-3', '2-3', '0-4', '1-4', '2-4', '3-4'],
        ],
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      isBoldGame() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 3:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isGoals() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 4:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isHalfAll() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 5:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      goalsHead() {
        if (this.boldFilterType) {
          return this.bestHead[1];
        } else {
          return this.bestHead[2];
        }
      },
      maxRows() {
        return Math.max(...this.boldTemplatesFilter.map((it) => it.length));
      },
      boldFilterType() {
        return this.$store.state.Game.boldFilterType;
      },
      boldTeamData() {
        const newData = this.source.Team.map(this.teamDataMapLogic)
          .filter((it) => it.Wager.length !== 0)
          .map((it) => {
            const newIt = JSON.parse(JSON.stringify(it));
            newIt.boldOddMapData = this.boldOddToMapData(it.Wager[0].Odds);
            return newIt;
          });
        return newData;
      },
      boldTemplatesFilter() {
        // 全場
        if (this.boldFilterType) {
          return this.templates;
        } else {
          // 半場
          return this.templates.map((template) => {
            return template.filter((it) => it.indexOf('4') === -1);
          });
        }
      },
      hasAnyTeamInLeague() {
        return this.boldTeamData.length > 0;
      },
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
      includePrincipal() {
        return this.$store.state.Setting.UserSetting.includePrincipal;
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
    },
    methods: {
      adjustedOdds(odds) {
        if (!odds) return '';
        return (parseFloat(odds) + this.$store.getters.userOddsAdjustment).toFixed(3);
      },
      isSelected2(oddData) {
        if (oddData && this.betCartList.length) {
          const compareData = this.betCartList.find(
            (cartData) => cartData.GameID === oddData.GameID
          );
          return compareData;
        }
        return false;
      },
      goBet(wagerData, teamData) {
        const [oddsData] = wagerData.Odds;
        const { GameID } = oddsData;
        if (!oddsData.DrewOdds) return;
        const odds = (
          parseFloat(oddsData.DrewOdds) + this.$store.getters.userOddsAdjustment
        ).toFixed(3);
        if (odds <= this.limit) return;

        this.$emit('AddToCart');

        const { GameType } = wagerData;
        const GameTypeLabel = this.gameStore.GameTypeList.find(
          (item) => item.key === GameType
        )?.value;

        const { WagerTypeID, WagerGrpID } = wagerData;
        const [headList] = this.bestHead;
        const head = headList.find((headData) => {
          const { WagerTypeIDs, WagerGrpIDs, OULine } = headData;
          return (
            WagerTypeIDs.includes(WagerTypeID) &&
            (WagerGrpIDs.includes(WagerGrpID) || WagerGrpIDs.includes(128)) &&
            OULine === oddsData.OULine
          );
        });

        const { WagerGrpName, showName: OULineName } = head;
        const WagerTypeName = this.$store.getters['Game/selectWagerTypeName'];
        let ItemName = '';
        if ([119].includes(WagerTypeID) && [1, 11, 21].includes(WagerGrpID)) {
          ItemName = `${WagerGrpName} ${WagerTypeName}`;
        } else {
          ItemName = `${WagerTypeName}`;
        }

        const { CatID, CatNameStr, ModID, LeagueID, LeagueNameStr } = this.source;
        const {
          HomeID,
          HomeTeamStr,
          AwayID,
          AwayTeamStr,
          SetFlag,
          ScheduleTimeStr: TimeStr,
          EvtID,
          EvtStatus,
          LiveTV,
          LiveTVLink,
        } = teamData;

        const betInfoData = {
          GameTypeID: GameType,
          GameTypeLabel,
          CatID,
          CatNameStr,
          ModID,
          LeagueID,
          LeagueNameStr,
          HomeID,
          HomeTeamStr,
          AwayID,
          AwayTeamStr,
          SetFlag,
          TimeStr,
          EvtID,
          EvtStatus,
          WagerGrpID,
          WagerTypeID,
          GameID,
          OriginShowOdd: parseFloat(odds),
          wagerPos: 3,
          ItemName,
          OULineName,
          LiveTV,
          LiveTVLink,
          ...oddsData,
        };

        this.$store.dispatch('BetCart/addCart', betInfoData);
      },
      goalsWager(teamData) {
        if (this.boldFilterType) {
          return teamData.Wager1;
        } else {
          return teamData.Wager2;
        }
      },
      goalsWagerOdds(wager) {
        if (wager?.Odds) return wager.Odds[0];
        return null;
      },
      goalsWagerOddsDrew(wager) {
        if (wager?.Odds) return wager.Odds[0].DrewOdds;
        return null;
      },
      toggleCollapse() {
        this.$emit('toggleCollapse');
      },
      teamDataMapLogic(teamData) {
        const GAME_FULL_GROUPS = this.$conf.GAME_FULL_GROUPS;
        const newTeamData = JSON.parse(JSON.stringify(teamData));
        newTeamData.Wager = teamData.Wager.filter((wagerData) => {
          if (wagerData?.isNoData) {
            return false;
          } else {
            // 全場
            if (this.boldFilterType) {
              return GAME_FULL_GROUPS.indexOf(wagerData.WagerGrpID) !== -1;
            } else {
              // 半場
              return GAME_FULL_GROUPS.indexOf(wagerData.WagerGrpID) === -1;
            }
          }
        });
        return newTeamData;
      },
      boldOddToMapData(boldOdd) {
        return boldOdd.reduce(
          (sum, it, index) => ({
            ...sum,
            ...{
              [it.OULine]: it,
            },
          }),
          {}
        );
      },
      boldOddMapDataDrewOdds(param) {
        if (!param) return '';
        if (!param.DrewOdds) return '';
        const odds = (parseFloat(param.DrewOdds) + this.$store.getters.userOddsAdjustment).toFixed(
          3
        );
        if (odds === '') return '';
        if (odds === undefined) return '';
        if (odds === null) return '';
        if (this.includePrincipal) {
          const value = this.$lib.trunc(parseFloat(odds) + 1);
          var arrayNum = value.toString().split('.');
          if (arrayNum.length === 1) {
            return value.toString() + '.00';
          }
          if (arrayNum.length > 1) {
            if (arrayNum[1].length < 2) {
              return value.toString() + '0';
            }
            return value;
          }
          return value;
        } else {
          return odds;
        }
      },
      goBoldBet(showOdd, oddData, teamData) {
        console.warn(oddData);
        if (showOdd === undefined) {
          return;
        }
        this.$emit('AddToCart');

        const { GameType } = teamData.Wager[0];
        const GameTypeLabel = this.$store.state.Game.GameTypeList.find(
          (it) => it.key === GameType
        )?.value;
        const { LiveTV = '', LiveTVLink = '' } = teamData;
        const betInfoData = {
          LeagueID: this.source.LeagueID,
          HomeID: teamData.HomeID,
          AwayID: teamData.AwayID,
          TimeStr: teamData.ScheduleTimeStr,
          OriginShowOdd: parseFloat(showOdd),
          wagerPos: 3,
          GameTypeID: GameType,
          GameTypeLabel: GameTypeLabel,
          GameID: oddData.GameID,
          CatID: this.source.CatID,
          CatNameStr: this.source.CatNameStr,
          LeagueNameStr: this.source.LeagueNameStr,
          HomeTeamStr: teamData?.HomeTeamStr,
          AwayTeamStr: teamData?.AwayTeamStr,
          WagerGrpID: 10,
          WagerTypeID: 112,
          EvtID: teamData.EvtID,
          EvtStatus: teamData.EvtStatus,
          SetFlag: teamData.SetFlag,
          LiveTV,
          LiveTVLink,
          ...oddData,
        };

        this.$store.dispatch('BetCart/addCart', betInfoData);
      },
      isSelected(oddData, OULine) {
        if (this.betCartList.length) {
          const compareData = this.betCartList.find(
            (cartData) => cartData.GameID === oddData?.GameID
          );
          return compareData && compareData.OULine === OULine;
        }
      },
      starCSSJudge(EvtID) {
        if (this.$store.state.Setting.UserSetting.favorites.indexOf(EvtID) > -1) {
          return 'active';
        } else {
          return '';
        }
      },
      addFavoriteHandler(EvtID) {
        this.$store.dispatch('Setting/addFavorites', EvtID);
      },
      moreGameClickHandler(teamData) {
        this.$store.dispatch('MoreGame/openMoreGameList', {
          teamData,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mBoldTable {
    &.closed {
      .leagueNameBar {
        .arrow {
          display: block;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #ccc;
        }
      }
    }
    .leagueNameBar {
      position: relative;
      display: flex;
      align-items: center;
      height: 30px;
      color: #444;
      overflow-x: auto;
      overflow-y: hidden;
      cursor: pointer;
      padding-left: 7px;
      background-color: #e8e8e8;
      .arrow {
        display: none;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1.1rem;
        filter: invert(30%);
      }
    }
    .team {
      .info-bar {
        display: flex;
        height: 30px;
        align-items: center;
        background-color: #f3f3f3;
        & > * {
          line-height: 1;
        }

        .time {
          color: #bbbbbb;
          margin: 0 7px;
        }

        .star {
          width: 15px;
          height: 15px;
          background: url('~@/assets/img/common/icon_star.svg') no-repeat center bottom;
          background-size: 100% auto;
          background-position: center bottom;
          background-repeat: no-repeat;
          margin-left: 8px;
          display: block;
          cursor: pointer;

          &:active,
          &.active {
            background-position: center top;
          }
        }
        .more {
          min-width: 45px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1;
          margin-left: auto;
          border-left: 1px solid #e8e8e8;
        }
      }

      table {
        width: 100%;
        border: 0;
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;
        background-color: #fff;
        font-size: 1.1rem;

        tr {
          width: 100%;
        }

        tr.title td {
          text-align: center;
          color: #888888;
          height: 35px;
        }

        td {
          //height: 35px;
          border-style: solid;
          border-color: #e8e8e8;
          border-width: 1px 1px 0 0;

          &.fix4 {
            display: flex;
            flex-wrap: wrap;
            border-width: 0 0 1px 0;

            .btnBet {
              flex: 50%;
              height: 28px;
              line-height: 28px;
              border-style: solid;
              border-color: #e8e8e8;
              border-width: 1px 1px 0 0;
            }
          }

          &.fix5 {
            display: flex;
            flex-wrap: wrap;
            border-width: 0 0 1px 0;

            .btnBet {
              flex: 33.33%;
              display: block;
              border-style: solid;
              border-color: #e8e8e8;
              border-width: 1px 1px 0 0;

              .betName {
                width: 100%;
                height: 24px;
                line-height: 24px;
                text-align: center;
              }
              .betOdds {
                width: 100%;
                height: 24px;
                line-height: 24px;
                text-align: center;
                color: #30679e;
                font-weight: bold;
              }
            }
          }

          .btnBet {
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            &:active {
              background-color: #ffe1ae;
            }
            &.isSelected {
              background-color: #ffd5d5;
            }
            .betName {
              flex: 50%;
              text-align: center;
            }
            .betOdds {
              flex: 50%;
              text-align: center;
              color: #3fa381;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    .mBoldTable {
      .leagueNameBar {
        background-color: #696969;
        color: #fff;
        &::after {
          background-color: #404040;
        }
      }

      .team {
        .info-bar {
          background-color: #606060;
          color: #fff;
          .more {
            border-color: #696969;
          }
        }

        table {
          tr.title td {
            color: #bbbbbb;
          }
          background-color: #585858;
          color: #fff;
          td {
            border-color: #5f5f5f;
            .btnBet {
              &:active {
                background-color: #777777;
              }
              &.isSelected {
                background-color: #ffffff;
                .betName {
                  color: #000000;
                }
                .betOdds {
                  color: #3fa381;
                }
              }
              .betOdds {
                color: #9adeff;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
</style>
