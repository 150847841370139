<template>
  <div id="BetViewList" ref="BetViewList" v-loading="appLoading">
    <template v-if="groupIndex === 0">
      <template v-if="isQuickBetEnable">
        <div class="centerTipBlock">
          <div class="centerTip">
            <div class="tipItem">
              {{ $t('QuickBet.open') }}
              <span style="color: red">{{ $t('QuickBet.fastBet') }}</span>
              {{ $t('QuickBet.mode') }}
            </div>
            <div class="tipItem">
              {{ $t('QuickBet.setAmount') }}
            </div>
            <div class="tipItem">
              {{ $t('QuickBet.ClickOdd') }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="isShowChartList || isShowCharStrayList">
          <listCardItem
            v-for="(cartData, cartIndex) in showBetCartList"
            :cartData="cartData"
            :childIndex="childIndex"
            :cartIndex="cartIndex"
            :listCardItemClassJudge="listCardItemClassJudge(cartData.GameID, cartData)"
            :currShowKeyboardIndex="currShowKeyboardIndex"
            :key="cartData.GameID"
            :isControlByBetSingle="isControlByBetSingle"
            :isShowMinText="cartData.isShowMinText"
            :isShowMaxText="cartData.isShowMaxText"
            :isShowMaxChip="isShowMaxChip"
            :theMaxChipValue="theMaxChipValue"
            :wholeFocusTarget="lastClickInput"
            @cancelSingleHandler="cancelSingleHandler"
            @inputRowItemChangeHandler="inputRowItemChangeHandler"
            @onCartListItemKeyboardShow="onCartListItemKeyboardShow"
            @inputFocusEvent="inputFocusEvent"
            @lastBlurInputEvent="listCardItemLastBlurInputEvent"
            @Add="keyBoardAddEvent"
            @Assign="keyBoardAssignEvent"
            @MobileListItemSubmitBet="submitPlay"
            @clearItemInputByIndex="clearItemInputByIndex"
            :strayErrors="strayErrors"
          />
        </template>

        <div
          v-if="MOBILE_APP && isControlByBetSingle && panelMode == PanelModeEnum.lock"
          class="cardOptionBlock"
        >
          <div class="buttonRow">
            <div class="submitBtn" style="text-align: center" @click="submitPlay">
              {{ $t('Common.SubmitBet') }}
            </div>
          </div>
        </div>

        <div v-if="isShowChartList && !isControlByBetSingle" class="cardOptionBlock">
          <div class="betRows-wrap">
            <ul v-if="panelMode === PanelModeEnum.normal" class="betInputRow">
              <li class="betInputTitle">
                {{ $t('Common.SingleOdd') }}
              </li>
              <li>
                <input
                  v-model="fillEachBetAmount"
                  ref="fillEachBetAmount"
                  type="number"
                  :class="{ gray: lastClickInput === 2 }"
                  :readonly="MOBILE_APP"
                  @focus="
                    onInputFocus();
                    lastClickInput = 1;
                  "
                  @blur="fillEachBetAmountBlurHandler"
                  @click="
                    isShowBetKB = lastClickInput !== 1 || !isShowBetKB;
                    lastClickInput = 1;
                  "
                  @input="fillEachBetAmountHandler"
                />
                <div class="betInputRowAbsoluteBlock"> x {{ showBetCartList.length }} </div>
                <span v-if="fillEachBetAmount" class="btnClearValue" @click="clearInput('normal')">
                  <i class="el-icon-error" />
                </span>
              </li>
            </ul>
            <ul v-if="panelMode === PanelModeEnum.normal" class="betInputRow">
              <li class="betInputTitle">
                {{ $t('Common.CanWinMoney') }}
              </li>
              <li>
                <input
                  v-model="fillEachWinAmount"
                  type="number"
                  :class="{ gray: lastClickInput === 1 }"
                  :readonly="MOBILE_APP"
                  @focus="
                    onInputFocus();
                    lastClickInput = 2;
                  "
                  @blur="fillEachWinAmountBlurHandler"
                  @click="
                    isShowBetKB = lastClickInput !== 2 || !isShowBetKB;
                    lastClickInput = 2;
                  "
                  @input="fillEachWinAmountHandler"
                />
                <div class="betInputRowAbsoluteBlock"> x {{ showBetCartList.length }} </div>
                <span v-if="fillEachWinAmount" class="btnClearValue" @click="clearInput('normal')">
                  <i class="el-icon-error" />
                </span>
              </li>
            </ul>
          </div>

          <!-- 小鍵盤 -->
          <mBetKeyboard
            v-if="MOBILE_APP && isShowBetKB && panelMode === PanelModeEnum.normal"
            :isShowMaxChip="isShowMaxChip"
            :theMaxChipValue="theMaxChipValue"
            @Add="keyBoardAddEvent"
            @Assign="keyBoardAssignEvent"
          />

          <!-- 小籌碼 -->
          <ChipsBar
            v-if="!MOBILE_APP && panelMode === PanelModeEnum.normal"
            :isShowMaxChip="isShowMaxChip"
            :theMaxChipValue="theMaxChipValue"
            @onChipClick="onChipClick"
          />

          <div class="amountTipRow" v-if="isShowStatusData2">
            <img src="@/assets/img/common/icon_event.svg" alt="" />

            <div style="margin-left: 10px">
              {{ $t('BetViewList.HaveChoose') }} <span style="color: #ffea01">{{ tipAmount }}</span>
              {{ $t('BetViewList.Change') }}
            </div>
          </div>

          <div class="totalRow">
            <div class="halfItem">
              {{ $t('BetViewList.TotalBet') }} : {{ toCurrency(totalBetAmount) }}
            </div>
            <div class="halfItem">
              {{ $t('Common.CanWinMoney') }} :
              <span v-if="isBetterOdds" :style="winAmountColor">
                {{ toCurrency(betterOddsWinAmount) }}
              </span>
              <span
                v-else
                :style="isWinAmountChangeColor ? winAmountColor : ''"
                :class="betDataChangeList.length !== 0 ? 'halfItemChange' : ''"
              >
                {{ toCurrency(totalWinAmount) }}
              </span>
            </div>
          </div>
          <div class="buttonRow">
            <div class="clearBtn" @click="resultLeftBtnClickHandler()">
              {{ OptionCancelBtnStr }}
            </div>
            <div
              class="acceptChange"
              v-if="isShowAcceptTip && panelMode !== PanelModeEnum.result"
              @click="submitPlay"
            >
              {{ $t('BetViewList.AcceptChange') }}
            </div>
            <div
              class="submitBtn"
              v-else-if="panelMode !== PanelModeEnum.result"
              @click="submitPlay"
            >
              {{ $t('Common.SubmitBet') }}
            </div>
            <div class="closeBtn" v-else @click="resultCancelBtnClick()">
              {{ $t('Common.Close') }}
            </div>
          </div>
        </div>

        <div
          v-if="
            MOBILE_APP &&
            isShowChartList &&
            panelMode === PanelModeEnum.result &&
            isControlByBetSingle
          "
          class="cardOptionBlock"
        >
          <div class="buttonRow">
            <div class="clearBtn" @click="resultLeftBtnClickHandler()">
              {{ OptionCancelBtnStr }}
            </div>
            <div
              v-if="isShowAcceptTip && panelMode !== PanelModeEnum.result"
              class="acceptChange"
              @click="submitPlay"
            >
              {{ $t('BetViewList.AcceptChange') }}
            </div>
            <div
              v-else-if="panelMode !== PanelModeEnum.result"
              class="submitBtn"
              @click="submitPlay"
            >
              {{ $t('Common.SubmitBet') }}
            </div>
            <div v-else class="closeBtn" @click="resultCancelBtnClick()">
              {{ $t('Common.Close') }}
            </div>
          </div>
        </div>

        <div v-if="isShowCharStrayList && !isControlByBetSingle" class="cardOptionBlock">
          <div v-if="isLockStray" class="StrayTipBlock">
            <div class="bottomTextRow"> ※ {{ $t('BetViewList.LockStray') }} </div>
          </div>
          <div v-else-if="EvtIdRepeatList.length !== 0" class="StrayTipBlock">
            <div class="topTextRow"> ※ {{ $t('BetViewList.HasSameGame') }} </div>
            <div class="bottomTextRow">
              <div>
                {{ $t('BetViewList.HaveChoose') }}
              </div>
              <div class="goldTip">
                {{ EvtIdRepeatList.length }}
              </div>
              <div>
                {{ $t('BetViewList.CantStray') }}
              </div>
            </div>
          </div>
          <div v-if="isShowStrayStatusData" class="strayErrorMessage">
            {{ showBetCartList[0].statusMessage }}
          </div>
          <!-- will: 包牌  -->
          <div v-if="bet97Enable && panelMode === PanelModeEnum.normal" class="bet97">
            <div class="bet97-header" @click="toggleBet97Mode()">
              <div class="bet97-title">{{ $t('Common.Stray97') }}</div>
              <i v-if="bet97Mode" class="el-icon-arrow-up bet97-icon" />
              <i v-else class="el-icon-arrow-down bet97-icon" />
            </div>
          </div>
          <template v-if="bet97Enable && bet97Mode">
            <BetResultBlock
              v-if="panelMode === PanelModeEnum.result"
              :panelMode="panelMode"
              :cartData="showBetCartList[0]"
              style="margin-bottom: 7px; margin-left: 10px"
            />
            <div v-if="panelMode === PanelModeEnum.normal" class="bet97-items">
              <!-- 2串1 -->
              <div v-if="betItemCount >= 2" class="bet97-item">
                <div class="bet97-item-name">2{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet2in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet2in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet2in1Amount($event)"
                    />
                    <i
                      v-if="bet2in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet2in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet2in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 3串1 -->
              <div v-if="betItemCount >= 3" class="bet97-item">
                <div class="bet97-item-name">3{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet3in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet3in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet3in1Amount($event)"
                    />
                    <i
                      v-if="bet3in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet3in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet3in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 4串1 -->
              <div v-if="betItemCount >= 4" class="bet97-item">
                <div class="bet97-item-name">4{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet4in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet4in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet4in1Amount($event)"
                    />
                    <i
                      v-if="bet4in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet4in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet4in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 5串1 -->
              <div v-if="betItemCount >= 5" class="bet97-item">
                <div class="bet97-item-name">5{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet5in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet5in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet5in1Amount($event)"
                    />
                    <i
                      v-if="bet5in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet5in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet5in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 6串1 -->
              <div v-if="betItemCount >= 6" class="bet97-item">
                <div class="bet97-item-name">6{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet6in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet6in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet6in1Amount($event)"
                    />
                    <i
                      v-if="bet6in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet6in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet6in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 7串1 -->
              <div v-if="betItemCount >= 7" class="bet97-item">
                <div class="bet97-item-name">7{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet7in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet7in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet7in1Amount($event)"
                    />
                    <i
                      v-if="bet7in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet7in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet7in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 8串1 -->
              <div v-if="betItemCount >= 8" class="bet97-item">
                <div class="bet97-item-name">8{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet8in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet8in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet8in1Amount($event)"
                    />
                    <i
                      v-if="bet8in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet8in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet8in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 9串1 -->
              <div v-if="betItemCount >= 9" class="bet97-item">
                <div class="bet97-item-name">9{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet9in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet9in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet9in1Amount($event)"
                    />
                    <i
                      v-if="bet9in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet9in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet9in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- 10串1 -->
              <div v-if="betItemCount >= 10" class="bet97-item">
                <div class="bet97-item-name">10{{ $t('Common.string') }}1</div>
                <div class="bet97-item-inputs">
                  <div class="bet97-item-count">{{ bet10in1Count }}</div>
                  <div class="bet97-item-amount">
                    <input
                      v-model="bet10in1Amount"
                      type="number"
                      class="bet97-item-amount-input"
                      @input="inputBet10in1Amount($event)"
                    />
                    <i
                      v-if="bet10in1Amount"
                      class="el-icon-error bet97-item-amount-clear"
                      @click="clearBet10in1Amount()"
                    />
                  </div>
                  <div class="bet97-item-win">
                    <input
                      v-model="bet10in1Win"
                      type="number"
                      class="bet97-item-win-input"
                      :placeholder="$t('Common.CanWinMoney')"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="strayRow">
              <div v-if="bet2in1Amount" class="strayRowContent">
                {{
                  `2${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet2in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet2in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet2in1Amount * bet2in1Count
                  )}`
                }}
              </div>
              <div v-if="bet3in1Amount" class="strayRowContent">
                {{
                  `3${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet3in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet3in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet3in1Amount * bet3in1Count
                  )}`
                }}
              </div>
              <div v-if="bet4in1Amount" class="strayRowContent">
                {{
                  `4${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet4in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet4in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet4in1Amount * bet4in1Count
                  )}`
                }}
              </div>
              <div v-if="bet5in1Amount" class="strayRowContent">
                {{
                  `5${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet5in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet5in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet5in1Amount * bet5in1Count
                  )}`
                }}
              </div>
              <div v-if="bet6in1Amount" class="strayRowContent">
                {{
                  `6${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet6in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet6in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet6in1Amount * bet6in1Count
                  )}`
                }}
              </div>
              <div v-if="bet7in1Amount" class="strayRowContent">
                {{
                  `7${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet7in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet7in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet7in1Amount * bet7in1Count
                  )}`
                }}
              </div>
              <div v-if="bet8in1Amount" class="strayRowContent">
                {{
                  `8${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet8in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet8in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet8in1Amount * bet8in1Count
                  )}`
                }}
              </div>
              <div v-if="bet9in1Amount" class="strayRowContent">
                {{
                  `9${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(bet9in1Amount)}${$t(
                    'Common.Dollar'
                  )}) X ${bet9in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet9in1Amount * bet9in1Count
                  )}`
                }}
              </div>
              <div v-if="bet10in1Amount" class="strayRowContent">
                {{
                  `10${$t('Common.string')}1 (${$t('Common.EachZu')}${toCurrency(
                    bet10in1Amount
                  )}${$t('Common.Dollar')}) X ${bet10in1Count} ${$t('Common.Group')} = ${toCurrency(
                    bet10in1Amount * bet10in1Count
                  )}`
                }}
              </div>
            </div>
            <div>
              <span>{{ bet97Tickets }}</span>
              {{ $t('Common.Bet97Bets') }},
              {{ $t('Common.Bet97TotalSets1') }}
              <span>{{ bet97Sets }}</span>
              {{ $t('Common.Bet97TotalSets2') }}
            </div>
            <div class="totalRow">
              <div class="halfItem">
                {{ $t('BetViewList.TotalBet') }} : {{ toCurrency(bet97Amount) }}
              </div>
              <div class="halfItem">
                {{ $t('Common.CanWinMoney') }} :
                <span>
                  {{ toCurrency($lib.truncFloor(bet97Win)) }}
                </span>
              </div>
            </div>

            <div v-if="panelMode === PanelModeEnum.result" class="buttonRow">
              <div class="clearBtn" @click="keepBet97()"> {{ $t('BetViewList.KeepBetCart') }} </div>
              <div class="submitBtn" @click="closeBet97()">
                {{ $t('Common.Close') }}
              </div>
            </div>
            <div v-else class="buttonRow">
              <div class="clearBtn" @click="clearBet97()"> {{ $t('Common.AllClear') }} </div>
              <div class="submitBtn" @click="submitBet97()">
                {{ $t('Common.SubmitBet') }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="betRows-wrap">
              <ul v-if="panelMode === PanelModeEnum.normal" class="betInputRow">
                <li class="betInputTitle">
                  <div class="strayBlock">
                    <div class="strayBlockTop">
                      <div class="strayTopLeft">
                        {{ `${showBetCartList.length}${$t('Common.string')}1` }}
                      </div>
                      <div class="strayTopRight">
                        <div class="num">1</div>
                        <div class="x">x</div>
                      </div>
                    </div>
                    <div class="strayBlockBottom">
                      <div v-if="!isLockStray" class="strayOdd">
                        @ {{ $lib.trunc(strayOdd, 2) }}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <input
                    ref="strayBetAmount"
                    v-model="strayBetAmount"
                    type="number"
                    read="true"
                    :readonly="MOBILE_APP"
                    :class="isShowMinText || isShowMaxText ? 'redErrorInput' : ''"
                    @focus="onInputFocus()"
                    @input="strayBetAmountInputChangeHandler"
                    @click="isShowStrayKB = !isShowStrayKB"
                    @blur="strayBetBlurHandler"
                  />
                  <span
                    v-if="strayBetAmount"
                    class="btnClearValue stray"
                    @click="clearInput('stray')"
                  >
                    <i class="el-icon-error" />
                  </span>
                </li>
              </ul>
              <ul v-if="panelMode === PanelModeEnum.normal" class="betInputRow">
                <li class="betInputTitle">
                  {{ $t('Common.CanWinMoney') }}
                </li>
                <li class="betReadInput">
                  {{ $lib.truncFloor(strayBetAmount * strayOdd) }}
                </li>
              </ul>
            </div>

            <mBetKeyboard
              v-if="MOBILE_APP && isShowStrayKB && panelMode === PanelModeEnum.normal"
              :isShowMaxChip="isShowMaxChip"
              :theMaxChipValue="theMaxChipValue"
              @Add="keyBoardAddEvent"
              @Assign="keyBoardAssignEvent"
            />

            <div class="strayLimitTipBlock">
              <!-- 串關限紅提示 -->
              <div class="limitTip" v-if="isShowMinText && childIndex === 1">
                {{ $t('Common.BetMinTip') }}
              </div>
              <div class="limitTip" v-if="isShowMaxText && childIndex === 1">
                {{ $t('Common.BetMaxTip') }}
              </div>
            </div>

            <ChipsBar
              v-if="!MOBILE_APP && panelMode === PanelModeEnum.normal"
              :isShowMaxChip="isShowMaxChip"
              :theMaxChipValue="theMaxChipValue"
              @onChipClick="onChipClick"
            />

            <BetResultBlock
              style="margin-bottom: 7px; margin-left: 10px"
              v-if="panelMode === PanelModeEnum.result"
              :panelMode="panelMode"
              :cartData="showBetCartList[0]"
            />

            <div
              v-if="panelMode === PanelModeEnum.lock || panelMode === PanelModeEnum.result"
              class="strayRow"
            >
              <div class="strayRowTitle"> {{ $t('BetViewList.StaryOnly1') }} </div>
              <div class="strayRowContent">
                {{
                  `${showBetCartList.length}${$t('Common.string')}1 X 1 (${$t(
                    'Common.EachZu'
                  )}${strayBetAmount}${$t('Common.Dollar')} X 1 ${$t(
                    'Common.Group'
                  )}) = ${strayBetAmount}`
                }}
              </div>
            </div>

            <div class="totalRow">
              <div class="halfItem">
                {{ $t('BetViewList.TotalBet') }} : {{ toCurrency(strayBetAmount) }}
              </div>
              <div class="halfItem">
                {{ $t('Common.CanWinMoney') }} :
                <span
                  :style="isWinAmountChangeColor ? winAmountColor : ''"
                  :class="betDataChangeList.length !== 0 ? 'halfItemChange' : ''"
                >
                  {{ toCurrency($lib.truncFloor(strayBetAmount * strayOdd)) }}
                </span>
              </div>
            </div>
            <div class="buttonRow">
              <div class="clearBtn" @click="resultLeftBtnClickHandler">
                {{ OptionCancelBtnStr }}
              </div>
              <div
                class="acceptChange"
                v-if="isShowAcceptTip && panelMode !== PanelModeEnum.result"
                @click="submitStrayPlay"
              >
                {{ $t('BetViewList.AcceptChange') }}
              </div>
              <div
                class="submitBtn"
                v-else-if="panelMode !== PanelModeEnum.result"
                @click="submitStrayPlay"
              >
                {{ $t('Common.SubmitBet') }}
              </div>
              <div class="closeBtn" v-else @click="resultCancelBtnClick()">
                {{ $t('Common.Close') }}
              </div>
            </div>
          </template>
        </div>

        <div class="noData" v-if="groupIndex === 0 && showBetCartList.length === 0">
          <div class="noDataItem">
            <div class="noDataItemImgContainer">
              <img src="@/assets/img/pc/icon_onInfo.svg" alt="" />
            </div>
            <div> {{ $t('BetViewList.ClickToAdd') }} </div>
          </div>
        </div>

        <div
          class="noData"
          v-if="groupIndex === 1 && showBetHistoryList.length === 0 && appLoading === false"
        >
          <div class="noDataItem">
            <div class="noDataItemImgContainer">
              <img src="@/assets/img/pc/icon_noReceipt.svg" alt="" />
            </div>
            <div>{{ $t('BetViewList.NoBet') }}</div>
          </div>
        </div>

        <div class="noData" v-if="isShowStrayCantPlayTip">
          <div class="noDataItem">
            <div class="noDataItemImgContainer center">
              <img src="@/assets/img/warning.png" style="width: 24px" />
              {{ $t('BetViewList.StayNeed2Game') }}
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else-if="groupIndex === 1">
      <HistoryCardItem
        v-for="(historyItem, historyIndex) in showBetHistoryList"
        :key="historyIndex"
        :historyItem="historyItem"
        :isSettlement="isSettlement"
      />
    </template>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import listCardItem from '@/components/ListCardItem';
  import BetResultBlock from '@/components/BetResultBlock';
  import HistoryCardItem from '@/components/HistoryCardItem';
  import ChipsBar from '@/components/ChipsBar';
  import mBetKeyboard from '@/components/mBetKeyboard';
  import { MOBILE_APP } from '@/config';
  import { PanelModeEnum } from '@/config/bet';
  import { toCurrency } from '@/config/balance';
  import { nextTick } from 'vue';

  export default {
    components: {
      listCardItem,
      BetResultBlock,
      HistoryCardItem,
      ChipsBar,
      mBetKeyboard,
    },
    props: {
      // 0: 投注資訊
      // 1: 最新注單
      groupIndex: {
        type: Number,
        default: 0,
      },
      // 0: 投注資訊 => 單向投注
      // 1: 投注資訊 => 過關投注
      // 0: 最新注單 => 未結算注單
      // 1: 最新注單 => 可兌現注單
      childIndex: {
        type: Number,
        default: 0,
      },
      // 是否來自 mGamesBetInfoSingle 控制
      isControlByBetSingle: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        MOBILE_APP,
        PanelModeEnum,

        // 一般投注相關數據
        totalBetAmount: 0,
        totalWinAmount: 0,
        fillEachBetAmount: null,
        fillEachWinAmount: null,
        // 串關相關數據
        strayOdd: null,
        strayBetAmount: null,
        EvtIdRepeatList: [],
        strayErrors: [],
        // 其他
        appLoading: false,
        intervalEvent: null,
        intervalEvent2: null,

        // 是否顯示下方 主要小鍵盤
        isShowBetKB: false,
        isShowStrayKB: false,

        // 單項投注時focus的input目標: 1=單注; 2=可贏金額
        lastClickInput: null,

        // 目前打開小鍵盤的 購物車item index
        currShowKeyboardIndex: -1,

        // 最後blur的input
        lastBlurInput: { name: 'fillEachBetAmount' },

        isLockEnter: false,
        lockEvent: null,

        // 限紅提示
        isShowMinText: false,
        isShowMaxText: false,

        // 最大籌碼按鈕
        isShowMaxChip: false,
        // 最大籌碼數字
        theMaxChipValue: 1000,

        // 項目不開放投注
        isLockStray: false,
        isBalanceOK: false,
        isOverMaxwin: false,
        maxWinEx: 2000000,

        // will: 包牌
        bet97Mode: false,
        bet2in1Amount: '',
        bet3in1Amount: '',
        bet4in1Amount: '',
        bet5in1Amount: '',
        bet6in1Amount: '',
        bet7in1Amount: '',
        bet8in1Amount: '',
        bet9in1Amount: '',
        bet10in1Amount: '',
      };
    },
    computed: {
      ...mapState(['userBalance', 'darkTheme']),
      ...mapGetters(['userWinMax']),
      winAmountColor() {
        if (!this.darkTheme) {
          return 'color:blue;';
        } else {
          return 'color:#a1d7ff;';
        }
      },
      isBetterOdds() {
        return this.$store.state.BetCart.isBetterOdds;
      },
      betterOddsWinAmount() {
        let amount = 0;
        this.showBetCartList
          .filter((cart) => cart.isSuccessMode)
          .forEach((cart) => {
            const { betAmount, winAmount, betResult } = cart;
            if (betAmount) {
              if (betResult?.PayoutOdds) {
                amount += betAmount * betResult?.PayoutOdds;
              } else {
                amount += winAmount;
              }
            }
          });

        return this.$lib.trunc(amount);
      },
      betDataChangeList() {
        return this.showBetCartList.filter((it) => it.hasDataChange);
      },
      isReCalcBetCart() {
        return this.$store.state.BetCart.isReCalcBetCart;
      },
      OptionCancelBtnStr() {
        if (this.panelMode === this.PanelModeEnum.normal) {
          return this.$t('Common.AllClear');
        } else if (this.panelMode === this.PanelModeEnum.lock) {
          return this.$t('Common.AllClear');
        } else {
          return this.$t('BetViewList.KeepBetCart');
        }
      },
      settings() {
        return this.$store.state.Setting.UserSetting;
      },
      showBetCartList() {
        return this.$store.getters['BetCart/showBetCartList'];
      },
      showBetHistoryList() {
        return this.$store.getters['BetCart/showBetHistoryList'];
      },
      isAddNewToChart() {
        return this.$store.state.BetCart.isAddNewToChart;
      },
      panelMode() {
        return this.$store.state.BetCart.panelMode;
      },
      isClearMemberData() {
        return this.$store.state.BetCart.isClearMemberData;
      },
      isSubmitHandler() {
        return this.$store.state.BetCart.isSubmitHandler;
      },
      isShowChartList() {
        return this.groupIndex === 0 && this.childIndex === 0 && this.showBetCartList.length > 0;
      },
      isShowCharStrayList() {
        return this.groupIndex === 0 && this.childIndex === 1 && this.showBetCartList.length > 1;
      },
      isShowStrayCantPlayTip() {
        return this.groupIndex === 0 && this.childIndex === 1 && this.showBetCartList.length === 1;
      },
      // 是否在已結算注單下
      isSettlement() {
        return this.groupIndex === 1 && this.childIndex === 1;
      },
      isQuickBetEnable() {
        return this.$store.state.Game.isQuickBet.isEnable;
      },
      isWinAmountChangeColor() {
        return this.panelMode === PanelModeEnum.lock || this.panelMode === PanelModeEnum.result;
      },
      isChatInputFocus() {
        return this.$store.state.Chat.isChatInputFocus;
      },
      isShowService() {
        return this.$store.state.Game.isShowService;
      },
      isShowStatusData() {
        return this.$store.state.BetCart.isShowStatusData;
      },
      isShowStatusData2() {
        return this.$store.state.BetCart.isShowStatusData2;
      },
      isShowStrayStatusData() {
        return this.$store.state.BetCart.isShowStrayStatusData;
      },
      tipAmount() {
        return this.$store.state.BetCart.tipAmount;
      },
      isShowAcceptTip() {
        return this.$store.state.BetCart.isShowAcceptTip;
      },
      isShowTracing() {
        return this.$store.state.BetCart.isShowTracing;
      },
      flag() {
        return this.$store.state.BetCart.flag;
      },
      quickBetData() {
        return this.$store.state.BetCart.lastFirstBetData;
      },
      // will: 包牌
      betItemCount() {
        return this.showBetCartList.length;
      },
      betStrayOddsList() {
        const { showBetCartList, cartDataToDisplayData } = this;
        return showBetCartList.map((cart) => Number(cartDataToDisplayData(cart).showOdd2));
      },
      bet97Enable() {
        const { isLockStray, EvtIdRepeatList, betItemCount } = this;
        return !isLockStray && !EvtIdRepeatList.length && betItemCount > 2 && betItemCount <= 10;
      },
      bet2in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 2:
            return 1;
          case 3:
            return 3;
          case 4:
            return 6;
          case 5:
            return 10;
          case 6:
            return 15;
          case 7:
            return 21;
          case 8:
            return 28;
          case 9:
            return 36;
          case 10:
            return 45;
          default:
            return '';
        }
      },
      bet2in1Win() {
        const { bet2in1Count, bet2in1Amount, betStrayOddsList } = this;
        if (bet2in1Count && bet2in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < length; i2++) {
              const odds2 = betStrayOddsList[i2];
              const odds = (1 + odds1) * (1 + odds2) - 1;
              list.push(odds);
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet2in1Amount) | 0;
        }
        return '';
      },
      bet3in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 3:
            return 1;
          case 4:
            return 4;
          case 5:
            return 10;
          case 6:
            return 20;
          case 7:
            return 35;
          case 8:
            return 56;
          case 9:
            return 84;
          case 10:
            return 120;
          default:
            return '';
        }
      },
      bet3in1Win() {
        const { bet3in1Count, bet3in1Amount, betStrayOddsList } = this;
        if (bet3in1Count && bet3in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 2;
          const end2 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < length; i3++) {
                const odds3 = betStrayOddsList[i3];
                const odds = (1 + odds1) * (1 + odds2) * (1 + odds3) - 1;
                list.push(odds);
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet3in1Amount) | 0;
        }
        return '';
      },
      bet4in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 4:
            return 1;
          case 5:
            return 5;
          case 6:
            return 15;
          case 7:
            return 35;
          case 8:
            return 70;
          case 9:
            return 126;
          case 10:
            return 210;
          default:
            return '';
        }
      },
      bet4in1Win() {
        const { bet4in1Count, bet4in1Amount, betStrayOddsList } = this;
        if (bet4in1Count && bet4in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 3;
          const end2 = length - 2;
          const end3 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < length; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  const odds = (1 + odds1) * (1 + odds2) * (1 + odds3) * (1 + odds4) - 1;
                  list.push(odds);
                }
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet4in1Amount) | 0;
        }
        return '';
      },
      bet5in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 5:
            return 1;
          case 6:
            return 6;
          case 7:
            return 21;
          case 8:
            return 56;
          case 9:
            return 126;
          case 10:
            return 252;
          default:
            return '';
        }
      },
      bet5in1Win() {
        const { bet5in1Count, bet5in1Amount, betStrayOddsList } = this;
        if (bet5in1Count && bet5in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 4;
          const end2 = length - 3;
          const end3 = length - 2;
          const end4 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < length; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    const odds =
                      (1 + odds1) * (1 + odds2) * (1 + odds3) * (1 + odds4) * (1 + odds5) - 1;
                    list.push(odds);
                  }
                }
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet5in1Amount) | 0;
        }
        return '';
      },
      bet6in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 6:
            return 1;
          case 7:
            return 7;
          case 8:
            return 28;
          case 9:
            return 84;
          case 10:
            return 210;
          default:
            return '';
        }
      },
      bet6in1Win() {
        const { bet6in1Count, bet6in1Amount, betStrayOddsList } = this;
        if (bet6in1Count && bet6in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 5;
          const end2 = length - 4;
          const end3 = length - 3;
          const end4 = length - 2;
          const end5 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < length; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      const odds =
                        (1 + odds1) *
                          (1 + odds2) *
                          (1 + odds3) *
                          (1 + odds4) *
                          (1 + odds5) *
                          (1 + odds6) -
                        1;
                      list.push(odds);
                    }
                  }
                }
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet6in1Amount) | 0;
        }
        return '';
      },
      bet7in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 7:
            return 1;
          case 8:
            return 8;
          case 9:
            return 36;
          case 10:
            return 120;
          default:
            return '';
        }
      },
      bet7in1Win() {
        const { bet7in1Count, bet7in1Amount, betStrayOddsList } = this;
        if (bet7in1Count && bet7in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 6;
          const end2 = length - 5;
          const end3 = length - 4;
          const end4 = length - 3;
          const end5 = length - 2;
          const end6 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < length; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        const odds =
                          (1 + odds1) *
                            (1 + odds2) *
                            (1 + odds3) *
                            (1 + odds4) *
                            (1 + odds5) *
                            (1 + odds6) *
                            (1 + odds7) -
                          1;
                        list.push(odds);
                      }
                    }
                  }
                }
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet7in1Amount) | 0;
        }
        return '';
      },
      bet8in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 8:
            return 1;
          case 9:
            return 9;
          case 10:
            return 45;
          default:
            return '';
        }
      },
      bet8in1Win() {
        const { bet8in1Count, bet8in1Amount, betStrayOddsList } = this;
        if (bet8in1Count && bet8in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 7;
          const end2 = length - 6;
          const end3 = length - 5;
          const end4 = length - 4;
          const end5 = length - 3;
          const end6 = length - 2;
          const end7 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < end7; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        for (let i8 = i7 + 1; i8 < length; i8++) {
                          const odds8 = betStrayOddsList[i8];
                          const odds =
                            (1 + odds1) *
                              (1 + odds2) *
                              (1 + odds3) *
                              (1 + odds4) *
                              (1 + odds5) *
                              (1 + odds6) *
                              (1 + odds7) *
                              (1 + odds8) -
                            1;
                          list.push(odds);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet8in1Amount) | 0;
        }
        return '';
      },
      bet9in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 9:
            return 1;
          case 10:
            return 10;
          default:
            return '';
        }
      },
      bet9in1Win() {
        const { bet9in1Count, bet9in1Amount, betStrayOddsList } = this;
        if (bet9in1Count && bet9in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 8;
          const end2 = length - 7;
          const end3 = length - 6;
          const end4 = length - 5;
          const end5 = length - 4;
          const end6 = length - 3;
          const end7 = length - 2;
          const end8 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < end7; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        for (let i8 = i7 + 1; i8 < end8; i8++) {
                          const odds8 = betStrayOddsList[i8];
                          for (let i9 = i8 + 1; i9 < length; i9++) {
                            const odds9 = betStrayOddsList[i9];
                            const odds =
                              (1 + odds1) *
                                (1 + odds2) *
                                (1 + odds3) *
                                (1 + odds4) *
                                (1 + odds5) *
                                (1 + odds6) *
                                (1 + odds7) *
                                (1 + odds8) *
                                (1 + odds9) -
                              1;
                            list.push(odds);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet9in1Amount) | 0;
        }
        return '';
      },
      bet10in1Count() {
        const { betItemCount } = this;
        switch (betItemCount) {
          case 10:
            return 1;
          default:
            return '';
        }
      },
      bet10in1Win() {
        const { bet10in1Count, bet10in1Amount, betStrayOddsList } = this;
        if (bet10in1Count && bet10in1Amount) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 9;
          const end2 = length - 8;
          const end3 = length - 7;
          const end4 = length - 6;
          const end5 = length - 5;
          const end6 = length - 4;
          const end7 = length - 3;
          const end8 = length - 2;
          const end9 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < end7; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        for (let i8 = i7 + 1; i8 < end8; i8++) {
                          const odds8 = betStrayOddsList[i8];
                          for (let i9 = i8 + 1; i9 < end9; i9++) {
                            const odds9 = betStrayOddsList[i9];
                            for (let i10 = i9 + 1; i10 < length; i10++) {
                              const odds10 = betStrayOddsList[i10];
                              const odds =
                                (1 + odds1) *
                                  (1 + odds2) *
                                  (1 + odds3) *
                                  (1 + odds4) *
                                  (1 + odds5) *
                                  (1 + odds6) *
                                  (1 + odds7) *
                                  (1 + odds8) *
                                  (1 + odds9) *
                                  (1 + odds10) -
                                1;
                              list.push(odds);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const sum = list.reduce((sum, odds) => sum + odds, 0);
          return (sum * bet10in1Amount) | 0;
        }
        return '';
      },
      bet97Tickets() {
        const {
          bet2in1Amount,
          bet3in1Amount,
          bet4in1Amount,
          bet5in1Amount,
          bet6in1Amount,
          bet7in1Amount,
          bet8in1Amount,
          bet9in1Amount,
          bet10in1Amount,
        } = this;
        let count = 0;
        if (bet2in1Amount) count += 1;
        if (bet3in1Amount) count += 1;
        if (bet4in1Amount) count += 1;
        if (bet5in1Amount) count += 1;
        if (bet6in1Amount) count += 1;
        if (bet7in1Amount) count += 1;
        if (bet8in1Amount) count += 1;
        if (bet9in1Amount) count += 1;
        if (bet10in1Amount) count += 1;
        return count;
      },
      bet97Sets() {
        const {
          bet2in1Amount,
          bet2in1Count,
          bet3in1Amount,
          bet3in1Count,
          bet4in1Amount,
          bet4in1Count,
          bet5in1Amount,
          bet5in1Count,
          bet6in1Amount,
          bet6in1Count,
          bet7in1Amount,
          bet7in1Count,
          bet8in1Amount,
          bet8in1Count,
          bet9in1Amount,
          bet9in1Count,
          bet10in1Amount,
          bet10in1Count,
        } = this;
        let count = 0;
        if (bet2in1Amount && bet2in1Count) count += bet2in1Count;
        if (bet3in1Amount && bet3in1Count) count += bet3in1Count;
        if (bet4in1Amount && bet4in1Count) count += bet4in1Count;
        if (bet5in1Amount && bet5in1Count) count += bet5in1Count;
        if (bet6in1Amount && bet6in1Count) count += bet6in1Count;
        if (bet7in1Amount && bet7in1Count) count += bet7in1Count;
        if (bet8in1Amount && bet8in1Count) count += bet8in1Count;
        if (bet9in1Amount && bet9in1Count) count += bet9in1Count;
        if (bet10in1Amount && bet10in1Count) count += bet10in1Count;
        return count;
      },
      bet97Amount() {
        const {
          bet2in1Amount,
          bet2in1Count,
          bet3in1Amount,
          bet3in1Count,
          bet4in1Amount,
          bet4in1Count,
          bet5in1Amount,
          bet5in1Count,
          bet6in1Amount,
          bet6in1Count,
          bet7in1Amount,
          bet7in1Count,
          bet8in1Amount,
          bet8in1Count,
          bet9in1Amount,
          bet9in1Count,
          bet10in1Amount,
          bet10in1Count,
        } = this;
        let amount = 0;
        if (bet2in1Amount && bet2in1Count) amount += bet2in1Amount * bet2in1Count;
        if (bet3in1Amount && bet3in1Count) amount += bet3in1Amount * bet3in1Count;
        if (bet4in1Amount && bet4in1Count) amount += bet4in1Amount * bet4in1Count;
        if (bet5in1Amount && bet5in1Count) amount += bet5in1Amount * bet5in1Count;
        if (bet6in1Amount && bet6in1Count) amount += bet6in1Amount * bet6in1Count;
        if (bet7in1Amount && bet7in1Count) amount += bet7in1Amount * bet7in1Count;
        if (bet8in1Amount && bet8in1Count) amount += bet8in1Amount * bet8in1Count;
        if (bet9in1Amount && bet9in1Count) amount += bet9in1Amount * bet9in1Count;
        if (bet10in1Amount && bet10in1Count) amount += bet10in1Amount * bet10in1Count;
        return amount;
      },
      bet97Win() {
        const {
          bet2in1Win,
          bet3in1Win,
          bet4in1Win,
          bet5in1Win,
          bet6in1Win,
          bet7in1Win,
          bet8in1Win,
          bet9in1Win,
          bet10in1Win,
        } = this;
        let win = 0;
        if (bet2in1Win) win += bet2in1Win;
        if (bet3in1Win) win += bet3in1Win;
        if (bet4in1Win) win += bet4in1Win;
        if (bet5in1Win) win += bet5in1Win;
        if (bet6in1Win) win += bet6in1Win;
        if (bet7in1Win) win += bet7in1Win;
        if (bet8in1Win) win += bet8in1Win;
        if (bet9in1Win) win += bet9in1Win;
        if (bet10in1Win) win += bet10in1Win;
        return win;
      },
    },
    watch: {
      // 有新增投注到購物車事件
      isAddNewToChart() {
        this.$nextTick(() => {
          this.$refs.BetViewList.scrollTop = 999999;
          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
          this.$store.commit('BetCart/setIsShowStrayStatusData', false);

          this.reCalcBetChart();

          if (
            this.$store.state.Setting.UserSetting.defaultStrayAmount.type === 1 ||
            this.$store.state.Setting.UserSetting.defaultStrayAmount.type === 2
          ) {
            this.strayBetAmount = parseInt(
              this.$store.state.Setting.UserSetting.defaultStrayAmount.amount
            );
          }

          if (this.groupIndex === 0 && !this.MOBILE_APP) {
            if (this.childIndex === 0 && this.$refs.fillEachBetAmount) {
              this.$refs.fillEachBetAmount.focus();
            } else if (this.childIndex === 1 && this.$refs.strayBetAmount) {
              this.$refs.strayBetAmount.focus();
            }
          }

          if (
            this.groupIndex === 0 &&
            this.childIndex === 1 &&
            this.showBetCartList.length > this.$conf.PARLAYS_MAX
          ) {
            const errorMessage = this.$t('Common.TriggerStrayLimit');
            this.$notify.error({
              message: errorMessage,
            });
          }
        });
      },
      isReCalcBetCart() {
        this.reCalcBetChart();
      },
      groupIndex: {
        handler() {
          if (this.groupIndex === 1) {
            this.callBetHistoryAPI();
          }
        },
        immediate: true,
      },
      childIndex: {
        handler() {
          if (!this.isShowTracing) {
            this.$store.commit('BetCart/addTracing');
          }

          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
          this.callBetHistoryAPI();
          if (this.groupIndex === 0) {
            if (this.childIndex === 0) {
              this.lastBlurInput = { name: 'fillEachBetAmount' };
            } else {
              this.lastBlurInput = { name: 'strayBetAmount' };
              this.isShowMaxChip = false;
            }
          }
          this.clearMinMaxTextState();
          this.clearSuccessModeItem();
        },
      },
      showBetCartList: {
        handler() {
          // 驅動過關賠率計算
          this.reCalcStrayBetChart();
          this.$emit('betCartListChanged', this.showBetCartList);

          if (this.showBetCartList.length === 0) {
            this.fillEachBetAmount = null;
            this.fillEachWinAmount = null;
          }
        },
      },
      'showBetCartList.length'() {
        // 關閉 x 筆投注資訊已變動 顯示
        this.$store.commit('BetCart/setIsShowStatusData', false);
        this.$store.commit('BetCart/setIsShowStatusData2', false);
      },
      strayOdd() {
        if (this.showBetCartList.length <= 1) {
          this.$store.commit('BetCart/setStrayOdd', null);
        } else {
          this.$store.commit('BetCart/setStrayOdd', this.strayOdd);
        }
      },
      isShowBetKB(isShowBetKB) {
        this.currShowKeyboardIndex = isShowBetKB ? -1 : this.currShowKeyboardIndex;
      },
      currShowKeyboardIndex(index) {
        this.isShowBetKB = index === -1;
      },
      isQuickBetEnable(val) {
        if (val) {
          // 如果快速投注開啟時 清空購物車
          this.cancelHandler();
        }
      },
      isClearMemberData() {
        this.clearMemberData();
      },
      isSubmitHandler() {
        // 來自快速下注的事件
        this.submitPlay();
      },
      flag() {
        if (this.groupIndex === 1) {
          this.callBetHistoryAPI();
        }
      },
    },
    methods: {
      toCurrency,
      clearMinMaxTextState() {
        this.isShowMinText = false;
        this.isShowMaxText = false;
      },
      clearAllMinMaxLimitState() {
        // 清除所有限紅提示
        this.clearMinMaxTextState();
        this.showBetCartList.forEach((cartData) => {
          cartData.isShowMinText = false;
          cartData.isShowMaxText = false;
          cartData.statusMessage = '';
        });
      },
      clearSuccessModeItem() {
        // 清除所有isSuccessMode的購物車內容
        this.$store.commit(
          'BetCart/setBetCartList',
          this.showBetCartList.filter((it) => !it.isSuccessMode)
        );
      },
      clearAllMessageState() {
        // 清除訊息狀態
        this.$store.commit('BetCart/resetStatus');
      },
      keyPress(e) {
        if (
          e.key === 'Enter' &&
          !this.isLockEnter &&
          !this.isChatInputFocus &&
          !this.isShowService
        ) {
          if (this.isQuickBetEnable && this.quickBetData.state === 'done') {
            this.$store.commit('BetCart/showQuickBetData', { isShow: false });
          }
          this.isLockEnter = true;
          clearTimeout(this.lockEvent);
          this.lockEvent = setTimeout(() => {
            this.isLockEnter = false;
          }, 300);
          if (
            this.groupIndex === 0 &&
            this.showBetCartList.length !== 0 &&
            this.panelMode !== this.PanelModeEnum.result
          ) {
            if (this.childIndex === 0) {
              this.submitPlay();
            } else if (this.childIndex === 1) {
              this.submitStrayPlay();
            }
          }
        }
      },
      resultCancelBtnClick() {
        if (!this.isShowTracing) {
          this.$store.commit('BetCart/addTracing');
        }
        this.$emit('setNewGroupIndex', 1);
        this.$emit('setNewChildIndex', 0);
        this.lastBlurInput = { name: 'fillEachBetAmount' };
        this.cancelHandler();
      },
      listCardItemClassJudge(GameID, cartData) {
        if (
          (this.childIndex === 1 && this.EvtIdRepeatList.indexOf(GameID) > -1) ||
          (this.panelMode === this.PanelModeEnum.result &&
            this.childIndex === 0 &&
            cartData.betResult !== null &&
            cartData.betResult?.code !== 200 &&
            cartData.betResult?.code !== 201)
        ) {
          return 'redErrorStyle';
        } else {
          return '';
        }
      },
      clearMemberData() {
        this.totalBetAmount = 0;
        this.totalWinAmount = 0;
        this.fillEachBetAmount = null;
        this.fillEachWinAmount = null;
        this.strayOdd = null;
        this.strayBetAmount = null;
        this.EvtIdRepeatList.length = 0;
        this.EvtIdRepeatList = [];
        this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
        this.bet2in1Amount = '';
        this.bet3in1Amount = '';
        this.bet4in1Amount = '';
        this.bet5in1Amount = '';
        this.bet6in1Amount = '';
        this.bet7in1Amount = '';
        this.bet8in1Amount = '';
        this.bet9in1Amount = '';
        this.bet10in1Amount = '';
      },
      callBetHistoryAPI() {
        if (this.groupIndex === 1) {
          if (this.$refs.BetViewList) {
            this.$refs.BetViewList.scrollTop = 0;
          }
          this.appLoading = true;
          let pagesize = 101;
          if (MOBILE_APP) {
            pagesize = 51;
          }
          this.$store
            .dispatch('BetCart/apiGetTicket', {
              isset: this.childIndex === 1,
              pagesize,
              page: 1,
            })
            .then(() => {
              this.appLoading = false;
            });
        }
      },
      inputRowItemChangeHandler() {
        this.reCalcBetChart();
      },
      fillEachBetAmountHandler() {
        this.fillEachWinAmount = null;
        this.fillEachBetAmount = parseFloat(this.fillEachBetAmount.replace(/[^\d]/g, ''));
        if (isNaN(this.fillEachBetAmount)) {
          this.fillEachBetAmount = 0;
        }
        const { userBalance } = this;
        this.showBetCartList.forEach((cartData) => {
          let betAmount = this.fillEachBetAmount;
          const { BetMax } = cartData;
          const max = Math.min(BetMax, userBalance);
          if (betAmount > max) {
            // will
            betAmount = max;
            nextTick(() => {
              if (max == userBalance) {
                cartData.isShowMaxText = 2;
              } else {
                cartData.isShowMaxText = true;
              }
            });
          }
          cartData.betAmount = betAmount;
        });
        this.reCalcBetChart();
      },
      fillEachWinAmountHandler() {
        this.fillEachBetAmount = null;
        this.fillEachWinAmount = parseFloat(this.fillEachWinAmount.replace(/[^\d]/g, ''));
        if (isNaN(this.fillEachWinAmount)) {
          this.fillEachWinAmount = 0;
        }
        const { userBalance } = this;
        this.showBetCartList.forEach((cartData) => {
          const displayData = this.cartDataToDisplayData(cartData);
          const odds = this.$lib.trunc(parseFloat(displayData.showOdd));
          let betAmount = (this.fillEachWinAmount / odds).toFixed();
          const { BetMax } = cartData;
          const max = Math.min(BetMax, userBalance);
          if (betAmount > max) {
            betAmount = max;
            nextTick(() => {
              if (max == userBalance) {
                cartData.isShowMaxText = 2;
              } else {
                cartData.isShowMaxText = true;
              }
            });
          }
          cartData.betAmount = betAmount;
        });
        this.reCalcBetChart();
      },
      strayBetAmountInputChangeHandler() {
        this.strayBetAmount = parseFloat(this.strayBetAmount.replace(/[^\d]/g, ''));
        if (isNaN(this.strayBetAmount)) {
          this.strayBetAmount = 0;
        }
        if (this.strayBetAmount > this.userBalance) {
          this.strayBetAmount = this.userBalance;
        }
        this.checkStrayMaxLimit();
      },
      minMaxJudge(cartData) {
        if (
          (cartData.BetMin && cartData.BetMin > this.userBalance) ||
          cartData.betAmount > this.userBalance
        ) {
          if (this.isQuickBetEnable) {
            this.isBalanceOK = false;
            this.$message({
              type: 'warning',
              message: this.$t('Common.NoBalanceTip'),
              duration: 5000,
            });
          }
          return cartData.betAmount;
        }

        if (cartData.betAmount < cartData.BetMin && cartData.BetMin !== null) {
          cartData.betAmount = cartData.BetMin;
          cartData.isShowMinText = true;

          if (this.isQuickBetEnable) {
            this.$message({
              type: 'warning',
              message: this.$t('Common.BetMinTip'),
              duration: 5000,
            });
          }
        }

        const judgeBetMax = cartData.BetMax; // this.$lib.BetMaxLimit(cartData.BetMax, cartData.BetMaxEvt);
        if (cartData.betAmount > judgeBetMax && judgeBetMax !== null) {
          cartData.betAmount = judgeBetMax;
          cartData.isShowMaxText = true;

          if (this.isQuickBetEnable) {
            this.$message({
              type: 'warning',
              message: this.$t('Common.BetMaxTip'),
              duration: 5000,
            });
          }
        }

        return cartData.betAmount;
      },
      // isMinMaxJudge 是否檢查限紅範圍
      reCalcBetChart(isMinMaxJudge = false) {
        const maxWin = this.maxWinEx;
        let newTotalBetAmount = 0;
        let newTotalWinAmount = 0;
        let isTriggerLimit = false;
        this.isBalanceOK = true;
        this.isOverMaxwin = false;
        this.clearMinMaxTextState();
        this.showBetCartList
          .filter((it) => !it.isSuccessMode)
          .forEach((cartData) => {
            const displayData = this.cartDataToDisplayData(cartData);
            if (cartData.betAmount !== null && !cartData.isForceLock) {
              cartData.betAmount = this.$lib.truncFloor(cartData.betAmount);
              cartData.isShowMinText = false;
              cartData.isShowMaxText = false;
              if (isMinMaxJudge) {
                cartData.betAmount = this.minMaxJudge(cartData);
                if (cartData.isShowMinText || cartData.isShowMaxText) {
                  isTriggerLimit = true;
                }
              }
              cartData.winAmount = this.$lib.truncFloor(
                cartData.betAmount * this.$lib.trunc(parseFloat(displayData.showOdd))
              );
              if (cartData.winAmount > this.userWinMax) {
                cartData.betAmount = this.$lib.truncFloor(
                  this.userWinMax / this.$lib.trunc(parseFloat(displayData.showOdd))
                );
                cartData.winAmount = this.$lib.truncFloor(
                  cartData.betAmount * this.$lib.trunc(parseFloat(displayData.showOdd))
                );
              }

              if ([112, 133].includes(cartData.WagerTypeID) && cartData.winAmount > maxWin) {
                this.isOverMaxwin = true;
              }

              newTotalBetAmount += cartData.betAmount;
              newTotalWinAmount += cartData.winAmount;
            }
          });

        this.totalBetAmount = newTotalBetAmount;
        this.totalWinAmount = newTotalWinAmount;

        return isTriggerLimit;
      },
      reCalcStrayBetChart() {
        // const noStrayList = this.$store.state.siteInfo?.ParlayDisableAry;
        let strayOdd = null;
        this.EvtIdRepeatList.length = 0;
        this.isLockStray = false;
        this.strayErrors = [];
        this.showBetCartList
          .filter((it) => !it.isSuccessMode)
          .forEach((cartData, index, arr) => {
            const List = this.EvtIdRepeatList;
            const {
              CatID,
              GameID,
              EventID,
              TimeStr,
              LeagueID,
              LeagueNameStr,
              HomeID,
              HomeTeamStr,
              AwayID,
              AwayTeamStr,
              WagerTypeID,
            } = cartData;
            const displayData = this.cartDataToDisplayData(cartData);
            const odd = this.$lib.trunc(1 + parseFloat(displayData.showOdd2));
            if (strayOdd === null) {
              strayOdd = odd;
            } else {
              strayOdd = this.$lib.trunc(strayOdd * odd);
            }

            // 遊戲不能串關
            // if (cartData.EnableParlay !== 1) {
            // console.log('遊戲不能串關 EnableParlay', cartData.EnableParlay);
            // this.isLockStray = true;
            // return;
            // }

            // 走地不能串關
            if (cartData.GameTypeID === 2) {
              // console.log('走地不能串關', cartData.GameTypeID);
              this.strayErrors.push(GameID);
              this.isLockStray = true;
              return;
            }

            // 球種不能串關
            if ([83].includes(CatID) && !cartData.ParlayFlag) {
              // console.log('球種不能串關 83 ParlayFlag', cartData.ParlayFlag);
              this.strayErrors.push(GameID);
              this.isLockStray = true;
              return;
            }

            // 玩法不能串關
            if ([112, 133].includes(WagerTypeID)) {
              // console.log('玩法不能串關 WagerTypeID', WagerTypeID);
              this.strayErrors.push(GameID);
              this.isLockStray = true;
              return;
            }

            // 玩法不能串關
            // if (noStrayList && Array.isArray(noStrayList)) {
            //  if (noStrayList.includes(WagerTypeID)) {
            //   console.log('玩法不能串關 noStrayList', noStrayList);
            //   this.strayErrors.push(GameID);
            //   this.isLockStray = true;
            //   return;
            //  }
            // }

            // 重複賽事不能串關
            arr.forEach((item, i) => {
              if (i === index) return;
              if (EventID === item.EvtID) {
                if (!List.includes(GameID)) {
                  // console.log('重複賽事不能串關', EventID);
                  // this.strayErrors.push(GameID);
                  List.push(GameID);
                }
                return;
              }

              if (TimeStr !== item.TimeStr) {
                return;
              }

              if (LeagueID === item.LeagueID && HomeID === item.HomeID && AwayID === item.AwayID) {
                if (!List.includes(GameID)) {
                  // console.log('重複賽事不能串關', LeagueID, HomeID, AwayID);
                  // this.strayErrors.push(GameID);
                  List.push(GameID);
                }
                return;
              }

              // 不需要繼續比對
              if ([83, 84].includes(CatID)) {
                return;
              }

              const ItemHomeTeamStr = item.HomeTeamStr.substr(0, 3);
              const ItemAwayTeamStr = item.AwayTeamStr.substr(0, 3);
              const ItemLeagueNameStr = item.LeagueNameStr.substr(0, 3);

              if (
                LeagueNameStr.includes(ItemLeagueNameStr) &&
                HomeTeamStr.includes(ItemHomeTeamStr) &&
                AwayTeamStr.includes(ItemAwayTeamStr)
              ) {
                if (!List.includes(GameID)) {
                  /* console.log(
                    '重複賽事不能串關',
                    ItemLeagueNameStr,
                    ItemHomeTeamStr,
                    ItemAwayTeamStr
                  ); */
                  // this.strayErrors.push(GameID);
                  List.push(GameID);
                }
              }
            });
          });

        // 扣掉本金 就可以得到串關賠率
        this.strayOdd = this.$lib.trunc(strayOdd - 1);
      },
      // 保留下注按鈕點擊事件
      resultLeftBtnClickHandler() {
        if (this.panelMode === this.PanelModeEnum.result) {
          if (!this.isShowTracing) {
            this.$store.commit('BetCart/addTracing', true);
          }
          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
          this.$store.commit('BetCart/clearCartBetResult');
          this.$store.commit('showLoading');
          this.$store.dispatch('BetCart/updateAllCartGames').finally(() => {
            this.$store.commit('hideLoading');
          });
        } else {
          this.cancelHandler();
        }
      },
      cancelHandler() {
        this.clearMemberData();
        this.$store.commit('BetCart/clearCart');
        this.clearAllMessageState();
        this.reCalcBetChart();
      },
      cancelSingleHandler(gameID) {
        this.fillEachBetAmount = null;
        this.fillEachWinAmount = null;
        this.$store.commit('BetCart/removeCartByGameID', gameID);
        this.reCalcBetChart();
        this.updateBet97();
      },
      checkBetPlayData(betType, strayBetAmount) {
        // 串關檢查
        if (betType === 99) {
          let errorMessage = null;
          if (this.EvtIdRepeatList.length !== 0) {
            errorMessage = this.$t('BetViewList.StrayHaveSame');
          } else if (
            this.strayBetAmount === 0 ||
            this.strayBetAmount === '' ||
            this.strayBetAmount === null
          ) {
            errorMessage = this.$t('BetViewList.PlzInputStrayAmountFirst');
          } else if (this.$store.state.BetCart.betCartList.length > this.$conf.PARLAYS_MAX) {
            errorMessage = this.$t('Common.TriggerStrayLimit');
          } else if (this.isLockStray) {
            errorMessage = this.$t('BetViewList.LockStray');
          }

          if (Number(this.strayBetAmount) < 100) {
            this.strayBetAmount = 100;
            errorMessage = this.$t('Common.BetMinTip');
          }

          if (errorMessage !== null) {
            this.$notify.error({
              message: errorMessage,
            });
            return null;
          }
        }

        // 蒐集投注資料
        const list = [];
        let errorMessage = null;
        this.$store.state.BetCart.betCartList.every((cartData) => {
          const CatId = cartData.CatID;
          const GameID = cartData.GameID;
          const WagerTypeID = cartData.WagerTypeID;
          const WagerGrpID = cartData.WagerGrpID;
          const WagerPos = cartData.wagerPos;
          const HdpPos = cartData.HdpPos;
          let CutLine = cartData.playData.playMethodData.betCutLineDealFunc(cartData);
          const data = this.$SportLib.cartDataToDisplayData(cartData);
          const odd = betType === 99 ? data.showOdd2 : data.showOdd;
          const OddValue = parseFloat(odd);
          if (CutLine === '') {
            CutLine = 0;
          }

          let WagerString = `${CatId},${GameID},${WagerTypeID},${WagerGrpID},${WagerPos},${HdpPos},${CutLine},${OddValue},DE`;
          if (cartData.GameTypeID === 2) {
            if (!cartData.apiUpdated) {
              let tteam = null;
              const clist = this.$store.state.Game.GameList;
              // eslint-disable-next-line no-labels
              search: for (let c = 0; c < clist.length; ++c) {
                const llist = clist[c].Items.List;
                for (let l = 0; l < llist.length; ++l) {
                  const tlist = llist[l].Team;
                  for (let t = 0; t < tlist.length; ++t) {
                    const team = tlist[t];
                    if (team.EvtID === cartData.EvtID) {
                      tteam = team;
                      // eslint-disable-next-line no-labels
                      break search;
                    }
                  }
                }
              }
              if (tteam) {
                cartData.HomeScore = tteam.HomeScore;
                cartData.AwayScore = tteam.AwayScore;
              }
            }
            WagerString += `,${cartData.HomeScore}:${cartData.AwayScore}`;
          }
          // 移除過期賽事提示
          if (cartData.Status !== 1) {
            errorMessage = this.$t('BetViewList.PlzRemoveOutOfTimeBet');
            return false;
          }
          // 一般投注
          if (betType === 1) {
            if (
              cartData.betAmount === null ||
              cartData.betAmount === '' ||
              cartData.betAmount === 0
            ) {
              errorMessage = this.$t('BetViewList.PlzInputBetAmount');
              return false;
            }

            const listItem = {
              CatId,
              WagerString,
              Amount: parseFloat(cartData.betAmount),
              AcceptBetter: this.$store.state.Setting.UserSetting.acceptBetter,
              BetType: 1,
              PlaySource: cartData.more ? 1 : 0,
            };
            list.push(listItem);
          } // 串關投注
          else if (betType === 99) {
            if (list.length === 0) {
              const listItem = {
                CatId,
                WagerString,
                Amount: parseFloat(strayBetAmount),
                AcceptBetter: this.$store.state.Setting.UserSetting.acceptBetter,
                BetType: 99,
                PlaySource: cartData.more ? 1 : 0,
              };
              list.push(listItem);
            } else {
              list[0].WagerString += '|' + WagerString;
            }
          } else {
            errorMessage = `betType ${betType} not define`;
            return false;
          }
          return true;
        });
        // have error
        if (errorMessage !== null) {
          this.$notify.error({
            message: errorMessage,
          });
          return null;
        } else {
          return list;
        }
      },

      submitPlay() {
        const { $store, isQuickBetEnable: isQuick } = this;
        const { commit } = $store;

        commit('BetCart/setShowTracing', false);
        const isTriggerLimit = this.reCalcBetChart(true);

        if (!this.isBalanceOK) {
          commit('BetCart/setLastFirstBetDataState', 'init');
          return;
        }

        if (this.isOverMaxwin) {
          this.$message({
            type: 'warning',
            message: `波膽可贏金額大於最高可贏額 2,000,000`,
            duration: 5000,
          });
          return;
        }

        if (this.totalBetAmount > Number(this.userBalance)) {
          this.isBalanceOK = false;
          this.$message({
            type: 'warning',
            message: this.$t('Common.NoBalanceTip'),
            duration: 5000,
          });
          return;
        }

        if (isTriggerLimit) {
          commit('BetCart/setLastFirstBetDataState', 'init');
          return;
        }

        const list = this.checkBetPlayData(1, null);
        if (!list) return;

        this.clearAllMinMaxLimitState();
        this.clearAllMessageState();
        this.clearSuccessModeItem();

        if (
          this.panelMode === PanelModeEnum.lock ||
          this.settings.showBetConfirm === false ||
          isQuick
        ) {
          const { defaultAmount } = $store.state.Setting.UserSetting;
          if (defaultAmount.type === 1) {
            defaultAmount.amount = Math.max(...list.map((item) => item.Amount));
          }

          $store.dispatch('BetCart/submitCarts', {
            list,
            isStray: false,
            isQuick,
          });
        } else {
          commit('BetCart/setPanelMode', PanelModeEnum.lock);
          this.$emit('toTop');
        }
      },

      submitStrayPlay() {
        const { $store } = this;
        const { commit } = $store;

        commit('BetCart/setShowTracing', false);

        if (Number(this.strayBetAmount) > Number(this.userBalance)) {
          this.isBalanceOK = false;
          this.$message({
            type: 'warning',
            message: this.$t('Common.NoBalanceTip'),
            duration: 5000,
          });
          return;
        }

        const winAmount = this.$lib.truncFloor(this.strayBetAmount * this.strayOdd);
        const maxWin = this.maxWinEx;
        if (winAmount > maxWin) {
          this.$message({
            type: 'warning',
            message: `可贏金額大於最高可贏額 2,000,000`,
            duration: 5000,
          });
          return;
        }

        const list = this.checkBetPlayData(99, this.strayBetAmount);
        if (!list) return;

        this.clearAllMinMaxLimitState();
        this.clearAllMessageState();

        if (this.panelMode === PanelModeEnum.lock || this.settings.showBetConfirm === false) {
          const { defaultStrayAmount } = $store.state.Setting.UserSetting;
          if (defaultStrayAmount.type === 1) {
            defaultStrayAmount.amount = this.strayBetAmount;
          }
          $store.dispatch('BetCart/submitCarts', { list, isStray: true, isQuick: false });
        } else {
          commit('BetCart/setPanelMode', PanelModeEnum.lock);
          this.$emit('toTop');
        }
      },

      cartDataToDisplayData(cartData) {
        return this.$SportLib.cartDataToDisplayData(cartData);
      },
      isShowBlackMask(cart) {
        return cart.Status !== 1 || cart.EvtStatus !== 1;
      },
      onCartListItemKeyboardShow(index) {
        this.currShowKeyboardIndex = index;
      },
      fillEachBetAmountBlurHandler() {
        this.lastBlurInput = { name: 'fillEachBetAmount' };
        this.reCalcBetChart(true);
      },
      fillEachWinAmountBlurHandler() {
        this.lastBlurInput = { name: 'fillEachWinAmount' };
        this.reCalcBetChart(true);
      },
      strayBetBlurHandler() {
        this.lastBlurInput = { name: 'strayBetAmount' };
        this.clearMinMaxTextState();
        if (this.showBetCartList.length !== 0 && this.strayBetAmount !== null) {
          const BetMin = 100;

          if (this.strayBetAmount < BetMin) {
            this.strayBetAmount = BetMin;
            this.isShowMinText = true;
          }

          if (this.strayBetAmount > this.userBalance) {
            this.strayBetAmount = this.userBalance;
            this.isShowMaxText = true;
          }

          this.checkStrayMaxLimit();
        }

        this.reCalcStrayBetChart();
      },
      checkStrayMaxLimit() {
        const winAmount = this.$lib.truncFloor(this.strayBetAmount * this.strayOdd);
        if (winAmount > this.userWinMax) {
          this.strayBetAmount = this.$lib.truncFloor(
            this.userWinMax / this.$lib.trunc(this.strayOdd)
          );
        }
      },
      inputFocusEvent({ from, BetMax }) {
        if (from === 'betAmount') {
          this.isShowMaxChip = true;
          this.theMaxChipValue = BetMax;
        } else {
          this.isShowMaxChip = false;
        }
      },
      listCardItemLastBlurInputEvent(lastBlurInputData) {
        if (this.lastBlurInput.from === lastBlurInputData.from) {
          this.isShowBetKB = !this.isShowBetKB;
        }
        this.lastBlurInput = lastBlurInputData;
        if (!lastBlurInputData.clear) this.reCalcBetChart(true);
      },
      onChipClick(value) {
        this.processLastBlurInput(value);
      },
      keyBoardAddEvent(addNum) {
        this.processLastBlurInput(addNum);
      },
      keyBoardAssignEvent(newNum) {
        this.processLastBlurInput(newNum, true);
      },
      processLastBlurInput(value, isAssignMode = false) {
        const { lastBlurInput, showBetCartList, userBalance } = this;
        if (lastBlurInput.name === 'rowItem') {
          const cartIndex = showBetCartList.findIndex(
            ({ GameID }) => GameID === lastBlurInput.GameID
          );
          if (cartIndex > -1) {
            const cart = showBetCartList[cartIndex];
            const { BetMax } = cart;
            const max = Math.min(BetMax, userBalance);
            let newAmount = 0;
            let fixed = false;
            if (lastBlurInput.from === 'betAmount') {
              if (cart.betAmount === null) {
                if (value !== -1) newAmount = value;
                else newAmount = null;
              } else {
                if (isAssignMode) {
                  if (value === -1) {
                    if (cart.betAmount >= 10) {
                      newAmount = cart.betAmount.toString().slice(0, -1);
                    } else {
                      newAmount = null;
                    }
                  } else {
                    newAmount = cart.betAmount.toString() + value.toString();
                  }
                } else {
                  newAmount = parseInt(cart.betAmount) + value;
                }
              }
              if (newAmount === null) {
                cart.betAmount = null;
                cart.winAmount = null;
              } else if (newAmount > max) {
                cart.betAmount = max;
                fixed = true;
              } else {
                cart.betAmount = newAmount;
              }
              this.reCalcBetChart();
              if (fixed) {
                cart.isShowMaxText = true;
                if (max == userBalance) {
                  cart.isShowMaxText = 2;
                }
                setTimeout(() => {
                  cart.isShowMaxText = false;
                }, 2000);
              }
            } else {
              if (cart.winAmount === null) {
                if (value !== -1) newAmount = value;
                else newAmount = null;
              } else {
                if (isAssignMode) {
                  if (value === -1) {
                    if (cart.winAmount >= 10) {
                      newAmount = cart.winAmount.toString().slice(0, -1);
                    } else {
                      newAmount = null;
                    }
                  } else {
                    newAmount = cart.winAmount.toString() + value.toString();
                  }
                } else {
                  newAmount = parseInt(cart.winAmount) + value;
                }
              }
              const winAmount = newAmount;
              if (winAmount === null) {
                cart.betAmount = null;
                cart.winAmount = null;
              } else {
                const displayData = this.cartDataToDisplayData(cart);
                const odds = displayData.showOdd;
                let betAmount = (winAmount / odds).toFixed();
                const max = Math.min(BetMax, userBalance);
                if (betAmount > max) {
                  betAmount = max;
                  fixed = true;
                }
                cart.betAmount = betAmount;
              }
              this.reCalcBetChart();
              if (fixed) {
                cart.isShowMaxText = true;
                console.log(cart.isShowMaxText);
                if (max == userBalance) {
                  cart.isShowMaxText = 2;
                }
                setTimeout(() => {
                  cart.isShowMaxText = false;
                }, 2000);
              }
            }
          } else {
            lastBlurInput.name = 'fillEachBetAmount';
          }
        }
        if (
          lastBlurInput.name === 'fillEachBetAmount' ||
          lastBlurInput.name === 'fillEachWinAmount'
        ) {
          let newAmount = 0;
          if (lastBlurInput.name === 'fillEachBetAmount') {
            const fillEachBetAmount = this.fillEachBetAmount;
            if (fillEachBetAmount === null) {
              if (value !== -1) newAmount = value;
              else newAmount = null;
            } else {
              if (isAssignMode) {
                if (value === -1) {
                  if (fillEachBetAmount >= 10) {
                    newAmount = fillEachBetAmount.toString().slice(0, -1);
                  } else {
                    newAmount = null;
                  }
                } else {
                  newAmount = fillEachBetAmount.toString() + value.toString();
                }
              } else {
                newAmount = parseInt(fillEachBetAmount) + value;
              }
            }
            if (newAmount === null) {
              this.fillEachBetAmount = '';
            } else {
              this.fillEachBetAmount = newAmount.toString();
            }
            this.fillEachBetAmountHandler();
          } else {
            const fillEachWinAmount = this.fillEachWinAmount;
            if (fillEachWinAmount === null) {
              if (value !== -1) newAmount = value;
              else newAmount = null;
            } else {
              if (isAssignMode) {
                if (value === -1) {
                  if (fillEachWinAmount >= 10) {
                    newAmount = fillEachWinAmount.toString().slice(0, -1);
                  } else {
                    newAmount = null;
                  }
                } else {
                  newAmount = fillEachWinAmount.toString() + value.toString();
                }
              } else {
                newAmount = parseInt(fillEachWinAmount) + value;
              }
            }
            if (newAmount === null) {
              this.fillEachWinAmount = '';
            } else {
              this.fillEachWinAmount = newAmount.toString();
            }
            this.fillEachWinAmountHandler();
          }
        } else if (lastBlurInput.name === 'strayBetAmount') {
          let newAmount = 0;
          let fixed = false;
          const strayBetAmount = this.strayBetAmount;
          if (strayBetAmount === null || strayBetAmount === '') {
            if (value === -1) {
              newAmount = null;
            } else {
              newAmount = value;
            }
          } else {
            if (isAssignMode) {
              if (value === -1) {
                if (strayBetAmount >= 10) {
                  newAmount = strayBetAmount.toString().slice(0, -1);
                } else {
                  newAmount = null;
                }
              } else {
                newAmount = parseInt(strayBetAmount.toString() + value.toString());
              }
            } else {
              newAmount = parseInt(strayBetAmount) + value;
            }
          }
          if (newAmount === null) {
            this.strayBetAmount = null;
          } else {
            const userBalance = parseInt(this.userBalance);
            if (newAmount > userBalance) {
              newAmount = userBalance;
              fixed = true;
            }
            const winAmount = this.$lib.truncFloor(newAmount * this.strayOdd);
            if (winAmount > this.userWinMax) {
              newAmount = (this.userWinMax / this.strayOdd).toFixed();
              fixed = true;
            }
            this.strayBetAmount = newAmount.toString();
          }
          this.reCalcStrayBetChart();
          if (fixed) {
            this.isShowMaxText = true;
            setTimeout(() => {
              this.isShowMaxText = false;
            }, 2000);
          }
        }
      },
      onInputFocus() {
        if (this.MOBILE_APP) {
          // 強制不調用手機虛擬鍵盤
          document.activeElement.blur();
        }
        this.isShowMaxChip = false;
      },
      clearInput(type) {
        if (type === 'normal') {
          const list = this.$store.state.BetCart.betCartList;
          this.fillEachBetAmount = null;
          this.fillEachWinAmount = null;
          list.forEach((item) => {
            item.betAmount = null;
            item.winAmount = null;
          });
          this.reCalcBetChart();
        } else if (type === 'stray') {
          this.strayBetAmount = null;
          this.reCalcStrayBetChart();
        }
      },
      clearItemInputByIndex(index) {
        const list = this.$store.state.BetCart.betCartList;
        const item = list[index];
        if (item) {
          item.betAmount = null;
          item.winAmount = null;
          this.reCalcBetChart();
        }
      },
      // will: 包牌
      toggleBet97Mode() {
        this.bet97Mode = !this.bet97Mode;

        if (this.bet97Mode) {
          const { betItemCount } = this;
          let amount = this.$store.state.Setting.UserSetting.defaultStrayAmount.amount;
          const list = this.$store.state.BetCart.betCartList;
          const item = list[0];
          if (amount < item.BetMin) {
            amount = item.BetMin;
          }

          if (betItemCount === 3) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
          } else if (betItemCount === 4) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
            this.bet4in1Amount || (this.bet4in1Amount = amount);
          } else if (betItemCount === 5) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
            this.bet4in1Amount || (this.bet4in1Amount = amount);
            this.bet5in1Amount || (this.bet5in1Amount = amount);
          } else if (betItemCount === 6) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
            this.bet4in1Amount || (this.bet4in1Amount = amount);
            this.bet5in1Amount || (this.bet5in1Amount = amount);
            this.bet6in1Amount || (this.bet6in1Amount = amount);
          } else if (betItemCount === 7) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
            this.bet4in1Amount || (this.bet4in1Amount = amount);
            this.bet5in1Amount || (this.bet5in1Amount = amount);
            this.bet6in1Amount || (this.bet6in1Amount = amount);
            this.bet7in1Amount || (this.bet7in1Amount = amount);
          } else if (betItemCount === 8) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
            this.bet4in1Amount || (this.bet4in1Amount = amount);
            this.bet5in1Amount || (this.bet5in1Amount = amount);
            this.bet6in1Amount || (this.bet6in1Amount = amount);
            this.bet7in1Amount || (this.bet7in1Amount = amount);
            this.bet8in1Amount || (this.bet8in1Amount = amount);
          } else if (betItemCount === 9) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
            this.bet4in1Amount || (this.bet4in1Amount = amount);
            this.bet5in1Amount || (this.bet5in1Amount = amount);
            this.bet6in1Amount || (this.bet6in1Amount = amount);
            this.bet7in1Amount || (this.bet7in1Amount = amount);
            this.bet8in1Amount || (this.bet8in1Amount = amount);
            this.bet9in1Amount || (this.bet9in1Amount = amount);
          } else if (betItemCount === 10) {
            this.bet2in1Amount || (this.bet2in1Amount = amount);
            this.bet3in1Amount || (this.bet3in1Amount = amount);
            this.bet4in1Amount || (this.bet4in1Amount = amount);
            this.bet5in1Amount || (this.bet5in1Amount = amount);
            this.bet6in1Amount || (this.bet6in1Amount = amount);
            this.bet7in1Amount || (this.bet7in1Amount = amount);
            this.bet8in1Amount || (this.bet8in1Amount = amount);
            this.bet9in1Amount || (this.bet9in1Amount = amount);
            this.bet10in1Amount || (this.bet10in1Amount = amount);
          }
        }
      },
      clearBet2in1Amount() {
        this.bet2in1Amount = '';
      },
      clearBet3in1Amount() {
        this.bet3in1Amount = '';
      },
      clearBet4in1Amount() {
        this.bet4in1Amount = '';
      },
      clearBet5in1Amount() {
        this.bet5in1Amount = '';
      },
      clearBet6in1Amount() {
        this.bet6in1Amount = '';
      },
      clearBet7in1Amount() {
        this.bet7in1Amount = '';
      },
      clearBet8in1Amount() {
        this.bet8in1Amount = '';
      },
      clearBet9in1Amount() {
        this.bet9in1Amount = '';
      },
      clearBet10in1Amount() {
        this.bet10in1Amount = '';
      },
      clearBet97() {
        this.bet2in1Amount = '';
        this.bet3in1Amount = '';
        this.bet4in1Amount = '';
        this.bet5in1Amount = '';
        this.bet6in1Amount = '';
        this.bet7in1Amount = '';
        this.bet8in1Amount = '';
        this.bet9in1Amount = '';
        this.bet10in1Amount = '';
        this.bet97Mode = false;
        this.cancelHandler();
      },
      updateBet97() {
        const { betItemCount } = this;
        if (betItemCount < 3) {
          this.bet2in1Amount = '';
          this.bet3in1Amount = '';
          this.bet4in1Amount = '';
          this.bet5in1Amount = '';
          this.bet6in1Amount = '';
          this.bet7in1Amount = '';
          this.bet8in1Amount = '';
          this.bet9in1Amount = '';
          this.bet10in1Amount = '';
        } else if (betItemCount < 4) {
          this.bet4in1Amount = '';
          this.bet5in1Amount = '';
          this.bet6in1Amount = '';
          this.bet7in1Amount = '';
          this.bet8in1Amount = '';
          this.bet9in1Amount = '';
          this.bet10in1Amount = '';
        } else if (betItemCount < 5) {
          this.bet5in1Amount = '';
          this.bet6in1Amount = '';
          this.bet7in1Amount = '';
          this.bet8in1Amount = '';
          this.bet9in1Amount = '';
          this.bet10in1Amount = '';
        } else if (betItemCount < 6) {
          this.bet6in1Amount = '';
          this.bet7in1Amount = '';
          this.bet8in1Amount = '';
          this.bet9in1Amount = '';
          this.bet10in1Amount = '';
        } else if (betItemCount < 7) {
          this.bet7in1Amount = '';
          this.bet8in1Amount = '';
          this.bet9in1Amount = '';
          this.bet10in1Amount = '';
        } else if (betItemCount < 8) {
          this.bet8in1Amount = '';
          this.bet9in1Amount = '';
          this.bet10in1Amount = '';
        } else if (betItemCount < 9) {
          this.bet9in1Amount = '';
          this.bet10in1Amount = '';
        } else if (betItemCount < 10) {
          this.bet10in1Amount = '';
        }
        if (
          !this.bet2in1Amount &&
          !this.bet3in1Amount &&
          !this.bet4in1Amount &&
          !this.bet5in1Amount &&
          !this.bet6in1Amount &&
          !this.bet7in1Amount &&
          !this.bet8in1Amount &&
          !this.bet9in1Amount &&
          !this.bet10in1Amount
        ) {
          this.$store.commit('BetCart/setPanelMode', PanelModeEnum.normal);
        }
      },
      inputBet2in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet2in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet2in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < length; i2++) {
              const odds2 = betStrayOddsList[i2];
              const odds = (1 + odds1) * (1 + odds2) - 1;
              list.push(odds);
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet2in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet3in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet3in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet3in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 2;
          const end2 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < length; i3++) {
                const odds3 = betStrayOddsList[i3];
                const odds = (1 + odds1) * (1 + odds2) * (1 + odds3) - 1;
                list.push(odds);
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet3in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet4in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet4in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet4in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 3;
          const end2 = length - 2;
          const end3 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < length; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  const odds = (1 + odds1) * (1 + odds2) * (1 + odds3) * (1 + odds4) - 1;
                  list.push(odds);
                }
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet4in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet5in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet5in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet5in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 4;
          const end2 = length - 3;
          const end3 = length - 2;
          const end4 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < length; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    const odds =
                      (1 + odds1) * (1 + odds2) * (1 + odds3) * (1 + odds4) * (1 + odds5) - 1;
                    list.push(odds);
                  }
                }
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet5in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet6in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet6in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet6in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 5;
          const end2 = length - 4;
          const end3 = length - 3;
          const end4 = length - 2;
          const end5 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < length; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      const odds =
                        (1 + odds1) *
                          (1 + odds2) *
                          (1 + odds3) *
                          (1 + odds4) *
                          (1 + odds5) *
                          (1 + odds6) -
                        1;
                      list.push(odds);
                    }
                  }
                }
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet6in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet7in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet7in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet7in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 6;
          const end2 = length - 5;
          const end3 = length - 4;
          const end4 = length - 3;
          const end5 = length - 2;
          const end6 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < length; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        const odds =
                          (1 + odds1) *
                            (1 + odds2) *
                            (1 + odds3) *
                            (1 + odds4) *
                            (1 + odds5) *
                            (1 + odds6) *
                            (1 + odds7) -
                          1;
                        list.push(odds);
                      }
                    }
                  }
                }
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet7in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet8in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet8in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet8in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 7;
          const end2 = length - 6;
          const end3 = length - 5;
          const end4 = length - 4;
          const end5 = length - 3;
          const end6 = length - 2;
          const end7 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < end7; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        for (let i8 = i7 + 1; i8 < length; i8++) {
                          const odds8 = betStrayOddsList[i8];
                          const odds =
                            (1 + odds1) *
                              (1 + odds2) *
                              (1 + odds3) *
                              (1 + odds4) *
                              (1 + odds5) *
                              (1 + odds6) *
                              (1 + odds7) *
                              (1 + odds8) -
                            1;
                          list.push(odds);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet8in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet9in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet9in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet9in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 8;
          const end2 = length - 7;
          const end3 = length - 6;
          const end4 = length - 5;
          const end5 = length - 4;
          const end6 = length - 3;
          const end7 = length - 2;
          const end8 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < end7; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        for (let i8 = i7 + 1; i8 < end8; i8++) {
                          const odds8 = betStrayOddsList[i8];
                          for (let i9 = i8 + 1; i9 < length; i9++) {
                            const odds9 = betStrayOddsList[i9];
                            const odds =
                              (1 + odds1) *
                                (1 + odds2) *
                                (1 + odds3) *
                                (1 + odds4) *
                                (1 + odds5) *
                                (1 + odds6) *
                                (1 + odds7) *
                                (1 + odds8) *
                                (1 + odds9) -
                              1;
                            list.push(odds);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet9in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      inputBet10in1Amount(event) {
        let { value } = event.target;
        const { betStrayOddsList, maxWinEx } = this;
        if (value < 0) {
          this.bet10in1Amount = '';
        }
        if (!Number.isInteger(value)) {
          value = value | 0;
          this.bet10in1Amount = value;
        }
        if (value > 0) {
          const { length } = betStrayOddsList;
          const list = [];
          const end1 = length - 9;
          const end2 = length - 8;
          const end3 = length - 7;
          const end4 = length - 6;
          const end5 = length - 5;
          const end6 = length - 4;
          const end7 = length - 3;
          const end8 = length - 2;
          const end9 = length - 1;
          for (let i1 = 0; i1 < end1; i1++) {
            const odds1 = betStrayOddsList[i1];
            for (let i2 = i1 + 1; i2 < end2; i2++) {
              const odds2 = betStrayOddsList[i2];
              for (let i3 = i2 + 1; i3 < end3; i3++) {
                const odds3 = betStrayOddsList[i3];
                for (let i4 = i3 + 1; i4 < end4; i4++) {
                  const odds4 = betStrayOddsList[i4];
                  for (let i5 = i4 + 1; i5 < end5; i5++) {
                    const odds5 = betStrayOddsList[i5];
                    for (let i6 = i5 + 1; i6 < end6; i6++) {
                      const odds6 = betStrayOddsList[i6];
                      for (let i7 = i6 + 1; i7 < end7; i7++) {
                        const odds7 = betStrayOddsList[i7];
                        for (let i8 = i7 + 1; i8 < end8; i8++) {
                          const odds8 = betStrayOddsList[i8];
                          for (let i9 = i8 + 1; i9 < end9; i9++) {
                            const odds9 = betStrayOddsList[i9];
                            for (let i10 = i9 + 1; i10 < length; i10++) {
                              const odds10 = betStrayOddsList[i10];
                              const odds =
                                (1 + odds1) *
                                  (1 + odds2) *
                                  (1 + odds3) *
                                  (1 + odds4) *
                                  (1 + odds5) *
                                  (1 + odds6) *
                                  (1 + odds7) *
                                  (1 + odds8) *
                                  (1 + odds9) *
                                  (1 + odds10) -
                                1;
                              list.push(odds);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const maxOdds = Math.max(...list);
          if (maxOdds * value > maxWinEx) {
            this.bet10in1Amount = (maxWinEx / maxOdds) | 0;
            this.showBet97OverMaxWinMessage();
          }
        }
      },
      showBet97OverMaxWinMessage() {
        this.$message({
          type: 'warning',
          message: `單筆可贏金額大於最高可贏額 2,000,000, 已調整為最大可投金額`,
          duration: 5000,
        });
      },
      getBet97Payload() {
        const {
          showBetCartList,
          bet2in1Amount,
          bet3in1Amount,
          bet4in1Amount,
          bet5in1Amount,
          bet6in1Amount,
          bet7in1Amount,
          bet8in1Amount,
          bet9in1Amount,
          bet10in1Amount,
        } = this;
        const payload = [];
        let WagerString = '';
        let BetString = '';
        showBetCartList.forEach((item) => {
          const { CatID, GameID, WagerTypeID, WagerGrpID, wagerPos, HdpPos } = item;
          let cutLine = item.playData.playMethodData.betCutLineDealFunc(item);
          if (!cutLine) cutLine = 0;
          const displayData = this.$SportLib.cartDataToDisplayData(item);
          const odds = parseFloat(displayData.showOdd2);
          const itemString = `${CatID},${GameID},${WagerTypeID},${WagerGrpID},${wagerPos},${HdpPos},${cutLine},${odds},DE`;
          if (WagerString) WagerString += '|';
          WagerString += itemString;
        });
        if (bet2in1Amount) {
          BetString += `2-1,${bet2in1Amount}`;
        }
        if (bet3in1Amount) {
          if (BetString) BetString += '|';
          BetString += `3-1,${bet3in1Amount}`;
        }
        if (bet4in1Amount) {
          if (BetString) BetString += '|';
          BetString += `4-1,${bet4in1Amount}`;
        }
        if (bet5in1Amount) {
          if (BetString) BetString += '|';
          BetString += `5-1,${bet5in1Amount}`;
        }
        if (bet6in1Amount) {
          if (BetString) BetString += '|';
          BetString += `6-1,${bet6in1Amount}`;
        }
        if (bet7in1Amount) {
          if (BetString) BetString += '|';
          BetString += `7-1,${bet7in1Amount}`;
        }
        if (bet8in1Amount) {
          if (BetString) BetString += '|';
          BetString += `8-1,${bet8in1Amount}`;
        }
        if (bet9in1Amount) {
          if (BetString) BetString += '|';
          BetString += `9-1,${bet9in1Amount}`;
        }
        if (bet10in1Amount) {
          if (BetString) BetString += '|';
          BetString += `10-1,${bet10in1Amount}`;
        }
        payload.push({
          BetType: 97,
          WagerString,
          BetString,
          AcceptBetter: this.$store.state.Setting.UserSetting.acceptBetter,
        });
        // console.log(payload);
        return payload;
      },
      submitBet97() {
        const { $store, bet97Amount, userBalance } = this;
        const { commit } = $store;

        if (!bet97Amount) {
          this.$notify.error({
            message: this.$t('BetViewList.PlzInputStrayAmountFirst'),
          });
          return;
        }

        if (Number(bet97Amount) > Number(userBalance)) {
          this.$message({
            type: 'warning',
            message: this.$t('Common.NoBalanceTip'),
            duration: 5000,
          });
          return;
        }

        const payload = this.getBet97Payload();
        this.clearAllMinMaxLimitState();
        this.clearAllMessageState();

        if (this.panelMode === PanelModeEnum.lock || this.settings.showBetConfirm === false) {
          $store.dispatch('BetCart/submitCarts', { list: payload, isStray: true, isQuick: false });
        } else {
          commit('BetCart/setPanelMode', PanelModeEnum.lock);
        }
      },
      keepBet97() {
        this.resultLeftBtnClickHandler();
      },
      closeBet97() {
        this.resultCancelBtnClick();
      },
    },
    mounted() {
      this.intervalEvent = setInterval(() => {
        // result狀態也不更新注單
        if (
          this.groupIndex === 0 &&
          this.panelMode !== this.PanelModeEnum.result &&
          !this.isControlByBetSingle
        ) {
          this.$store.dispatch('BetCart/updateAllCartGames');
        }
      }, 5000);

      if (this.isControlByBetSingle) {
        this.currShowKeyboardIndex = 0;
      }

      window.addEventListener('keydown', this.keyPress);
    },
    beforeDestroy() {
      clearInterval(this.intervalEvent);
      clearInterval(this.intervalEvent2);
      window.removeEventListener('keydown', this.keyPress);
    },
  };
</script>

<style lang="scss" scoped>
  @import './ListCardItem.scss';
  #app[data-theme^='light'] {
    #BetViewList {
      .cardOptionBlock,
      .noData {
        color: black;
      }
      .strayOdd {
        color: #f00;
      }
      .limitTip {
        color: red;
      }
      .strayTopRight {
        .num {
          color: #005aff;
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    #BetViewList {
      .cardOptionBlock,
      .noData,
      .centerTipBlock {
        color: white;
      }
      .strayOdd {
        color: #ff8686;
      }
      .limitTip {
        color: #ff8686;
      }
      .strayTopRight {
        .num {
          color: #ffea01;
        }
        .x {
          color: #ddd;
        }
      }
    }
  }

  #BetViewList {
    position: relative;
    height: calc(100% - 35px);
    overflow: auto;
    &::-webkit-scrollbar {
      /*隱藏滾輪*/
      display: none;
    }
    .redErrorStyle {
      background-color: #deb7b7 !important;
      .cardHeaderRow {
        background-color: #d18d8c;
        color: white;
        .playMethodName,
        .playMethodNameSupport,
        .playBetOdd {
          color: white;
        }
      }
    }

    .cardOptionBlock {
      .strayErrorMessage {
        text-align: left;
        // color: red;
        color: #ff6161;
      }
      .StrayTipBlock {
        background-color: #d66764;
        width: 100%;
        padding: 15px 0px;
        .topTextRow {
          color: white;
          text-align: center;
          margin-bottom: 12px;
        }
        .bottomTextRow {
          color: white;
          display: flex;
          justify-content: center;
          .goldTip {
            color: #ffea01;
            padding: 0 5px;
          }
        }
      }
      .buttonRow {
        display: flex;
        justify-content: space-around;
        .clearBtn,
        .submitBtn,
        .closeBtn,
        .acceptChange {
          width: 48%;
          padding: 10px;
          font-size: 14px;
          cursor: pointer;
          font-weight: bold;
          text-align: center;
        }
        .clearBtn {
          background-color: #838383;
          &:hover {
            background-color: #9a9a9af6;
          }
          color: white;
        }
        .submitBtn {
          background-color: #ffdf1b;
          color: black;
          &:hover {
            background-color: #ffeb68f9;
          }
        }
        .acceptChange {
          background-color: #3fa381;
          color: white;
          &:hover {
            background-color: #62b096;
          }
        }
        .closeBtn {
          background-color: #3fa381;
          color: white;
          &:hover {
            background-color: #62b096;
          }
        }
      }
      .betRows-wrap {
        display: table;
        table-layout: auto;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px 5px;
        flex-direction: column;
        .betInputRow {
          display: table-row;
          position: relative;

          li {
            display: table-cell;
            vertical-align: middle;
            position: relative;
          }

          .betInputTitle {
            width: 25%;
            // text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
            white-space: nowrap;
            text-align: left;
            padding: 0 8px 0 0;
            position: relative;
            &::after {
              content: ':';
              position: absolute;
              top: 50%;
              right: 5px;
              transform: translateY(-50%);
              font-weight: bold;
            }
          }
          input {
            width: 100%;
            height: 30px;
            border: 0px;
            padding: 0 5px;
            &.gray {
              background-color: #c0c0c0;
            }
          }
          .redErrorInput {
            border: 1px solid red !important;
          }
          .betReadInput {
            width: 75%;
            height: 30px;
            line-height: 30px;
            text-align: left;
            padding: 0 5px;
            background-color: #c0c0c0;
          }
          .strayBlock {
            display: flex;
            flex-wrap: wrap;
            padding: 0 5px;
            .strayBlockTop {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .strayTopLeft {
              }
              .strayTopRight {
                display: flex;
                justify-content: center;
                .num {
                  padding-right: 3px;
                }
                .x {
                  color: #777;
                }
              }
            }
            .strayBlockBottom {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              margin-top: 2px;
              .strayOdd {
                width: 100%;

                text-align: left;
                font-size: 12px;
              }
            }
          }
          .betInputRowAbsoluteBlock {
            position: absolute;
            width: 53px;
            height: 100%;
            border-left: 1px solid rgba(128, 128, 128, 0.653);
            top: 0;
            right: 0px;
            color: #004eff;
            line-height: 30px;
            text-align: right;
            padding-right: 10px;
          }
          .btnClearValue {
            position: absolute;
            top: 50%;
            right: 58px;
            transform: translateY(-50%);
            font-size: 16px;
            line-height: 1;
            cursor: pointer;
            color: #000;
            opacity: 0.3;
            &:hover {
              opacity: 0.6;
            }
            &.stray {
              right: 5px;
            }
          }
        }
      }

      .totalRow {
        padding: 12px 5px;
        display: flex;
        justify-content: space-around;
        .halfItem {
          width: 50%;
          text-align: left;
        }
        .halfItemChange {
          background-color: #01a2e8;
          color: black;
          padding: 2px 8px;
        }
      }
      .strayRow {
        border: 1px solid gray;
        text-align: left;
        padding: 5px;
        font-size: 14px;
        line-height: 14px;
        .strayRowTitle {
          margin-bottom: 5px;
        }
        .strayRowContent {
        }
      }
      .strayLimitTipBlock {
        margin: 7px 0;
      }
      .amountTipRow {
        background-color: #a72120;
        color: #fff;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .noData {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: 100%;
      .noDataItem {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .centerTipBlock {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .centerTip {
        .tipItem {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
    }

    .noDataItemImgContainer {
      &.center {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }
  }
  // will: 包牌
  .bet97 {
    font-size: 13px;
  }
  .bet97-header {
    position: relative;
    height: 32px;
    padding-left: 8px;
    background-color: #eee;
    line-height: 32px;
    text-align: left;
    cursor: pointer;
  }
  .bet97-title {
    font-size: 14px;
  }
  .bet97-icon {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 18px;
    font-weight: bold;
    opacity: 0.5;
  }
  .bet97-items {
  }
  .bet97-item {
    margin-top: 2px;
    padding: 4px 8px;
    background-color: #eee;
  }
  .bet97-item-name {
    height: 20px;
    line-height: 20px;
    text-align: left;
    color: #444;
  }
  .bet97-item-inputs {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 32px;
  }
  .bet97-item-count {
    flex: 0 0 40px;
    padding: 0 4px;
    color: #f00;
    text-align: right;
    &::after {
      content: 'x';
      padding: 0 4px;
      color: #222;
    }
  }
  .bet97-item-amount {
    flex: 1 1 64px;
    position: relative;
  }
  .bet97-item-amount-input {
    width: 100%;
    padding: 3px 4px 0;
    border: 1px solid #aaa;
    border-radius: 2px;
    background-color: #fff;
    line-height: 20px;
  }
  .bet97-item-amount-clear {
    position: absolute;
    right: 6px;
    top: 8px;
    color: #aaa;
    cursor: pointer;
  }
  .bet97-item-win {
    flex: 1 1 64px;
  }
  .bet97-item-win-input {
    width: 100%;
    padding: 3px 4px 0;
    border: 1px solid #aaa;
    border-radius: 2px;
    background-color: #ccc;
    line-height: 20px;
    pointer-events: none;
  }
  .bet97-info {
    display: flex;
  }
  .bet97-buttons {
    display: flex;
  }
</style>
