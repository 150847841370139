<template>
  <div v-if="item">
    <div v-for="(groupitem, index) in item.GroupBet" :key="index" class="listCardItem">
      <div class="cardHeaderRow">
        <div class="strayContentBlockRow">
          <div class="strayTitleInfoText">
            {{ option }}{{ $t('Common.string') }}1 - {{ index + 1 }}
          </div>
        </div>
      </div>
      <div class="cardContentBlock">
        <div
          v-for="(betitemIndex, index2) in groupitem.GroupContent"
          :key="index2"
          :set="(betitem = item.dataBet[betitemIndex])"
          class="strayDetailBlock"
          style="display: flex; flex-flow: row nowrap; padding: 0; border-width: 0"
        >
          <div
            style="
              width: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #aaa;
              border-right: none;
            "
          >
            {{ betitemIndex + 1 }}
          </div>
          <div
            class="strayDetailBlock"
            :class="{ DeleteBG: ['D', 'V'].includes(betitem.StatusType) }"
            style="flex-grow: 1; margin: 0"
          >
            <div class="strayDetailTitle">
              <span
                v-if="
                  betitem.GameType === GAME_PLAYING_TYPE &&
                  GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                  GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
                "
                class="ScoreColor2"
                style="display: inline"
              >
                {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
              </span>
              <span class="betPosName">
                {{ betitem.WagerPosName }}
              </span>
              <span class="cutLine">
                {{ betitem.CutLine }}
              </span>
              <template v--if="historyItem.BetType !== BET_SUPER8_PARLAY_TYPE">
                @
                <span class="cutLine">
                  {{ betitem.PayoutOddsStr }}
                </span>
              </template>
            </div>
            <div class="strayDetailLine" />
            <ul style="padding: 4px">
              <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                {{ betitem.CatName }} - {{ betitem.LeagueName }}
                <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
              </li>
              <li
                v-if="betitem.CatID === GAME_HORSE_DOG_CAT"
                :class="{ cancel: betitem.ItemStatus !== 'Y' }"
              >
                <span v-if="settled" class="ScoreColor2">[{{ betitem.HomeScore }}]</span>
                {{ betitem.HomeTeam }}
              </li>
              <li
                v-else-if="betitem.CatID === GAME_LOTTERY_CAT"
                :class="{ cancel: betitem.ItemStatus !== 'Y' }"
              >
                <template v-if="betitem.HomeTeam">
                  <span v-if="settled" class="ScoreColor2">[{{ betitem.HomeScore }}]</span>
                  {{ betitem.HomeTeam }}
                </template>
                <template v-else-if="betitem.AwayTeam">
                  <span v-if="settled" class="ScoreColor2">[{{ betitem.HomeScore }}]</span>
                  {{ betitem.AwayTeam }}
                </template>
              </li>
              <li
                v-else-if="betitem.CatID === GAME_INDEX_CAT"
                :class="{ cancel: betitem.ItemStatus !== 'Y' }"
              >
                <span v-if="settled" class="ScoreColor2">[{{ betitem.HomeScore }}]</span>
                {{ betitem.AwayTeam }}
              </li>
              <li
                v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)"
                :class="{ cancel: betitem.ItemStatus !== 'Y' }"
              >
                <template v-if="betitem.HomeTeam">
                  <span v-if="settled" class="ScoreColor2">[{{ betitem.HomeScore }}]</span>
                  {{ betitem.HomeTeam }}
                  <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                </template>
                <template v-else-if="betitem.AwayTeam">
                  <span v-if="settled" class="ScoreColor2">[{{ betitem.AwayScore }}]</span>
                  {{ betitem.AwayTeam }}
                </template>
              </li>
              <li
                v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                :class="{ cancel: betitem.ItemStatus !== 'Y' }"
              >
                {{ betitem.HomeTeam }}
              </li>
              <li v-else :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                <span v-if="settled && betitem.EvtType !== 19" class="ScoreColor2"
                  >[{{ betitem.HomeScore }}]</span
                >
                {{ betitem.HomeTeam }}
                <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                vs
                <span v-if="settled && betitem.EvtType !== 19" class="ScoreColor2"
                  >[{{ betitem.AwayScore }}]</span
                >
                {{ betitem.AwayTeam }}
                <span v-if="settled && betitem.EvtType === 19" class="ScoreColor2"
                  >[總和{{ betitem.HomeScore }}]</span
                >
              </li>
              <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                {{ $t('Common.GameTime') }} :
                <span class="startGameTime">{{ betitem.ScheduleTimeStr.slice(0, 16) }}</span>
              </li>
              <li v-if="betitem.ItemStatusContent">
                <span style="color: red">({{ betitem.ItemStatusContent }})</span>
              </li>
              <li v-if="betitem.ItemStatus !== 'Y'">
                <span class="ScoreColor2">{{ $t('Common.GameCancel') }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="cardContentBlockRow" style="padding: 0 8px">
          <template v-if="settled">
            {{ $t('Common.Bet') }} / {{ $t('HistoryRecord.BetAmount2') }} :
            {{ toCurrency(groupitem.Amount) }} / {{ toCurrency(groupitem.EffectiveAmount) }}
          </template>
          <template v-else> {{ $t('Common.Bet') }} : {{ toCurrency(groupitem.Amount) }} </template>
        </div>
        <div class="cardContentBlockRow" style="padding: 0 8px">
          {{ $t('BetViewList.HighBack') }} :
          {{ toCurrency(groupitem.ToWin) }}
        </div>
        <div v-if="settled" class="cardContentBlockRow" style="padding: 0 8px">
          {{ $t('Common.Result') }} :
          <span
            class="resultText"
            style="padding: 0 6px"
            :style="[resultTextColorJudge(groupitem.ResultAmount)]"
          >
            {{ toCurrency(groupitem.ResultAmount) }}
          </span>
        </div>
        <div v-if="groupitem.Remarks" class="cardContentBlockRow Remarks">
          {{ groupitem.Remarks }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    GAME_PLAYING_TYPE,
    GAME_HORSE_DOG_CAT,
    GAME_LOTTERY_CAT,
    GAME_INDEX_CAT,
    GAME_FOOTBALL_CATS,
    GAME_HANDICAP_WAGERS,
    GAME_ONE_TEAM_SCORE_WAGERS,
    GAME_CHAMPION_WAGERS,
  } from '@/config';
  import { toCurrency } from '@/config/balance';
  import { getBet97Sets } from '@/config/bet';
  import { apiGetTicket97 } from '@/api/game';

  export default {
    data() {
      return {
        GAME_PLAYING_TYPE,
        GAME_HORSE_DOG_CAT,
        GAME_LOTTERY_CAT,
        GAME_INDEX_CAT,
        GAME_FOOTBALL_CATS,
        GAME_HANDICAP_WAGERS,
        GAME_ONE_TEAM_SCORE_WAGERS,
        GAME_CHAMPION_WAGERS,
        item: null,
      };
    },
    computed: {
      key() {
        const { key } = this.$route.params;
        return key;
      },
      settled() {
        const { type } = this.$route.params;
        return !!Number(type);
      },
      option() {
        const option = this.item?.Option.split('-')[0];
        return option ? Number(option) : 0;
      },
      bets() {
        return this.item?.dataBet.length || 0;
      },
      sets() {
        const { option, bets } = this;
        return getBet97Sets(option, bets);
      },
    },
    watch: {
      key: {
        handler() {
          this.updateData();
        },
        immediate: true,
      },
    },
    methods: {
      toCurrency,
      updateData() {
        const { key } = this;
        if (key) {
          this.$store.commit('showLoading');
          apiGetTicket97({ TicketID: key })
            .then((response) => {
              this.item = response.data.list[0];
            })
            .finally(() => {
              this.$store.commit('hideLoading');
            });
        }
      },
      resultTextColorJudge(value) {
        const color = value > 0 ? 'green' : value < 0 ? 'red' : 'black';
        return { color };
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/components/ListCardItem.scss';
  .strayDetailContentBlockRow {
    .HomeTeamSign {
      margin-left: 5px;
    }
    .vs {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  .vs {
    margin-right: 5px;
  }

  .sell {
    padding: 4px 8px;
  }
  .selling {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px 16px;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    background-color: #dddddd;
  }
  .selling-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 4px 2px;
  }
  .selling-content {
    padding: 4px;
  }
  .selling-btns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    gap: 16px;
  }
  .selling-confirm {
    padding: 4px 16px;
    border: 1px solid #34b28d;
    border-radius: 8px;
    box-shadow: inset 1px 1px 1px rgb(255, 255, 255, 0.3);
    background-color: #06a378;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #088e6a;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .selling-cancel {
    padding: 4px 16px;
    border: 1px solid #ff7074;
    border-radius: 8px;
    box-shadow: inset 1px 1px 1px rgb(255, 255, 255, 0.3);
    background-color: #f45358;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #d84a4e;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .selling-loading {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    &::v-deep .el-loading-mask {
      background-color: transparent !important;
    }
    &::v-deep .el-loading-spinner {
      top: 0 !important;
      margin-top: 0 !important;
    }
    &::v-deep .el-loading-spinner .circular {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .selling-info {
    display: inline-block;
  }
  .sell-btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    background: linear-gradient(#f5f3f5, #d3d3d3);
    cursor: pointer;
    &:hover {
      background: linear-gradient(#d3d3d3, #f5f3f5);
    }
  }
  .sell-icon {
    width: 20px;
    height: 20px;

    &.info {
      position: absolute;
      right: 8px;
      top: 36px;
    }
  }
  .sell-text {
    font-size: 13px;
    color: #7d7d7d;
  }
  .sell-amount {
    padding-top: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #666666;
  }
  .bet97-link {
    color: #409eff;
    cursor: pointer;
  }
  .ScoreColor2 {
    color: red;
  }
</style>
