<template>
  <div id="mStrayCounter">
    <StrayCount />
  </div>
</template>

<script>
  import StrayCount from '@/components/StrayCount';

  export default {
    components: {
      StrayCount,
    },
  };
</script>

<style lang="scss" scoped>
  #mStrayCounter {
    height: 100%;
    overflow-y: auto;
    background-color: #ccc;
  }
</style>
