<template>
  <div id="HistoryRecord">
    <div class="Record_head">
      <h3>
        {{ $t('HistoryRecord.BetHistoryRecord') }}
      </h3>
    </div>
    <div class="Record_top">
      <ul>
        <li v-if="settled" class="active">
          {{ $t('HistoryRecord.IsCountBet') }}
        </li>
        <li v-else class="active">
          {{ $t('GamesBetInfo.NotCountBet') }}
        </li>
      </ul>
    </div>
    <div v-if="item" class="Record_main">
      <div class="Record_mainContainer">
        <table border="0" cellspacing="0" cellpadding="0">
          <tr>
            <th width="180">
              {{ $t('HistoryRecord.BetMessage') }}
            </th>
            <th>
              {{ $t('Common.BetContent') }}
            </th>
            <th width="120">
              {{ $t('Common.Bet97Sets') }}
            </th>
          </tr>
          <tr class="rt_data" :class="{ DeleteBG: ['D', 'V'].includes(item.StatusType) }">
            <td
              class="rt_info"
              style="text-align: left"
              :class="['D', 'V'].includes(item.StatusType) ? 'textLine' : ''"
            >
              <ul>
                <li> {{ $t('Common.DownBet') }} : {{ item.BetTimeStr }} </li>
                <li>
                  {{ $t('HistoryRecord.TypeStray3') }}
                  {{ option }}{{ $t('Common.string') }}1 x
                  {{ sets }}
                </li>
                <li> {{ $t('Common.BetID') }} : {{ item.TicketID }} </li>
                <li> {{ $t('Common.Odd') }} : {{ $t('GamesSetup.NotIncludePrincipal') }} </li>
                <li v-if="item.Remarks"> {{ item.Remarks }} </li>
              </ul>
            </td>
            <td class="rt_fs">
              <div
                v-for="(
                  {
                    ItemStatus,
                    ItemStatusContent,
                    GameType,
                    CatID,
                    CatName,
                    LeagueName,
                    ScheduleTimeStr,
                    ItemName,
                    HomeTeam,
                    HomeScore,
                    AwayTeam,
                    AwayScore,
                    CurHomeScore,
                    CurAwayScore,
                    WagerTypeID,
                    WagerPosName,
                    CutLine,
                    PayoutOddsStr,
                  },
                  index
                ) in item.dataBet"
                :key="index"
                class="rt_fs_list"
              >
                <div>
                  {{ index + 1 }}
                </div>
                <ul>
                  <li :class="{ cancel: ItemStatus !== 'Y' }">
                    {{ CatName }} - {{ LeagueName }}
                    <template v-if="ItemName"> - {{ ItemName }} </template>
                  </li>
                  <li v-if="CatID === GAME_HORSE_DOG_CAT" :class="{ cancel: ItemStatus !== 'Y' }">
                    <span v-if="settled" class="ScoreColor">[{{ HomeScore }}]</span>
                    {{ HomeTeam }}
                  </li>
                  <li
                    v-else-if="CatID === GAME_LOTTERY_CAT"
                    :class="{ cancel: ItemStatus !== 'Y' }"
                  >
                    <template v-if="HomeTeam">
                      <span v-if="settled" class="ScoreColor">[{{ HomeScore }}]</span>
                      {{ HomeTeam }}
                    </template>
                    <template v-else-if="AwayTeam">
                      <span v-if="settled" class="ScoreColor">[{{ HomeScore }}]</span>
                      {{ AwayTeam }}
                    </template>
                  </li>
                  <li v-else-if="CatID === GAME_INDEX_CAT" :class="{ cancel: ItemStatus !== 'Y' }">
                    <span v-if="settled" class="ScoreColor">[{{ HomeScore }}]</span>
                    {{ AwayTeam }}
                  </li>
                  <li
                    v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(WagerTypeID)"
                    :class="{ cancel: ItemStatus !== 'Y' }"
                  >
                    <template v-if="HomeTeam">
                      <span v-if="settled" class="ScoreColor">[{{ HomeScore }}]</span>
                      {{ HomeTeam }}
                      <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                    </template>
                    <template v-else-if="AwayTeam">
                      <span v-if="settled" class="ScoreColor">[{{ AwayScore }}]</span>
                      {{ AwayTeam }}
                    </template>
                  </li>
                  <li
                    v-else-if="GAME_CHAMPION_WAGERS.includes(WagerTypeID)"
                    :class="{ cancel: ItemStatus !== 'Y' }"
                  >
                    {{ HomeTeam }}
                  </li>
                  <li v-else :class="{ cancel: ItemStatus !== 'Y' }">
                    <span v-if="settled" class="ScoreColor">[{{ HomeScore }}]</span>
                    {{ HomeTeam }}
                    <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                    vs
                    <span v-if="settled" class="ScoreColor">[{{ AwayScore }}]</span>
                    {{ AwayTeam }}
                  </li>
                  <li :class="{ cancel: ItemStatus !== 'Y' }">
                    {{ $t('Common.Bet') }} :
                    <span
                      v-if="
                        GameType === GAME_PLAYING_TYPE &&
                        GAME_FOOTBALL_CATS.includes(CatID) &&
                        GAME_HANDICAP_WAGERS.includes(WagerTypeID)
                      "
                      class="ScoreColor"
                    >
                      {{ `(${CurHomeScore}:${CurAwayScore})` }}
                    </span>
                    <span class="betTeamColor">
                      {{ WagerPosName }}
                    </span>
                    <span v-if="!GAME_CHAMPION_WAGERS.includes(WagerTypeID)" class="oddColor">
                      {{ CutLine }}
                    </span>
                    @
                    <span class="oddColor">
                      {{ PayoutOddsStr }}
                    </span>
                  </li>
                  <li :class="{ cancel: ItemStatus !== 'Y' }">
                    {{ $t('Common.GameTime') }} :
                    <span class="startGameTime">{{ ScheduleTimeStr.slice(0, 16) }}</span>
                  </li>
                  <li v-if="ItemStatusContent">
                    <span style="color: red">({{ ItemStatusContent }})</span>
                  </li>
                  <li v-if="ItemStatus !== 'Y'">
                    <span class="ScoreColor">{{ $t('Common.GameCancel') }}</span>
                  </li>
                </ul>
              </div>
            </td>
            <td class="rt_betval" style="text-align: center">
              {{ $t('Common.Bet97TotalSets1') }}
              {{ sets }}
              {{ $t('Common.Bet97TotalSets2') }}
            </td>
          </tr>
        </table>
        <template>
          <table v-if="settled" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <th width="180">
                {{ $t('HistoryRecord.BetMessage') }}
              </th>
              <th>
                {{ $t('Common.BetContent') }}
              </th>
              <th width="120">
                {{ $t('HistoryRecord.BetAmount') }} / {{ $t('HistoryRecord.BetAmount2') }}
              </th>
              <th width="120">
                {{ $t('Common.CanWin') }}
              </th>
              <th width="120">
                {{ $t('Common.Result') }}
              </th>
            </tr>
            <tr v-for="(groupitem, index) in item.GroupBet" :key="index" class="rt_data">
              <td class="rt_info"> {{ option }}{{ $t('Common.string') }}1 - {{ index + 1 }} </td>
              <td class="rt_fs">
                <div
                  v-for="(betitemIndex, index) in groupitem.GroupContent"
                  :key="index"
                  :set="(betitem = item.dataBet[betitemIndex])"
                >
                  <div
                    class="rt_fs_list"
                    :class="{ DeleteBG: ['D', 'V'].includes(betitem.StatusType) }"
                  >
                    <div>
                      {{ betitemIndex + 1 }}
                    </div>
                    <ul>
                      <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        {{ betitem.CatName }} - {{ betitem.LeagueName }}
                        <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                      </li>
                      <li
                        v-if="betitem.CatID === GAME_HORSE_DOG_CAT"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                      </li>
                      <li
                        v-else-if="betitem.CatID === GAME_LOTTERY_CAT"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <template v-if="betitem.HomeTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.HomeTeam }}
                        </template>
                        <template v-else-if="betitem.AwayTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.AwayTeam }}
                        </template>
                      </li>
                      <li
                        v-else-if="betitem.CatID === GAME_INDEX_CAT"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </li>
                      <li
                        v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <template v-if="betitem.HomeTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.HomeTeam }}
                          <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                        </template>
                        <template v-else-if="betitem.AwayTeam">
                          <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                          {{ betitem.AwayTeam }}
                        </template>
                      </li>
                      <li
                        v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        {{ betitem.HomeTeam }}
                      </li>
                      <li v-else :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                        <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                        vs
                        <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </li>
                      <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        {{ $t('Common.Bet') }} :
                        <span
                          v-if="
                            betitem.GameType === GAME_PLAYING_TYPE &&
                            GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                            GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
                          "
                          class="ScoreColor"
                          style="display: inline"
                        >
                          {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                        </span>
                        <span class="betTeamColor">
                          {{ betitem.WagerPosName }}
                        </span>
                        <span
                          v-if="!GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                          class="oddColor"
                        >
                          {{ betitem.CutLine }}
                        </span>
                        @
                        <span class="oddColor">
                          {{ betitem.PayoutOddsStr }}
                        </span>
                      </li>
                      <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        {{ $t('Common.GameTime') }} :
                        <span class="startGameTime">{{
                          betitem.ScheduleTimeStr.slice(0, 16)
                        }}</span>
                      </li>
                      <li v-if="betitem.ItemStatusContent">
                        <span style="color: red">({{ betitem.ItemStatusContent }})</span>
                      </li>
                      <li v-if="betitem.ItemStatus !== 'Y'">
                        <span class="ScoreColor">{{ $t('Common.GameCancel') }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="rt_betval">
                {{ toCurrency(groupitem.Amount) }} / {{ toCurrency(groupitem.EffectiveAmount) }}
              </td>
              <td class="rt_betval">
                {{ toCurrency(groupitem.ToWin) }}
              </td>
              <td
                class="rt_betval"
                :class="parseInt(groupitem.ResultAmount) < 0 ? 'ScoreColor' : 'resultScore'"
              >
                {{ toCurrency(groupitem.ResultAmount) }}
              </td>
            </tr>
          </table>
          <table v-else border="0" cellspacing="0" cellpadding="0">
            <tr>
              <th width="180">
                {{ $t('HistoryRecord.BetMessage') }}
              </th>
              <th>
                {{ $t('Common.BetContent') }}
              </th>
              <th width="120">
                {{ $t('HistoryRecord.BetAmount') }}
              </th>
              <th width="120">
                {{ $t('Common.CanWin') }}
              </th>
            </tr>
            <tr v-for="(groupitem, index) in item.GroupBet" :key="index" class="rt_data">
              <td class="rt_info"> {{ option }}{{ $t('Common.string') }}1 - {{ index + 1 }} </td>
              <td class="rt_fs">
                <div
                  v-for="(betitemIndex, index) in groupitem.GroupContent"
                  :key="index"
                  :set="(betitem = item.dataBet[betitemIndex])"
                >
                  <div class="rt_fs_list">
                    <div>
                      {{ betitemIndex + 1 }}
                    </div>
                    <ul>
                      <li>
                        {{ betitem.CatName }} - {{ betitem.LeagueName }}
                        <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                      </li>
                      <li v-if="betitem.CatID === GAME_HORSE_DOG_CAT">
                        {{ betitem.HomeTeam }}
                      </li>
                      <li v-else-if="betitem.CatID === GAME_LOTTERY_CAT">
                        <template v-if="betitem.HomeTeam">
                          {{ betitem.HomeTeam }}
                        </template>
                        <template v-else-if="betitem.AwayTeam">
                          {{ betitem.AwayTeam }}
                        </template>
                      </li>
                      <li v-else-if="betitem.CatID === GAME_INDEX_CAT">
                        {{ betitem.AwayTeam }}
                      </li>
                      <li
                        v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <template v-if="betitem.HomeTeam">
                          {{ betitem.HomeTeam }}
                          <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                        </template>
                        <template v-else-if="betitem.AwayTeam">
                          {{ betitem.AwayTeam }}
                        </template>
                      </li>
                      <li
                        v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        {{ betitem.HomeTeam }}
                      </li>
                      <li v-else>
                        {{ betitem.HomeTeam }}
                        <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                        vs {{ betitem.AwayTeam }}
                      </li>
                      <li>
                        {{ $t('Common.Bet') }} :
                        <span
                          v-if="
                            betitem.GameType === GAME_PLAYING_TYPE &&
                            GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                            GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
                          "
                          class="ScoreColor"
                          style="display: inline"
                        >
                          {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                        </span>
                        <span class="betTeamColor">
                          {{ betitem.WagerPosName }}
                        </span>
                        <span
                          v-if="!GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                          class="oddColor"
                        >
                          {{ betitem.CutLine }}
                        </span>
                        @
                        <span class="oddColor">
                          {{ betitem.PayoutOddsStr }}
                        </span>
                      </li>
                      <li>
                        {{ $t('Common.GameTime') }} :
                        <span class="startGameTime">{{
                          betitem?.ScheduleTimeStr.slice(0, 16)
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="rt_betval">
                {{ toCurrency(groupitem.Amount) }}
              </td>
              <td class="rt_betval">
                {{ toCurrency(groupitem.ToWin) }}
              </td>
            </tr>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    GAME_PLAYING_TYPE,
    GAME_HORSE_DOG_CAT,
    GAME_LOTTERY_CAT,
    GAME_INDEX_CAT,
    GAME_FOOTBALL_CATS,
    GAME_HANDICAP_WAGERS,
    GAME_ONE_TEAM_SCORE_WAGERS,
    GAME_CHAMPION_WAGERS,
  } from '@/config';
  import { toCurrency } from '@/config/balance';
  import { getBet97Sets } from '@/config/bet';
  import { apiGetTicket97 } from '@/api/game';

  export default {
    data() {
      return {
        GAME_PLAYING_TYPE,
        GAME_HORSE_DOG_CAT,
        GAME_LOTTERY_CAT,
        GAME_INDEX_CAT,
        GAME_FOOTBALL_CATS,
        GAME_HANDICAP_WAGERS,
        GAME_ONE_TEAM_SCORE_WAGERS,
        GAME_CHAMPION_WAGERS,
        item: null,
      };
    },
    computed: {
      key() {
        const { key } = this.$route.params;
        return key;
      },
      settled() {
        const { type } = this.$route.params;
        return !!Number(type);
      },
      option() {
        const option = this.item?.Option.split('-')[0];
        return option ? Number(option) : 0;
      },
      bets() {
        return this.item?.dataBet.length || 0;
      },
      sets() {
        const { option, bets } = this;
        return getBet97Sets(option, bets);
      },
    },
    watch: {
      key: {
        handler() {
          this.updateData();
        },
        immediate: true,
      },
    },
    methods: {
      toCurrency,
      updateData() {
        const { key } = this;
        if (key) {
          this.$store.commit('showLoading');
          apiGetTicket97({ TicketID: key })
            .then((response) => {
              this.item = response.data.list[0];
            })
            .finally(() => {
              this.$store.commit('hideLoading');
            });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/theme/mixin.scss';
  @import '../../assets/sass/global.scss';
  #app[data-theme^='light'] {
    #HistoryRecord {
    }
  }

  .header1 {
    border-bottom: 1px solid #777;
    border-right: 1px solid #bbb;
  }

  .my-bigger-wrap {
    display: inline-block;
    padding: 4px 16px;
    background-color: #eee;
    border-radius: 8px;
  }

  .my-bigger {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    padding-right: 8px;
  }

  .my-bigger2 {
    font-weight: bold;
    font-size: 16px;
  }

  #app[data-theme^='dark'] {
    #HistoryRecord {
      background: #585858;
    }
  }
  #HistoryRecord {
    & * {
      user-select: text !important;
    }
    height: 100%;
    .whiteColor {
      color: white !important;
    }
    .ScoreColor {
      color: red !important;
      margin-right: 1px;
    }
    .linkColor {
      color: #409eff !important;
    }
    .betTeamColor {
      color: #0077ff;
    }
    .oddColor {
      color: #f00;
    }
    .Remarks {
      color: #f00;
    }
    .DeleteBG {
      background-color: #d5d5d5;
    }
    .textLine > div > ul > li,
    .textLine > ul > li {
      text-decoration: line-through;
    }
    .cancel {
      text-decoration: line-through;
    }
    .betSumTotal {
      color: #ffe900 !important;
    }
    .resultScore {
      color: #0a9c00 !important;
    }
    .startGameTime {
      color: #666;
    }
    .HomeTeamSign {
      color: #ff8800;
    }
    .Record_head {
      width: 100%;
      height: 70px;
      // position: fixed;
      top: 0;
      // background-color: #3fa381;
      @include base_background();
      h3 {
        color: #fff; //#81f0ca;
        font-size: 21px;
        display: inline-block;
        margin-left: 20px;
      }
    }
    .Record_top {
      background-color: #404040;
      color: #bbb;
      width: 100%;
      // position: fixed;
      top: 70px;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      font-size: 13px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      ul {
        display: flex;
        li {
          margin-right: 20px;
          cursor: pointer;
        }
        li.active {
          color: #ffe900;
        }
      }
    }
    .Record_main {
      height: calc(100% - 70px - 40px);
      overflow: auto;
      .Record_mainContainer {
        height: fit-content;
        .footerPageBlock {
          display: flex;
          justify-content: center;
          padding: 15px 0;
          background: #e5e5e5;
        }
        .rt_info {
          text-align: center;
          color: #666;
        }
        .NoData {
          margin: 20px auto;
          text-align: center;
          font-size: 14px;
          width: 100%;
        }
        table {
          width: 100%;
          font-size: 13px;
          border: none;
          background-color: #e5e5e5;
          th {
            background: #ccc;
            font-size: 14px;
            //border-bottom: 1px solid #bbb;
            height: 48px;
          }

          tr:nth-child(odd) {
            background-color: #eee;
          }
          tr:nth-child(even) {
            background-color: #ddd;
          }

          td {
            border-bottom: 1px solid #bbb;
            border-right: 1px solid #bbb;
            padding: 10px;

            & > div:nth-child(odd) {
              background-color: #ddd;
            }
            & > div:nth-child(even) {
              background-color: #eee;
            }

            ul {
              li {
                line-height: 20px;
              }
            }
          }

          td.rt_fs {
            padding: 0;
            .rt_fs_list {
              display: flex;
              align-items: center;
              min-height: 100px;
              border-bottom: 1px solid #bbb;
              > div {
                min-height: 100px;
                width: 50px;
                text-align: center;
                line-height: 100px;
                border-right: 1px solid #bbb;
              }
              > ul {
                padding: 0 20px;
              }
            }
            .rt_fs_list:last-child {
              border: none;
            }
          }
          td.rt_betval {
            text-align: right;
            font-weight: bold;
            font-size: 17px;
          }
          .rt_foot {
            text-align: right;
            // position: fixed;
            bottom: 0;
            width: 100%;
            td {
              background-color: #404040;
              color: #ffffff;
              font-size: 14px;
              flex: 1;
            }
          }
        }
      }
    }
    .weektable {
      tr {
        td {
          text-align: right;
        }
        td:first-child {
          text-align: left;
          padding: 0 20px;
        }
      }
      .week {
        td {
          background: #404040;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        td:nth-child(2) {
          color: #ffe900;
        }
        td:nth-child(3) {
          color: #20b616;
        }
        td:nth-child(4) {
          color: #20b616;
        }
      }
    }
    .collapsetable {
      tr {
        td {
          background: #aaa;
          text-align: right;
          font-weight: bold;
          font-size: 17px;
        }
        td:first-child {
          cursor: pointer;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .sell {
    color: #0dae05;
  }
  .bet97-link {
    color: #409eff;
    cursor: pointer;
  }
</style>
