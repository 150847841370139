// 第三方外接
// - DG 真人: { GVID: 'DG', GTID: 'Live' }
// - SP 彩球: { GVID: 'SP', GTID: 'Lottery' }

import request from './config/request';

// 取得外接登入連結
export function apiGetThirdLogin({ GVID, GTID }) {
  return request({
    url: '/ThirdPartyGame/Login',
    method: 'post',
    auth: true,
    data: { GVID, GTID },
  });
}

// 取得外接餘額
export function apiGetThirdBalance({ GVID, GTID }) {
  return request({
    url: '/ThirdPartyGame/Balance',
    method: 'post',
    auth: true,
    data: { GVID, GTID },
  });
}

// --- DG Live ---
export function apiGetDGLiveLogin() {
  return apiGetThirdLogin({ GVID: 'DG', GTID: 'Live' });
}

export function apiGetDGLiveBalance() {
  return apiGetThirdBalance({ GVID: 'DG', GTID: 'Live' });
}

// --- Super Lottery ---
export function apiGetSPLotteryLogin() {
  return apiGetThirdLogin({ GVID: 'SP', GTID: 'Lottery' });
}

export function apiGetSPLotteryBalance() {
  return apiGetThirdBalance({ GVID: 'SP', GTID: 'Lottery' });
}
