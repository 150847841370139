<template>
  <div id="m-games-page" class="page">
    <MobileHeader
      class="games-header"
      :page="page"
      @goPage="(v) => (page = v)"
      @openService="openService()"
      @openMenuPanel="openMenuPanel()"
    />
    <div class="games-main">
      <template v-if="page === PageEnum.game">
        <mGameCatNav v-if="initOk" class="games-cats" />
        <div class="games-tables">
          <template v-if="GameList.length">
            <div v-if="searchMode" class="searchBar">關鍵字查詢: {{ searchKey }}</div>
            <mGameTable
              v-for="(gameData, index) in GameList"
              :key="index"
              class="games-table"
              :gameData="gameData"
              :hasMoreGame="gameData.Items.hasMoreCount && selectCatID !== GAME_CHAMPION_CAT"
              @openWagerTypePopup="openWagerTypePopup"
              @collapse="updateCollapse"
            />
          </template>
          <template v-else>
            <div v-if="!appLoading" class="games-none">
              {{ $t('Common.NoGame') }}
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="page === PageEnum.gameResult">
        <mGameResultCatNav
          :date="gameResultDate"
          @changeGameResultCatId="(id) => (gameResultCatId = id)"
          @openDatePicker="$refs.gameResult.isShowDatePicker = true"
        />
        <mGameResultTable
          ref="gameResult"
          :selectedCatId="gameResultCatId"
          :gameResultSelectedLeagueIDs="gameResultSelectedLeagueIDs"
          @date="(date) => (gameResultDate = date)"
        />
      </template>
      <template v-else-if="page === PageEnum.announcement">
        <mAnnouncement />
      </template>
      <template v-else-if="page === PageEnum.liveScore">
        <mLiveScorePage />
      </template>
      <template v-else-if="page === PageEnum.rules">
        <mRulesPage />
      </template>
      <template v-else-if="page === PageEnum.strayCounter">
        <mStrayCounter ref="StrayCountDialog" />
      </template>
      <div id="tracing" v-if="tracingList.length">
        <div
          v-for="(item, index) in tracingList"
          :key="index"
          class="item"
          :class="getTracingClass(item)"
          @click="showTracingResult(item)"
        >
          <div v-if="item.running" v-loading="true" />
          {{ item.title }}
        </div>
      </div>
    </div>
    <MobileFooter
      class="games-footer"
      :page="page"
      :hasLeagueFiltered="hasLeagueFiltered"
      @openLeaguesPanel="openLeaguesPanel()"
      @openBetInfoPopup="isShowBetInfo ? (isShowBetInfo = false) : openBetInfoPopup()"
      @openBetRecordView="openBetRecordView()"
    />
    <div v-if="initOk" class="games-popup-layer">
      <MoreGame
        v-if="isShowMoreGame"
        @openBetRecordView="openBetRecordView()"
        @openPersonalPanel="isOpenPersonalPanel = true"
      />
      <mGamesBetInfoSingle
        v-show="isShowBetInfo && betCartList.length === 1"
        ref="singleBet"
        :isShowMoreGame="isShowMoreGame"
        @onHide="isShowBetInfo = false"
      />
      <mGamesBetInfoAll
        ref="betInfoAll"
        v-show="isShowBetInfo && betCartList.length > 1"
        @onCloseBetInfo="isShowBetInfo = false"
      />
      <mBetRecordView
        v-if="isShowBetRecordView"
        @onCloseBetRecordView="isShowBetRecordView = false"
      />
      <mWagerTypePopup
        v-if="isShowWagerTypePopup"
        :isSub="isSubWagerType"
        @closeWagerTypePopup="closeWagerTypePopup"
      />
      <mMenuPanel
        v-if="initOk"
        :isOpen="isOpenMenuPanel"
        @closeMe="isOpenMenuPanel = false"
        @updateGameDetail="onUpdateGameDetail()"
        @showPersonalPanel="openPersonalPanel"
        @goPage="(v) => (page = v)"
      />
      <mLeaguesPanel
        v-if="initOk"
        ref="leaguesPanel"
        :isOpen="isOpenLeaguesPanel"
        :page="page"
        :gameResultCatId="gameResultCatId"
        @closeMe="isOpenLeaguesPanel = false"
        @onLeaguesListChanged="onUpdateGameDetail()"
        @hasLeagueFiltered="(val) => (hasLeagueFiltered = val)"
        @updateGameResultSelectedLeagueIDs="(IDs) => (gameResultSelectedLeagueIDs = IDs)"
      />
      <ServiceChat :isOpen="isOpenServiceChat" @closeMe="isOpenServiceChat = false" />
      <PersonalPanel
        v-if="isOpenPersonalPanel"
        :type="personalPanelType"
        @closeMe="isOpenPersonalPanel = false"
      />
      <mFloatingBetCart v-show="isShowFloatingBall" @openBetInfoPopup="openBetInfoPopup()" />
    </div>
    <ChatSocket v-if="siteOk" />
    <Promotion v-if="initOk" />
    <StandaloneLive v-if="isShowStandalone" />
  </div>
</template>

<script>
  import MobileHeader from './components/MobileHeader.vue';
  import MobileFooter from './components/MobileFooter.vue';
  import mGameCatNav from './components/mGameCatNav.vue';
  import mGameResultCatNav from './components/mGameResultCatNav.vue';
  import mGameTable from './components/GameTable/mGameTable.vue';
  import mGameResultTable from './components/GameResultTable/mGameResultTable.vue';
  import mGamesBetInfoAll from './components/mGamesBetInfoAll.vue';
  import mGamesBetInfoSingle from './components/mGamesBetInfoSingle.vue';
  import mBetRecordView from './components/mBetRecordView.vue';
  import mWagerTypePopup from './components/mWagerTypePopup.vue';
  import mMenuPanel from './components/MenuPanel/mMenuPanel.vue';
  import mLeaguesPanel from './components/mLeaguesPanel.vue';
  import MoreGame from '@/components/MoreGame.vue';
  import ServiceChat from '@/components/ServiceChat.vue';
  import mStrayCounter from './components/mStrayCounter';
  import PersonalPanel from '@/components/PersonalPanel';
  import mAnnouncement from './components/mAnnouncement';
  import mLiveScorePage from './components/mLiveScorePage.vue';
  import mRulesPage from './components/mRulesPage';
  import mFloatingBetCart from './components/mFloatingBetCart.vue';
  import ChatSocket from '@/components/ChatSocket';
  import Promotion from '@/components/Promotion.vue';
  import { mapState, mapGetters } from 'vuex';
  import { PageEnum } from '@/config/mobile/enum';
  import { GAME_FAVORITE_CAT, GAME_CHAMPION_CAT } from '@/config/index.js';
  import StandaloneLive from '@/components/StandaloneLive.vue';

  export default {
    components: {
      MobileHeader,
      MobileFooter,
      mGameCatNav,
      mGameResultCatNav,
      mGameTable,
      mGameResultTable,
      mGamesBetInfoAll,
      mGamesBetInfoSingle,
      mBetRecordView,
      mWagerTypePopup,
      mMenuPanel,
      mLeaguesPanel,
      MoreGame,
      ServiceChat,
      PersonalPanel,
      mLiveScorePage,
      mRulesPage,
      mFloatingBetCart,
      mAnnouncement,
      mStrayCounter,
      ChatSocket,
      Promotion,
      StandaloneLive,
    },
    data() {
      return {
        PageEnum,
        GAME_FAVORITE_CAT,
        GAME_CHAMPION_CAT,
        page: PageEnum.game,
        personalPanelType: 1, // 1: 基本資料, 2: 修改密碼
        hasLeagueFiltered: false,
        serviceUnreadMessages: 0,
        initOk: false,
        isShowBetInfo: false,
        isShowBetRecordView: false,
        isShowWagerTypePopup: false,
        isOpenMenuPanel: false,
        isOpenLeaguesPanel: false,
        isOpenServiceChat: false,
        isOpenPersonalPanel: false,
        gameResultCatId: null,
        gameResultDate: null,
        gameResultSelectedLeagueIDs: [],
        intervalEvent1: null,
        intervalEvent2: null,
        intervalEvent3: null,
      };
    },
    computed: {
      ...mapState(['appLoading']),
      ...mapGetters(['siteOk']),
      ...mapState('MoreGame', ['isShowStandalone']),
      searchMode() {
        return this.$store.state.Game.searchMode;
      },
      searchKey() {
        return this.$store.state.Game.searchKey;
      },
      GameList() {
        return this.$store.getters['Game/GameListFilterBySelectLeague'];
      },
      gameStore() {
        return this.$store.state.Game;
      },
      betStore() {
        return this.$store.state.BetCart;
      },
      settingStore() {
        return this.$store.state.Setting;
      },
      CatList() {
        return this.gameStore.CatList.filter((cat) => cat.CatID !== GAME_FAVORITE_CAT);
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      isFavoriteMode() {
        return this.selectCatID === GAME_FAVORITE_CAT;
      },
      isCallGameDetailAPI() {
        return this.gameStore.isCallGameDetailAPI;
      },
      isShowMoreGame() {
        return this.$store.state.MoreGame.isShowMoreGame;
      },
      betCartList() {
        return this.betStore.betCartList;
      },
      isShowFloatingBall() {
        return this.isShowMoreGame && this.betCartList.length > 0 && !this.isShowBetInfo;
      },
      favorites() {
        return this.settingStore.UserSetting.favorites;
      },
      autoSwitchToStrayMode() {
        return this.settingStore.UserSetting.autoSwitchToStrayMode;
      },
      tracingList() {
        return this.betStore.tracingList;
      },
    },
    watch: {
      isCallGameDetailAPI: {
        handler() {
          this.updateGameDetail({ loading: false, clear: false });
        },
      },
      favorites(val) {
        if (val.length === 0 && this.isFavoriteMode) {
          this.onUpdateGameDetail();
        }
      },
    },
    methods: {
      refesh() {
        const gamesType = this.selectGameType;
        const gamesCat = this.selectCatID;
        const gamesWager = this.selectWagerTypeKey;
        sessionStorage.setItem('gamesType', gamesType);
        sessionStorage.setItem('gamesCat', gamesCat);
        sessionStorage.setItem('gamesWager', gamesWager);
      },
      initSessionMenu() {
        const gamesType = +sessionStorage.getItem('gamesType');
        const gamesCat = +sessionStorage.getItem('gamesCat');
        const gamesWager = +sessionStorage.getItem('gamesWager');
        if (gamesCat === GAME_FAVORITE_CAT) {
          this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
            selectGameType: gamesType,
            selectCatID: GAME_FAVORITE_CAT,
            selectWagerTypeKey: null,
          });
        } else {
          this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
            selectGameType: gamesType,
            selectCatID: gamesCat,
            selectWagerTypeKey: gamesWager,
          });
          this.$store.commit('Game/changeCatReset', gamesCat);
        }
      },
      onSelectType(gameType) {
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        if (gameType === selectGameType) return;

        let catID = null;
        let wagerKey = null;
        if ([GAME_FAVORITE_CAT].includes(selectCatID)) {
          catID = selectCatID;
        } else {
          const menuData = this.gameStore.FullMenuList.find(
            (menuData) => menuData.GameType === gameType
          );
          if (menuData) {
            const { item: catList } = menuData.LeftMenu;
            if (catList?.length) {
              const catItem = catList.find((catItem) => catItem.catid === selectCatID);
              if (catItem) {
                catID = selectCatID;
                const { Items: wagerList } = catItem;
                if (wagerList?.length) {
                  const wagerItem = wagerList.find(
                    (wagerItem) => wagerItem.WagerTypeKey === selectWagerTypeKey
                  );
                  if (wagerItem) {
                    wagerKey = selectWagerTypeKey;
                  } else {
                    wagerKey = wagerList[0].WagerTypeKey;
                  }
                }
              } else {
                const catData0 = catList[0];
                catID = catData0.catid;
                const { Items: wagerList } = catData0;
                if (wagerList?.length) {
                  wagerKey = wagerList[0].WagerTypeKey;
                }
              }
            }
          }
        }
        if (catID === null) {
          catID = 1;
          wagerKey = 1;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType: gameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.updateGameDetail({ loading: true, clear: true }).then(() => {
          this.$store.dispatch('Game/updateGameMenuList');
          this.$store.commit('Game/changeCatReset', selectCatID);
        });
      },
      onSelectCat(catItem) {
        const { catid: catID, Items: wagerList } = catItem;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const wagerKey = wagerList?.length ? wagerList[0].WagerTypeKey : null;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) return;

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.updateGameDetail({ loading: true, clear: true }).then(() => {
          this.$store.commit('Game/changeCatReset', selectCatID);
        });
      },
      onSelectFavoriteCat() {
        if (this.selectCatID === GAME_FAVORITE_CAT) return;
        this.$store.commit('setLoading', true);
        this.$store.dispatch('Game/GetFavoriteGameDetail').finally(() => {
          this.$store.commit('setLoading', false);
        });
      },
      onSelectWager(catItem, wagerKey) {
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const { catid: catID } = catItem;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) return;

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        this.updateGameDetail({ loading: true, clear: true });
      },
      openWagerTypePopup(isSub = false) {
        this.isShowWagerTypePopup = true;
        this.isSubWagerType = isSub;
      },
      closeWagerTypePopup() {
        this.isShowWagerTypePopup = false;
      },
      openService() {
        this.isOpenServiceChat = true;
      },
      openMenuPanel() {
        this.isOpenMenuPanel = true;
        this.$store.dispatch('Game/GetAnnouncement');
      },
      openPersonalPanel(type) {
        this.personalPanelType = type;
        this.isOpenPersonalPanel = true;
      },
      openLeaguesPanel() {
        this.isOpenLeaguesPanel = true;
      },
      openBetInfoPopup() {
        this.isShowBetInfo = true;
        if (this.betCartList.length > 1) {
          if (this.$refs.betInfoAll) {
            if (this.autoSwitchToStrayMode) {
              this.$refs.betInfoAll.tabIndex = 1;
            } else {
              this.$refs.betInfoAll.tabIndex = 0;
            }
          }
        }
      },
      openBetRecordView() {
        this.isShowBetRecordView = true;
      },
      showTracingResult(item) {
        if (item.status <= 1) return;
        this.$store.commit('BetCart/addTracing');
        this.$store.commit('BetCart/showTracingItem', item);
        const data = {
          results: item.data,
          traceCodeKey: item.code,
          isStray: item.type === 99,
        };
        this.$store.dispatch('BetCart/updateCartsResult', data);
        this.$nextTick(() => {
          this.$store.commit('BetCart/removeTracing', item.code);
        });
      },
      getTracingClass(item) {
        switch (item.status) {
          case 0:
            return '';
          case 1:
            return 'ok';
          case 2:
            return 'error';
          default:
            return '';
        }
      },
      async updateGameDetail({ loading = false, clear = false }) {
        if (loading) {
          this.$store.commit('setLoading', true);
        }
        return this.$store.dispatch('Game/updateGameDetail', { clear }).finally(() => {
          if (loading) {
            this.$store.commit('setLoading', false);
          }
        });
      },
      updateGameDetailSmall() {
        return this.$store.dispatch('Game/updateGameDetailSmall');
      },
      onUpdateGameDetail() {
        this.updateGameDetail({ loading: true, clear: true });
      },
      updateCollapse() {
        this.$forceUpdate();
      },
    },
    beforeMount() {
      const { $store } = this;
      if ($store.getters.userOk) {
        $store.commit('setLoading', true);
        $store
          .dispatch('Game/initGame')
          .then(() => {
            this.initOk = true;

            const gamesType = sessionStorage.getItem('gamesType');
            const gamesCat = sessionStorage.getItem('gamesCat');
            if (gamesType !== null && gamesCat !== null) {
              this.initSessionMenu();
            }

            // 賽果預設選取第一個彩種
            if (this.CatList[0]) {
              this.gameResultCatId = this.CatList[0].CatID;
            }

            // 更新 賠率: 每10秒
            this.intervalEvent1 = setInterval(() => {
              this.updateGameDetailSmall();
            }, 10000);

            // 更新 MENU: 每20秒
            this.intervalEvent2 = setInterval(() => {
              this.$store.dispatch('Game/updateGameMenuList', false);
            }, 20000);

            this.intervalEvent3 = setInterval(() => {
              this.$store.dispatch('updateUserBalance');
            }, 60000);
          })
          .catch((error) => {
            // console.log(error);
          })
          .finally(() => {
            $store.commit('hideLoading');
          });
      } else {
        this.$router.replace({ name: 'Login' });
      }
    },
    mounted() {
      // 強制修正 viewport高度
      /* function syncHeight() {
        document.documentElement.style.setProperty(
          '--window-inner-height',
          `${window.innerHeight}px`
        );
      }
      window.addEventListener('resize', syncHeight);
       */

      // 強制禁用 ios 兩指縮放 (舊)
      /* document.documentElement.addEventListener(
        'touchstart',
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        false
      ); */

      // 強制禁用 ios 兩指縮放 (新)
      /* document.documentElement.addEventListener(
        'gesturestart',
        (event) => event.preventDefault(),
        false
      ); */

      this.$watch(
        () => {
          return this.betCartList.length;
        },
        (to, from) => {
          if (from === 0 && to === 1) {
            setTimeout(() => {
              this.isShowBetInfo = true;
            }, 0);
          }
        }
      );
    },
    beforeDestroy() {
      clearInterval(this.intervalEvent1);
      clearInterval(this.intervalEvent2);
      clearInterval(this.intervalEvent3);
    },
  };
</script>

<style lang="scss" scoped>
  #m-games-page {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    background-color: #eee;

    > .games-header {
      flex: 0 0 auto;
    }

    > .games-main {
      flex: 0 1 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      position: relative;
      margin: 0;
      padding: 0;
      border: none;
      overflow: hidden;

      > .games-cats {
        flex: 0 0 auto;
        margin: 0;
        z-index: 1;
      }

      > .games-tables {
        flex: 0 1 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        position: relative;
        margin: 0;
        padding: 0;
        border: none;
        overflow-y: auto;

        > .searchBar {
          flex: 0 0 auto;
          position: sticky;
          height: 32px;
          z-index: 10;
          top: 0;
          left: 0;
          padding: 0 16px;
          background-color: #aaa;
          color: #fff;
          font-size: 1rem;
          line-height: 32px;
        }

        > .games-table {
          flex: 0 0 content;
        }

        > .games-none {
          flex: 1 1 content;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          font-size: 2rem;
        }
      }
    }

    > .games-footer {
      flex: 0 0 auto;
    }

    .games-popup-layer {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      & > div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }
  }

  #tracing {
    position: absolute;
    bottom: 60px;
    right: 0;
    margin: 4px;
    z-index: 100;

    .item {
      display: inline-block;
      background-color: #ccc;
      border-radius: 4px;
      height: 32px;
      line-height: 32px;
      width: 64px;
      text-align: center;
      margin-left: 4px;
      &.error {
        background-color: #c03b35;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  #app[data-theme^='dark'] {
    #m-games-page {
      background-color: #404040;
    }
  }
</style>
