<template>
  <ul class="gameCatNav">
    <li class="item date-picker" @click="$emit('openDatePicker')">
      <img class="btn-date" src="@/assets/img/mobile/btn_date.svg" />
      <span class="text">{{ showGetDate }}</span>
    </li>
    <li
      v-for="({ CatID, Name }, index) in dynamicCatList"
      :key="index"
      :class="['item', { active: CatID === selectedCatId }]"
      @click="selectedCatId = CatID"
    >
      <img :src="getMenuIconByCatID(CatID)" class="menu-icon" />
      <span>{{ Name }}</span>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      date: {
        type: Date,
      },
    },
    data() {
      return {
        cloneNum: 3,
        isOverflow: false,
        isPauseScrollEvent: false,
        selectedCatId: null,
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      CatList() {
        return this.gameStore.CatList.filter((cat) => cat.CatID !== this.$conf.GAME_FAVORITE_CAT);
      },
      CatMapData() {
        return this.gameStore.CatMapData;
      },
      showGetDate() {
        return this.date?.getDate();
      },
      dynamicCatList() {
        let list = this.CatList;
        if (this.isOverflow) {
          const clone = [...list];
          for (let i = 0; i < this.cloneNum; i++) {
            list = [...list, ...clone];
          }
        }
        return list;
      },
      orgChildrenWidth() {
        const children = Array.from(this.$el.children);
        children.length = this.CatList.length;
        return children.reduce((acc, it) => (acc += it.clientWidth), 0);
      },
    },
    watch: {
      selectedCatId(newValue) {
        this.$emit('changeGameResultCatId', newValue);
      },
      'CatList.length': {
        handler() {
          this.$nextTick(() => {
            this.onScroll();
            this.setPositionToCurrentCat();
          });
        },
        immediate: true,
      },
    },
    methods: {
      getMenuIconByCatID(catId) {
        const icon = this.CatMapData[catId].icon;
        return require('@/assets/img/common/menuIcon/' + icon);
      },
      detectIsOverflow() {
        this.isOverflow = this.orgChildrenWidth > this.$el.clientWidth;
      },
      onScroll() {
        this.detectIsOverflow();
        if (this.isOverflow && !this.isPauseScrollEvent) {
          const { clientWidth, scrollWidth, scrollLeft } = this.$el;
          if (scrollLeft <= 0) {
            this.$el.scrollLeft = this.orgChildrenWidth * 2;
          } else if (scrollLeft >= scrollWidth - clientWidth) {
            this.$el.scrollLeft = this.orgChildrenWidth * 2 - clientWidth;
          }
        }
      },
      setPositionToCurrentCat() {
        const target = this.$el.getElementsByClassName('active')[0];
        if (target) {
          this.isPauseScrollEvent = true;
          const datePicker = this.$el.querySelector('.date-picker');
          const datePickerWidth = datePicker ? datePicker.clientWidth : 0;
          this.$el.scrollLeft = target.offsetLeft - datePickerWidth;
          this.isPauseScrollEvent = false;
        }
      },
    },
    created() {
      if (this.CatList.length > 0) {
        this.selectedCatId = this.CatList[0].CatID;
      }
    },
    mounted() {
      window.addEventListener('resize', this.detectIsOverflow);
      this.$el.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.detectIsOverflow);
      this.$el.removeEventListener('scroll', this.onScroll);
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  ul.gameCatNav {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    height: 55px;
    padding: 0;
    width: 100%;
    @include base-background();
    //background-color: #3fa381;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    li.item {
      display: flex;
      flex: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
      padding: 0.4rem 0;
      min-width: 5.35rem;
      text-align: center;
      font-size: 1.2rem;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;

      &.active {
        color: #fff;
        img.menu-icon {
          filter: brightness(1.1);
        }
      }

      img.menu-icon {
        max-width: 20px;
        height: auto;
        padding-top: 4px;
        filter: grayscale(1) brightness(3);
        opacity: 0.7;
      }

      &.date-picker {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: #6da9e5;
        box-shadow: 1px 0px 6px rgb(0 0 0 / 10%);
        justify-content: center;

        img.btn-date {
          max-width: 2.769rem;
          height: auto;
          opacity: 0.7;
        }

        span.text {
          position: absolute;
          top: 45.2%;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
</style>
