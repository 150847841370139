<template>
  <div id="Games">
    <GamesHeader
      @openService="openServiceChat()"
      @openPersonal="openPersonal"
      @openSetting="
        isShowSetting = true;
        $refs.GamesSettingDialog.loadSettings();
      "
    />
    <GamesSetup
      ref="GamesSetup"
      :ok="initOk"
      :isShowGameBet="isShowGameBet"
      @ClickShowGameBet="ShowGameBet"
    />
    <main class="main">
      <GamesNavMenu v-if="initOk" :ok="initOk" ref="GamesNavMenu" @ChangeCat="ChangeCat()" />
      <GamesTableList :ok="initOk" @AddToCart="AddToCartEvent()" />
      <MoreGame
        v-if="isShowMoreGame"
        @AddToCart="AddToCartEvent()"
        @openPersonalPanel="isOpenPersonalPanel = true"
      />
      <GamesBetInfo
        ref="GamesBetInfo"
        v-show="isShowGameBet"
        @openSetting="
          isShowSetting = true;
          $refs.GamesSettingDialog.loadSettings();
        "
        @openStrayCount="isShowStrayCount = true"
      />
    </main>

    <GamesSettingDialog
      ref="GamesSettingDialog"
      v-show="isShowSetting"
      v-if="initOk"
      @closeMe="isShowSetting = false"
    />
    <StrayCountDialog
      ref="StrayCountDialog"
      v-if="isShowStrayCount"
      @closeMe="isShowStrayCount = false"
    />
    <ServiceChat :isOpen="isOpenServiceChat" @closeMe="isOpenServiceChat = false" />
    <PersonalPanel
      v-if="isOpenPersonalPanel"
      :type="personalPanelType"
      @closeMe="isOpenPersonalPanel = false"
    />

    <QuickBetPanel v-if="quickBetData.isShow" />
    <div id="tracing" v-if="tracingList.length">
      <div
        v-for="(item, index) in tracingList"
        :key="index"
        class="item"
        :class="getTracingClass(item)"
        @click="showTracingResult(item)"
      >
        <div v-if="item.running" v-loading="true"></div>
        {{ item.title }}
      </div>
    </div>
    <Promotion v-if="initOk" />
    <LivesDialog />
    <ChatSocket />
    <StandaloneLive v-if="isShowStandalone" />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import { loginRoute } from '@/router';
  import GamesHeader from './components/GamesHeader.vue';
  import GamesSetup from './components/GamesSetup.vue';
  import GamesSettingDialog from './components/GamesSettingDialog.vue';
  import GamesNavMenu from './components/GamesNavMenu.vue';
  import GamesTableList from './components/GamesTable/GamesTableList.vue';
  import MoreGame from '../../components/MoreGame.vue';
  import GamesBetInfo from './components/GamesBetInfo/GamesBetInfo.vue';
  import StrayCountDialog from './components/StrayCountDialog.vue';
  import ServiceChat from '@/components/ServiceChat';
  import PersonalPanel from '@/components/PersonalPanel';
  import ChatSocket from '@/components/ChatSocket';
  import QuickBetPanel from '@/components/QuickBetPanel';
  import Promotion from '@/components/Promotion.vue';
  import LivesDialog from '@/components/LivesDialog.vue';
  import StandaloneLive from '@/components/StandaloneLive.vue';

  export default {
    components: {
      GamesHeader,
      GamesSetup,
      GamesSettingDialog,
      StrayCountDialog,
      GamesNavMenu,
      GamesTableList,
      GamesBetInfo,
      MoreGame,
      ServiceChat,
      PersonalPanel,
      ChatSocket,
      QuickBetPanel,
      Promotion,
      LivesDialog,
      StandaloneLive,
    },
    data() {
      return {
        isShowSetting: false,
        isShowStrayCount: false,
        isOpenServiceChat: false,
        isOpenPersonalPanel: false,
        initOk: false,
      };
    },
    computed: {
      ...mapGetters(['userOk']),
      ...mapState('MoreGame', ['isShowStandalone']),
      isShowGameBet: {
        get() {
          return this.$store.state.Game.isShowGameBet;
        },
        set(value) {
          this.$store.commit('Game/setShowGameBet', value);
        },
      },
      isShowMoreGame() {
        return this.$store.state.MoreGame.isShowMoreGame;
      },
      isAddNewToChart() {
        return this.$store.state.BetCart.isAddNewToChart;
      },
      quickBetData() {
        return this.$store.state.BetCart.quickBetData;
      },
      tracingList() {
        return this.$store.state.BetCart.tracingList;
      },
    },
    watch: {
      isAddNewToChart() {
        this.isShowGameBet = true;
      },
      isOpenServiceChat: {
        handler() {
          this.$store.commit('Game/setIsShowService', this.isOpenServiceChat);
        },
        immediate: true,
      },
    },
    methods: {
      ShowGameBet(val) {
        this.isShowGameBet = val;
      },
      AddToCartEvent() {
        this.$refs.GamesBetInfo.resetGroupIndex();
      },
      ChangeCat() {
        this.$refs.GamesSetup.clearLeagueList();
      },
      openServiceChat() {
        this.isOpenServiceChat = true;
      },
      openPersonal(type) {
        this.personalPanelType = type;
        this.isOpenPersonalPanel = true;
      },
      showTracingResult(item) {
        if (item.status <= 1) return;
        this.$store.commit('BetCart/addTracing');
        this.$store.commit('BetCart/showTracingItem', item);
        const data = {
          results: item.data,
          traceCodeKey: item.code,
          isStray: item.type === 99,
        };
        this.$store.dispatch('BetCart/updateCartsResult', data);
        this.$nextTick(() => {
          this.$store.commit('BetCart/removeTracing', item.code);
        });
      },
      getTracingClass(item) {
        switch (item.status) {
          case 0:
            return '';
          case 1:
            return 'ok';
          case 2:
            return 'error';
          default:
            return '';
        }
      },
    },
    beforeMount() {
      const { $store } = this;
      if ($store.getters.userOk) {
        $store.commit('showLoading');
        $store
          .dispatch('Game/initGame')
          .then(() => {
            this.initOk = true;
          })
          .catch((error) => {
            // console.log(error);
          })
          .finally(() => {
            $store.commit('hideLoading');
          });
      } else {
        this.$router.replace(loginRoute);
      }
    },
  };
</script>

<style lang="scss" scoped>
  #Games {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    position: relative;
    width: 100%;
    min-width: 1400px;
    height: 100%;
  }

  .main {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    position: relative;
    width: 100%;
    height: calc(100% - 108px);
  }

  #tracing {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 4px;

    .item {
      display: inline-block;
      background-color: #ccc;
      border-radius: 4px;
      height: 32px;
      line-height: 32px;
      width: 64px;
      text-align: center;
      margin-left: 4px;

      &.error {
        background-color: #c03b35;
        color: #fff;
        cursor: pointer;
      }
    }
  }
</style>
