<template>
  <section v-if="livesShow" class="dialog lives">
    <div class="dialog_bg" @click="close()" />
    <div class="dialog_panel">
      <div class="panel_header">
        <div class="header_title">直播賽事清單</div>
        <button class="header_close" @click="close()" />
      </div>
      <div class="panel_main">
        <div v-if="lives.length" class="live_list">
          <div v-for="live in lives" :key="live.EvtID" class="live_item" @click="showLive(live)">
            <div class="item_line line1">
              <img class="cat_icon" :src="getCatIcon(live.CatID)" />
              <div class="cat_name">{{ live.CatName }}</div>
              <div class="league_name">{{ live.LeagueNameStr }}</div>
            </div>
            <div class="item_line line2">
              <div v-if="live.HomeTeamStr && live.HomeTeamStr !== '.'" class="team_name home">{{
                live.HomeTeamStr
              }}</div>
              <div
                v-if="
                  live.HomeTeamStr &&
                  live.HomeTeamStr !== '.' &&
                  live.AwayTeamStr &&
                  live.AwayTeamStr !== '.'
                "
                class="team_vs"
              />
              <div v-if="live.AwayTeamStr && live.AwayTeamStr !== '.'" class="team_name away">{{
                live.AwayTeamStr
              }}</div>
            </div>
          </div>
        </div>
        <div v-else class="live_none">當前無直播賽事</div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { getMenuIconByCatID } from '@/utils/SportLib';

  export default {
    data() {
      return {};
    },
    computed: {
      ...mapState('Game', ['lives', 'livesShow']),
    },
    methods: {
      ...mapMutations('Game', ['hideLives']),
      getCatIcon(catId) {
        const icon = getMenuIconByCatID(catId);
        if (icon) {
          return require('@/assets/img/common/menuIcon/' + icon);
        } else {
          return '';
        }
      },
      showLive(live) {
        this.hideLives();
        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType: 2,
          selectCatID: live.CatID,
          selectWagerTypeKey: 1,
        });
        window.OddData.clear();
        this.$emit('ChangeCat');
        this.$store.commit('setLoading', true);
        return this.$store
          .dispatch('Game/updateGameDetail', { clear: true })
          .then(() => {
            const list = this.$store.state.Game.GameList;
            const league = list[0].Items.List.find((league) => league.LeagueID === live.LeagueID);
            if (league) {
              const event = league.Team.find((event) => event.EvtID === live.EvtID);
              this.$store.dispatch('MoreGame/openMoreGameList', {
                teamData: event,
              });
            } else {
              this.$store.dispatch('MoreGame/openMoreGameList', {
                teamData: live,
              });
            }
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      close() {
        this.hideLives();
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/sass/theme/mixin.scss';

  .dialog {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    font-size: 13px;
  }

  .dialog_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .dialog_panel {
    position: relative;
    width: 80%;
    max-width: 480px;
    border: 2px solid #ddd;
    border-radius: 8px;
    color: #fff;
    box-shadow: 4px 8px 24px 0 rgba(0, 0, 0, 0.6);
  }

  .panel_header {
    @include nav-headrtBgcolor();

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 6px 6px 0 0;
    height: 40px;
  }

  .header_title {
    flex-grow: 1;
    flex-shrink: 1;
    color: #fff;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .header_close {
    position: absolute;
    right: 8px;
    top: 7px;
    padding: 0 6px 1px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background-color: transparent;
    color: #ddd;
    cursor: pointer;

    &:after {
      content: 'x';
      font-size: 18px;
    }

    &:hover {
      border-color: #fff;
      color: #fff;
      box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.6);
    }
  }

  .panel_main {
    max-height: 400px;
    overflow-y: auto;
    padding: 8px;
    background-color: #eee;
  }

  .live_list {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
  }

  .live_item {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
    border: 2px solid #ddd;
    border-radius: 8px;
    color: #444;
    cursor: pointer;

    &:hover {
      border: 2px solid #888;
      box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.6);
    }

    .item_line {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding-left: 32px;
      padding-right: 32px;
      font-size: 14px;
      line-height: 32px;

      &.line1 {
        border-bottom: 1px solid #ddd;
      }
    }

    .cat_icon {
      height: 20px;
    }

    .cat_name {
      margin-left: 6px;
    }

    .league_name {
      margin-left: 8px;

      &::before {
        content: '-';
        margin-right: 8px;
      }
    }

    .team_name {
    }

    .team_vs {
      &::before {
        content: 'vs';
        margin-left: 8px;
        margin-right: 8px;
        color: #888;
      }
    }
  }

  .live_none {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 8px;
    border: 2px solid #ddd;
    color: #444;
  }
</style>
