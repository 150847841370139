<template>
  <div id="ServiceChat" :class="isOpen ? 'open' : ''">
    <div class="overlay" @click="close()"></div>
    <div class="panel">
      <div class="header">
        <h5>{{ $t('Login.CustomerService') }}</h5>
        <div class="btn-close" @click="close()"></div>
      </div>

      <div class="body">
        <div class="container">
          <div class="chat-header">
            <img src="@/assets/img/common/test2.jpg" class="avatar" />
            <div class="info">
              <div class="name"> {{ $t('Login.CustomerService') }}</div>
              <div class="status"> {{ $t('Common.Online') }} </div>
            </div>
          </div>

          <div class="chat-history" ref="history">
            <template v-for="(msg, index) in historyMixFirstMsg">
              <div
                v-if="!msg.isFirstMsg"
                class="msg-wrap"
                :class="isSelfMessage(msg.getName) ? 'self' : ''"
                :key="index"
              >
                <div class="msg-row">
                  <div class="avatar">
                    <img src="@/assets/img/common/test2.jpg" />
                  </div>
                  <div class="msg">
                    <template v-if="msg.Content.indexOf('{{FileImage}}') >= 0">
                      <el-image
                        class="msgPhoto"
                        :src="parseImgUrl(msg.Content)"
                        :preview-src-list="[parseImgUrl(msg.Content)]"
                        @load="imgLoadDone()"
                      >
                      </el-image>
                    </template>
                    <template v-else>
                      {{ msg.Content }}
                    </template>
                  </div>
                </div>
                <div class="time">{{ $lib.timeFormatHHmm(msg.CreateTimestr) }}</div>
              </div>

              <!-- 專屬客服-首次登入訊息 -->
              <div v-else class="firstMsgBlock msg-wrap" :key="`firstMsg-` + index">
                <div class="msg-row">
                  <div class="avatar">
                    <img src="@/assets/img/common/test2.jpg" />
                  </div>
                  <div class="msg firstMsgStyle">
                    <div class="title" v-html="msg.mainTitle"></div>
                    <div class="content" v-for="(item, i) in msg.content" :key="i">
                      <p class="subTitle" @click.stop="handleBotMsgClick(item)">
                        <i
                          v-if="!item.isButtun"
                          :class="'el-icon-caret-' + (item.isOpened ? 'bottom' : 'right')"
                        ></i>
                        {{ item.subTitle }}
                      </p>
                      <p
                        class="message"
                        v-show="item.isOpened"
                        v-html="getMessageHtml(item.message)"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="chat-input">
            <div class="btn-file-uploader" @click="$refs.fileInput.click()">
              <i class="el-icon-picture"></i>
              <input type="file" ref="fileInput" accept="image/*" style="display: none" />
            </div>
            <div class="input">
              <input
                type="text"
                v-model="modelInput"
                @keypress.enter="sendMessage"
                :placeholder="$t('Common.InputMessage')"
              />
            </div>
            <div class="btn-submit" @click="sendMessage">
              <i class="el-icon-s-promotion"></i>
            </div>
          </div>
        </div>

        <div class="loadingMask" :class="loading ? 'show' : ''">
          <i class="el-icon-loading"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'ServiceChat',
    props: {
      isOpen: {
        type: Boolean,
      },
      serviceQuestion: {
        type: String,
      },
    },
    data() {
      return {
        modelInput: '',
        historyTimer: null,
        history: [],
        fileInput: null,
        loading: false,
        timeStrOfLastMsgOnInit: '',
        firstMessageObj: null, // 存放 首次登入訊息api資料
        /*  example : */
        // firstMessageObj: {
        //   content: [
        //     {
        //       subTitle: '規則問題',
        //       message:
        //         '前往查看規則<br/><a href="https://queen168.net/score/rules.html">https://queen168.net/score/rules.html</a>',
        //     },
        //     {
        //       subTitle: '比分問題',
        //       message:
        //         '可依照需求點選對應連結查看<br/>賽果<br/><a href="https://queen168.net/#/GameResult">https://queen168.net/#/GameResult</a><br/>比分網彙整<br/><a href="https://queen168.net/score/score_link.html">https://queen168.net/score/score_link.html</a>',
        //     },
        //     {
        //       subTitle: '🎧專屬客服',
        //       message: '',
        //     },
        //   ],
        //   mainTitle:
        //     '歡迎來到【體育客服系統】<br/>若有問題可點選下方常見問題分類，由Super智能小幫手快速為您解答<br/>若仍無法解決，請點選《專屬客服》，將由您的專屬客服為您服務唷 ~ !!',
        // },
      };
    },
    computed: {
      ...mapState(['userUnread']),
      isGuestMode() {
        return this.$router.currentRoute.name === 'Login';
      },
      // 一般對話紀錄 + 首次登入訊息
      historyMixFirstMsg() {
        const orgHistory = this.history.slice();
        if (this.firstMessageObj) {
          orgHistory.splice(0, 0, {
            isFirstMsg: true,
            ...this.firstMessageObj,
          });
        }
        return orgHistory;
      },
    },
    mounted() {
      this.fileInput = this.$refs.fileInput;
      this.fileInput.onchange = (e) => {
        var file = e.target.files[0];
        if (file.size > 3000000) {
          // console.log('file is too large');
          this.$notify.error({ message: '檔案大小需小於 3MB' });
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file); // read as base64
        reader.onload = (readerEvent) => {
          const base64str = readerEvent.target.result;
          const result = base64str.split(',')[1];
          this.sendFile({ base64File: result, name: file.name });
        };
        reader.onerror = (error) => {
          console.error(error);
          this.$notify.error({ message: error });
        };
      };

      // 定時更新紀錄
      this.historyTimer = setInterval(() => {
        if (this.isOpen) {
          this.getHistory(true);
        }
      }, 5000);

      this.modelInput = this.serviceQuestion;
    },
    watch: {
      isOpen(newValue) {
        if (newValue === true) {
          this.getHistory();
          this.modelInput = this.serviceQuestion;
          if (!this.firstMessageObj) {
            this.getFirstMessage();
          }
        }
      },
    },
    methods: {
      getMessageHtml(message) {
        return message;
      },
      close() {
        this.$emit('closeMe');
      },
      scrollToBottom(callback) {
        const view = this.$refs.history;
        view.scrollTo({ top: view.scrollHeight, behavior: 'smooth' });
        callback && callback();
      },
      // 取得對話紀錄
      getHistory(isBehindUpdate = false) {
        if (!this.isOpen) {
          return;
        }
        if (!isBehindUpdate) {
          this.loading = true;
        }
        this.$store
          .dispatch('Game/GetQAHistory', { isGuestMode: this.isGuestMode })
          .then((res) => this.historyHandler(res.data))
          .finally(() => {
            this.loading = false;
          });
      },

      // 標示已讀
      apiCServicePostRead() {
        this.$store.dispatch('Game/SendReadMes', { isGuestMode: this.isGuestMode }).then(() => {
          this.unreadCount = 0;
          this.$store.commit('setUserUnread', 0);
        });
      },
      // 發送訊息
      sendMessage() {
        if (!this.loading && this.modelInput.trim()) {
          const message = this.modelInput;
          this.modelInput = '';
          this.loading = true;
          this.$store
            .dispatch('Game/SendQAMessage', { message, isGuestMode: this.isGuestMode })
            .then((res) => this.historyHandler(res.data))
            .finally(() => (this.loading = false));
        }
      },
      // 發送圖片
      sendFile({ base64File, name }) {
        this.loading = true;
        this.$store
          .dispatch('Game/SendQAFile', { base64File, name, isGuestMode: this.isGuestMode })
          .then(() => this.getHistory())
          .finally(() => (this.loading = false));
      },
      // 取得專屬客服-首次登入訊息
      getFirstMessage() {
        this.loading = true;
        this.$store
          .dispatch('Game/GetQAFirstMes', { isGuestMode: this.isGuestMode })
          .then((res) => this.firstMessageHandler(res.data))
          .finally(() => (this.loading = false));
      },
      parseImgUrl(str) {
        return str.split('{{FileImage}}')[1];
      },
      imgLoadDone() {
        this.scrollToBottom();
      },
      isSelfMessage(name) {
        return name !== 'Service';
      },
      historyHandler(historyArr) {
        const historyLength = this.history.length;
        this.history = historyArr.reverse();
        if (historyLength !== historyArr.length) {
          this.$nextTick(() => {
            this.scrollToBottom(() => {
              this.apiCServicePostRead();
            });
          });
        }
        if (this.timeStrOfLastMsgOnInit === '') {
          if (this.history.length) {
            this.timeStrOfLastMsgOnInit = this.history[this.history.length - 1].CreateTimestr;
          }
        }
      },
      firstMessageHandler(data) {
        if (data) {
          if (data?.content.length) {
            data.content = data.content.map((item) => ({
              ...item,
              isOpened: false,
            }));
          }
          this.firstMessageObj = data;
        }
      },
      handleBotMsgClick(item) {
        item.isOpened = !item.isOpened;
        if (item.isButtun) {
          this.loading = true;
          this.$store
            .dispatch('Game/SendQAMessage', {
              message: this.$t('Login.CustomerService'),
              isGuestMode: this.isGuestMode,
            })
            .then((res) => this.historyHandler(res.data))
            .finally(() => (this.loading = false));
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.historyTimer);
      clearInterval(this.checkUnreadTimer);
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #ServiceChat {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    overflow: hidden;
    display: flex;

    &.open {
      pointer-events: auto;
      .overlay {
        opacity: 1;
      }
      .panel {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: 350ms ease;
    }

    .panel {
      display: flex;
      flex-direction: column;
      width: calc(100% - 50px);
      height: 70%;
      max-height: 800px;
      max-width: 650px;
      overflow: hidden;
      margin: auto;
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
      font-size: 1.23rem;
      transform: translateY(-60px);
      opacity: 0;
      transition: 350ms ease;

      .header {
        flex: 0 1;
        padding: 1.23rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        h5 {
          //color: #38a688;
          @include base-color();
          font-size: 1.538rem;
          margin: 0;
          font-size: 1.538rem;
          font-weight: 500;
          line-height: 1.5;
        }
        .btn-close {
          background-image: url('~@/assets/img/common/close.svg');
          background-repeat: no-repeat;
          background-position: center;
          height: 1.23rem;
          width: 1.23rem;
          margin-left: auto;
          opacity: 0.5;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }

      .body {
        flex: 1;
        padding: 1.23rem;
        overflow: auto;
        position: relative;

        .container {
          display: flex;
          flex-flow: column nowrap;
          height: 100%;
          position: relative;

          .chat-header {
            flex-shrink: 0;
            display: flex;
            padding-bottom: 20px;
            border-radius: 6px;
            border-bottom: 2px solid #f4f7f6;
            box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.4);
            z-index: 1;

            img.avatar {
              width: 40px;
              height: 40px;
              border-radius: 50rem;
              border: none;
              outline: none;
              overflow: hidden;
              background-color: #333;
            }

            .info {
              display: flex;
              flex-flow: column nowrap;
              justify-content: space-evenly;
              padding-left: 1rem;
              .status {
                font-size: 1rem;
                @include base-color();
                //color: #198754;
              }
            }
          }
          .chat-history {
            flex-grow: 1;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 20px;

            .msg-wrap {
              display: flex;
              max-width: 450px;
              margin-bottom: 20px;
              .msg-row {
                display: flex;
                flex-flow: row nowrap;

                .avatar {
                  flex-shrink: 0;
                  //width: 3.23rem;
                  //height: 3.23rem;
                  //border: 1px solid #000;
                  border-radius: 50rem;
                  //background-color: #333;
                  overflow: hidden;
                  margin-right: 20px;
                  img {
                    width: 40px;
                    height: 40px;
                  }
                }
                .msg {
                  color: #444;
                  padding: 0.8rem;
                  line-height: normal;
                  font-size: 16px;
                  min-height: 3rem;
                  border-radius: 7px;
                  position: relative;
                  background: #efefef;
                  align-self: flex-end;
                  display: flex;
                  align-items: center;
                  word-break: break-all;
                  user-select: text;
                  &.firstMsgStyle {
                    flex-direction: column;
                    align-items: flex-start;
                  }

                  &::after {
                    position: absolute;
                    content: ' ';
                    bottom: 0;
                    height: 0;
                    width: 0;
                    border: 0 solid transparent;
                    pointer-events: none;
                    border-right-color: #efefef;
                    border-width: 10px;
                    transform: translateX(-100%);
                    left: 0;
                    top: 10px;
                  }
                }
                .msgPhoto {
                  display: block;
                  max-width: 100%;
                  margin: 0 auto;
                }
              }
              .time {
                margin: 0 8px;
                color: #6c757d;
                font-size: 1.23rem;
                text-align: center;
                display: flex;
                align-items: flex-end;
              }

              &.self {
                align-self: flex-end;
                flex-flow: row-reverse;
                margin-right: 10px;
                .avatar {
                  display: none;
                }
                .msg {
                  background-color: #e8f1f3;
                  &::after {
                    border-left-color: #e8f1f3;
                    border-right-color: transparent;
                    transform: translateX(100%);
                    right: 0;
                    left: unset;
                  }
                }
              }
            }

            // 首登訊息樣式
            .firstMsgBlock {
              .title {
                line-height: 1.2;
                margin-bottom: 10px;
              }
              .content {
                margin: 3px 0;
                .subTitle {
                  margin: 3px 0;
                  font-weight: bold;
                  cursor: pointer;
                }
                .message {
                  margin: 3px 0 8px 20px;
                  line-height: 1.25;
                  &::v-deep a {
                    cursor: pointer;
                    @include base-color();
                    //color: #38a688;
                    text-decoration: underline;
                  }
                }
              }
              &::v-deep * {
                user-select: text;
              }
            }
          }

          .chat-input {
            flex-shrink: 0;
            display: flex;
            align-items: stretch;
            height: 46px;
            border-radius: 0.25rem;
            overflow: hidden;
            & > div {
              height: 100%;
            }
            .btn-file-uploader,
            .btn-submit {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              cursor: pointer;
            }
            .btn-file-uploader {
              background-color: #fff;
              border: 1px solid #ced4da;
              border-radius: 0.25rem 0 0 0.25rem;
              border-width: 1px 0 1px 1px;
              width: 50px;
              &:hover {
                background: #f0f0f0;
              }
              &:active {
                background: #dddddd;
              }
              i {
                font-size: 22px;
                color: #000;
              }
            }
            .btn-submit {
              padding: 0 10px;
              border-radius: 0 0.25rem 0.25rem 0;
              @include base-background();
              // background-color: #38a688;
              width: 50px;
              &:hover {
                filter: brightness(1.1);
              }
              &:active {
                filter: brightness(0.9);
              }
              i {
                font-size: 22px;
                color: #fff;
              }
            }
            .input {
              flex-grow: 1;
              border: 1px solid #ced4da;
              input {
                width: 100%;
                height: 100%;
                border: 0;
                padding: 0 1rem;
              }
            }
          }
        }

        .loadingMask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // border-radius: 0.25rem;
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 10;
          opacity: 0;
          pointer-events: none;
          transition: 200ms ease;

          &.show {
            opacity: 1;
          }

          i {
            color: #fff;
            font-size: 40px;
          }
        }
      }
    }
  }
</style>
