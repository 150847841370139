<template>
  <div id="HistoryRecord">
    <div class="Record_head">
      <h3>
        {{ $t('HistoryRecord.BetHistoryRecord') }}
      </h3>
    </div>
    <div class="Record_top">
      <ul>
        <li
          @click="
            active = 0;
            refreshAPI();
          "
          :class="active === 0 ? 'active' : ''"
        >
          {{ $t('GamesBetInfo.NotCountBet') }}
        </li>
        <li
          @click="
            active = 1;
            refreshAPI();
          "
          :class="active !== 0 ? 'active' : ''"
        >
          {{ $t('HistoryRecord.IsCountBet') }}
        </li>
      </ul>
      <div>
        <el-button v-show="active === 2" size="mini" @click="active = 1" icon="el-icon-arrow-left">
          {{ $t('Common.Return') }}
        </el-button>
        <el-button type="primary" size="mini" @click="refreshAPI">
          {{ $t('Common.Update') }}
        </el-button>
      </div>
    </div>
    <div class="Record_main" ref="Record_main">
      <div class="Record_mainContainer">
        <!-- 未結算注單 -->
        <template v-if="active === 0">
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <th width="200">
                {{ $t('HistoryRecord.BetMessage') }}
              </th>
              <th>
                {{ $t('Common.BetContent') }}
              </th>
              <th width="150">
                {{ $t('HistoryRecord.BetAmount') }}
              </th>
              <th width="150">
                {{ $t('Common.CanWin') }}
              </th>
            </tr>
            <tr v-if="betHistoryData.length === 0">
              <td class="NoDataTD" colspan="5">
                <div class="NoData"> {{ $t('Common.TicketsNoData') }} </div>
              </td>
            </tr>
            <tr
              v-for="(item, index) in betHistoryData"
              :key="index"
              :set="(betitem = item.dataBet[0])"
              class="rt_data"
              :class="{ DeleteBG: ['D', 'V'].includes(item.StatusType) }"
            >
              <td class="rt_info" :class="['D', 'V'].includes(item.StatusType) ? 'textLine' : ''">
                <ul>
                  <li> {{ $t('Common.DownBet') }} : {{ item.BetTimeStr }} </li>
                  <li v-if="item.BetType === 1">
                    {{ $t('Common.Game') }} : {{ betitem?.ScheduleTimeStr.slice(0, 16) }}
                  </li>
                  <li v-else-if="item.BetType === BET_COMBO_PARLAY_TYPE">
                    {{ $t('HistoryRecord.TypeStray3') }}
                    <span class="bet97-link" @click="openBet97(item, 0)">
                      {{ item.Option.split('-')[0] }}{{ $t('Common.string') }}1 x
                      {{ getBet97Sets(Number(item.Option.split('-')[0]), item.dataBet.length) }}
                    </span>
                  </li>
                  <li v-else-if="item.BetType === BET_SUPER8_PARLAY_TYPE">
                    {{ $t('HistoryRecord.TypeStray2') }}
                  </li>
                  <li v-else-if="item.BetType === BET_PARLAYL_TYPE">
                    {{ $t('HistoryRecord.TypeStray') }}
                    {{ item.dataBet.length }}{{ $t('Common.string') }}1
                  </li>
                  <li> {{ $t('Common.BetID') }} : {{ item.TicketID }} </li>
                  <li> {{ $t('Common.Odd') }} : {{ $t('GamesSetup.NotIncludePrincipal') }} </li>
                </ul>
              </td>
              <td v-if="item.BetType === BET_NORMAL_TYPE">
                <ul>
                  <li>
                    {{ betitem.CatName }}
                    <template
                      v-if="betitem.GameType === GAME_PLAYING_TYPE && betitem.WagerGrpID !== 35"
                    >
                      {{ betitem.GameTypeName }}
                    </template>
                    - {{ betitem.LeagueName }}
                    <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                  </li>
                  <li v-if="betitem.CatID === GAME_HORSE_DOG_CAT">
                    {{ betitem.HomeTeam }}
                  </li>
                  <li v-else-if="betitem.CatID === GAME_LOTTERY_CAT">
                    <template v-if="betitem.HomeTeam">
                      {{ betitem.HomeTeam }}
                    </template>
                    <template v-else-if="betitem.AwayTeam">
                      {{ betitem.AwayTeam }}
                    </template>
                  </li>
                  <li v-else-if="betitem.CatID === GAME_INDEX_CAT">
                    {{ betitem.AwayTeam }}
                  </li>
                  <li v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)">
                    <template v-if="betitem.HomeTeam">
                      {{ betitem.HomeTeam }}
                      <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                    </template>
                    <template v-else-if="betitem.AwayTeam">
                      {{ betitem.AwayTeam }}
                    </template>
                  </li>
                  <li v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)">
                    {{ betitem.HomeTeam }}
                  </li>
                  <li v-else>
                    {{ betitem.HomeTeam }}
                    <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                    vs {{ betitem.AwayTeam }}
                  </li>
                  <li>
                    {{ $t('Common.Bet') }} :
                    <span
                      v-if="
                        betitem.GameType === GAME_PLAYING_TYPE &&
                        GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                        GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
                      "
                      class="ScoreColor"
                      style="display: inline"
                    >
                      {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                    </span>
                    <span class="betTeamColor">
                      {{ betitem.WagerPosName }}
                    </span>
                    <span
                      v-if="!GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                      class="oddColor"
                    >
                      {{ betitem.CutLine }}
                    </span>
                    @
                    <span class="oddColor">
                      {{ betitem.PayoutOddsStr }}
                    </span>
                  </li>
                </ul>
              </td>
              <td v-else class="rt_fs">
                <div v-for="(betitem, index) in item.dataBet" :key="index" class="rt_fs_list">
                  <div> {{ index + 1 }} </div>
                  <ul>
                    <li>
                      {{ betitem.CatName }} - {{ betitem.LeagueName }}
                      <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                    </li>
                    <li v-if="betitem.CatID === GAME_HORSE_DOG_CAT">
                      {{ betitem.HomeTeam }}
                    </li>
                    <li v-else-if="betitem.CatID === GAME_LOTTERY_CAT">
                      <template v-if="betitem.HomeTeam">
                        {{ betitem.HomeTeam }}
                      </template>
                      <template v-else-if="betitem.AwayTeam">
                        {{ betitem.AwayTeam }}
                      </template>
                    </li>
                    <li v-else-if="betitem.CatID === GAME_INDEX_CAT">
                      {{ betitem.AwayTeam }}
                    </li>
                    <li v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)">
                      <template v-if="betitem.HomeTeam">
                        {{ betitem.HomeTeam }}
                        <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                      </template>
                      <template v-else-if="betitem.AwayTeam">
                        {{ betitem.AwayTeam }}
                      </template>
                    </li>
                    <li v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)">
                      {{ betitem.HomeTeam }}
                    </li>
                    <li v-else>
                      {{ betitem.HomeTeam }}
                      <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                      vs {{ betitem.AwayTeam }}
                    </li>
                    <li>
                      {{ $t('Common.Bet') }} :
                      <span
                        v-if="
                          betitem.GameType === GAME_PLAYING_TYPE &&
                          GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                          GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
                        "
                        class="ScoreColor"
                        style="display: inline"
                      >
                        {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                      </span>
                      <span class="betTeamColor">
                        {{ betitem.WagerPosName }}
                      </span>
                      <span
                        v-if="!GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                        class="oddColor"
                      >
                        {{ betitem.CutLine }}
                      </span>
                      <template v-if="item.BetType != BET_SUPER8_PARLAY_TYPE">
                        @
                        <span class="oddColor">
                          {{ betitem.PayoutOddsStr }}
                        </span>
                      </template>
                    </li>
                    <li>
                      {{ $t('Common.GameTime') }} :
                      <span class="startGameTime">{{ betitem.ScheduleTimeStr.slice(0, 16) }}</span>
                    </li>
                  </ul>
                </div>
              </td>
              <td class="rt_betval">
                {{ toCurrency(item.Amount) }}
              </td>
              <td class="rt_betval">
                {{ toCurrency(item.ToWin) }}
              </td>
            </tr>
            <tr class="rt_foot">
              <td colspan="2">
                {{ $t('Common.Total') }}
              </td>
              <td class="betSumTotal">
                {{ toCurrency(totalAmount) }}
              </td>
              <td>
                {{ toCurrency(totalWinAmount) }}
              </td>
            </tr>
          </table>
          <div class="footerPageBlock">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="pageData.CurrentPage"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="pageData.PageSize"
              :total="pageData.Count"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </template>
        <!-- 已結算注單 -->
        <table v-show="active === 1" class="weektable" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <th>
              {{ $t('GameDate') }}
            </th>
            <th> {{ $t('HistoryRecord.BetAmount') }} / {{ $t('HistoryRecord.BetAmount2') }} </th>
            <th>
              {{ $t('Common.Result') }}
            </th>
          </tr>
          <tr
            v-for="(weekitem, index) in weekData"
            :key="index"
            :class="
              weekitem.weekLang.indexOf($t('Common.Total')) > 0 || weekitem.week === 128
                ? 'week'
                : ''
            "
          >
            <td> {{ weekitem.accdate.substr(5) }} {{ weekitem.weekLang }} </td>
            <td> {{ toCurrency(weekitem.amount) }} / {{ toCurrency(weekitem.effectAmount) }} </td>
            <td
              v-if="weekitem.weekLang.indexOf($t('Common.Total')) > 0"
              :class="sumClassColorJudge(weekitem.ResultAmount)"
            >
              {{ toCurrency(Number(weekitem.ResultAmount).toFixed(2)) }}
            </td>
            <td v-else>
              <el-link
                type="primary"
                :class="sumBlueClassColorJudge(weekitem.ResultAmount)"
                style="text-decoration: underline"
                @click="goThisWeek(weekitem.accdate)"
              >
                {{ toCurrency(Number(weekitem.ResultAmount).toFixed(2)) }}
              </el-link>
            </td>
          </tr>
        </table>
        <!-- 已結算注單詳細 -->
        <div v-show="active === 2">
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <th class="header1" width="185">
                {{ $t('HistoryRecord.BetMessage') }}
              </th>
              <th class="header1" width="400">
                {{ $t('Common.BetContent') }}
              </th>
              <th class="header1" width="100">
                {{ $t('HistoryRecord.BetAmount') }} / {{ $t('HistoryRecord.BetAmount2') }}
              </th>
              <th class="header1" width="100">
                {{ $t('Common.CanWin') }}
              </th>
              <th class="header1" width="100">
                {{ $t('Common.Result') }}
              </th>
            </tr>
          </table>
          <div v-for="(groupitem, index) in gettodayDetails" :key="index">
            <table class="collapsetable" border="0" cellspacing="0" cellpadding="0">
              <tr style="cursor: pointer" @click="upactive(groupitem.CatName)">
                <td width="585">
                  <span class="my-bigger-wrap">
                    <i
                      class="my-bigger"
                      :class="groupitem.active ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                    />
                    <span class="my-bigger2">{{ groupitem.CatName }}</span>
                  </span>
                </td>
                <td width="100">
                  {{ toCurrency(groupitem.Amounts) }} / {{ toCurrency(groupitem.EffectiveAmount) }}
                </td>
                <td width="100">
                  {{ toCurrency(groupitem.canwins) }}
                </td>
                <td
                  width="100"
                  :class="parseInt(groupitem.ResultAmounts) < 0 ? 'ScoreColor' : 'resultScore'"
                >
                  {{ toCurrency(groupitem.ResultAmounts) }}
                </td>
              </tr>
            </table>
            <table
              v-show="groupitem.active"
              v-for="(item, index2) in groupitem.data"
              :key="index2"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <tr class="rt_data" :class="{ DeleteBG: ['D', 'V'].includes(item.StatusType) }">
                <td width="185" class="rt_info">
                  <ul>
                    <li> {{ $t('Common.DownBet') }} : {{ item.BetTimeStr.slice(0, 19) }} </li>
                    <li v-if="item.BetType === 1">
                      {{ $t('Common.Game') }} :
                      {{ item.dataBet[0]?.ScheduleTimeStr.slice(0, 16) }}
                    </li>
                    <li v-else-if="item.BetType === BET_COMBO_PARLAY_TYPE">
                      {{ $t('HistoryRecord.TypeStray3') }}
                      <span class="bet97-link" @click="openBet97(item, 1)">
                        {{ item.Option.split('-')[0] }}{{ $t('Common.string') }}1 x
                        {{ getBet97Sets(Number(item.Option.split('-')[0]), item.dataBet.length) }}
                      </span>
                    </li>
                    <li v-else-if="item.BetType === BET_SUPER8_PARLAY_TYPE">
                      {{ $t('HistoryRecord.TypeStray2') }}
                    </li>
                    <li v-else-if="item.BetType === BET_PARLAYL_TYPE">
                      {{ $t('HistoryRecord.TypeStray') }}
                      {{ item.dataBet.length }}{{ $t('Common.string') }}1
                    </li>
                    <li> {{ $t('Common.BetID') }} : {{ item.TicketID }} </li>
                    <li> {{ $t('Common.Odd') }} : {{ $t('GamesSetup.NotIncludePrincipal') }} </li>
                  </ul>
                </td>
                <td
                  v-if="item.BetType === 1"
                  :class="['D', 'V'].includes(item.StatusType) ? 'textLine' : ''"
                  width="400"
                  :set="(betitem = item.dataBet[0])"
                >
                  <ul>
                    <li>
                      {{ betitem.CatName }}
                      <template
                        v-if="betitem.GameType === GAME_PLAYING_TYPE && betitem.WagerGrpID !== 35"
                      >
                        {{ betitem.GameTypeName }}
                      </template>
                      - {{ betitem.LeagueName }}
                      <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                    </li>
                    <li v-if="betitem.CatID === GAME_HORSE_DOG_CAT">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.HomeTeam }}
                    </li>
                    <li v-else-if="betitem.CatID === GAME_LOTTERY_CAT">
                      <template v-if="betitem.HomeTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                      </template>
                      <template v-else-if="betitem.AwayTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </template>
                    </li>
                    <li v-else-if="betitem.CatID === GAME_INDEX_CAT">
                      <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                      {{ betitem.AwayTeam }}
                    </li>
                    <li v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)">
                      <template v-if="betitem.HomeTeam">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                        <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                      </template>
                      <template v-else-if="betitem.AwayTeam">
                        <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </template>
                    </li>
                    <li v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)">
                      {{ betitem.HomeTeam }}
                    </li>
                    <li v-else>
                      <span v-if="betitem.EvtType !== 19" class="ScoreColor"
                        >[{{ betitem.HomeScore }}]</span
                      >
                      {{ betitem.HomeTeam }}
                      <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                      vs
                      <span v-if="betitem.EvtType !== 19" class="ScoreColor"
                        >[{{ betitem.AwayScore }}]</span
                      >
                      {{ betitem.AwayTeam }}
                      <span v-if="betitem.EvtType === 19" class="ScoreColor"
                        >[總和{{ betitem.HomeScore }}]</span
                      >
                    </li>
                    <li>
                      {{ $t('Common.Bet') }} :
                      <span
                        v-if="
                          betitem.GameType === GAME_PLAYING_TYPE &&
                          GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                          GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
                        "
                        class="ScoreColor"
                        style="display: inline"
                      >
                        {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                      </span>
                      <span class="betTeamColor">
                        {{ betitem.WagerPosName }}
                      </span>
                      <span
                        v-if="!GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                        class="oddColor"
                      >
                        {{ betitem.CutLine }}
                      </span>
                      @
                      <span class="oddColor">
                        {{ betitem.PayoutOddsStr }}
                      </span>
                    </li>
                    <li v-if="betitem.ItemStatusContent">
                      <span style="color: red">({{ betitem.ItemStatusContent }})</span>
                    </li>
                    <div v-if="item.Remarks" class="Remarks">
                      {{ item.Remarks }}
                    </div>
                    <div v-if="item.SellFlag" class="sell">
                      {{ item.TicketTypeName }}
                    </div>
                  </ul>
                </td>
                <td
                  v-else
                  class="rt_fs"
                  :class="['D', 'V'].includes(item.StatusType) ? 'textLine' : ''"
                  width="400"
                >
                  <div v-for="(betitem, index3) in item.dataBet" :key="index3" class="rt_fs_list">
                    <div>
                      {{ index3 + 1 }}
                    </div>
                    <ul>
                      <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        {{ betitem.CatName }} - {{ betitem.LeagueName }}
                        <template v-if="betitem.ItemName"> - {{ betitem.ItemName }} </template>
                      </li>
                      <li
                        v-if="betitem.CatID === GAME_HORSE_DOG_CAT"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                      </li>
                      <li
                        v-else-if="betitem.CatID === GAME_LOTTERY_CAT"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <template v-if="betitem.HomeTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.HomeTeam }}
                        </template>
                        <template v-else-if="betitem.AwayTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.AwayTeam }}
                        </template>
                      </li>
                      <li
                        v-else-if="betitem.CatID === GAME_INDEX_CAT"
                        :class="{ cancel: betitem.ItemStatus !== 'Y' }"
                      >
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </li>
                      <li v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)">
                        <template v-if="betitem.HomeTeam">
                          <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                          {{ betitem.HomeTeam }}
                          <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                        </template>
                        <template v-else-if="betitem.AwayTeam">
                          <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                          {{ betitem.AwayTeam }}
                        </template>
                      </li>
                      <li v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)">
                        {{ betitem.HomeTeam }}
                      </li>
                      <li v-else :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        <span class="ScoreColor">[{{ betitem.HomeScore }}]</span>
                        {{ betitem.HomeTeam }}
                        <span class="HomeTeamSign">({{ $t('Common.Home') }})</span>
                        vs
                        <span class="ScoreColor">[{{ betitem.AwayScore }}]</span>
                        {{ betitem.AwayTeam }}
                      </li>
                      <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        {{ $t('Common.Bet') }} :
                        <span
                          v-if="
                            betitem.GameType === GAME_PLAYING_TYPE &&
                            GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                            GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
                          "
                          class="ScoreColor"
                          style="display: inline"
                        >
                          {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
                        </span>
                        <span class="betTeamColor">
                          {{ betitem.WagerPosName }}
                        </span>
                        <span
                          v-if="!GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
                          class="oddColor"
                        >
                          {{ betitem.CutLine }}
                        </span>
                        <template v-if="item.BetType !== BET_SUPER8_PARLAY_TYPE">
                          @
                          <span class="oddColor"> {{ betitem.PayoutOddsStr }} </span>
                        </template>
                      </li>
                      <li :class="{ cancel: betitem.ItemStatus !== 'Y' }">
                        {{ $t('Common.GameTime') }} :
                        <span class="startGameTime">{{
                          betitem.ScheduleTimeStr.slice(0, 16)
                        }}</span>
                      </li>
                      <li v-if="betitem.ItemStatusContent">
                        <span style="color: red">({{ betitem.ItemStatusContent }})</span>
                      </li>
                      <li v-if="betitem.ItemStatus !== 'Y'">
                        <span class="ScoreColor">{{ $t('Common.GameCancel') }}</span>
                      </li>
                    </ul>
                  </div>
                  <div v-if="item.Remarks" class="Remarks" style="padding: 5px">
                    {{ item.Remarks }}
                  </div>
                </td>
                <td width="100" class="rt_betval">
                  {{ toCurrency(item.Amount) }} / {{ toCurrency(item.EffectiveAmount) }}
                </td>
                <td width="100" class="rt_betval">
                  {{ toCurrency(item.ToWin) }}
                </td>
                <td
                  class="rt_betval"
                  :class="parseInt(item.ResultAmount) < 0 ? 'ScoreColor' : 'resultScore'"
                  width="100"
                >
                  {{ toCurrency(item.ResultAmount) }}
                </td>
              </tr>
            </table>
          </div>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr class="rt_foot">
              <td width="585">
                {{ $t('Common.Total') }}
              </td>
              <td width="100" class="betSumTotal">
                {{ toCurrency(gettotal.Amounts) }}
              </td>
              <td width="100">
                {{ toCurrency(gettotal.canwins) }}
              </td>
              <td width="100" :class="this.sumClassColorJudge(gettotal.ResultAmounts)">
                {{ toCurrency(gettotal.ResultAmounts) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    GAME_PLAYING_TYPE,
    GAME_HORSE_DOG_CAT,
    GAME_LOTTERY_CAT,
    GAME_INDEX_CAT,
    GAME_FOOTBALL_CATS,
    GAME_HANDICAP_WAGERS,
    GAME_ONE_TEAM_SCORE_WAGERS,
    GAME_CHAMPION_WAGERS,
    BET_NORMAL_TYPE,
    BET_COMBO_PARLAY_TYPE,
    BET_SUPER8_PARLAY_TYPE,
    BET_PARLAYL_TYPE,
  } from '@/config';
  import { toCurrency } from '@/config/balance';
  import { getBet97Sets } from '@/config/bet';

  export default {
    data() {
      return {
        GAME_PLAYING_TYPE,
        GAME_HORSE_DOG_CAT,
        GAME_LOTTERY_CAT,
        GAME_INDEX_CAT,
        GAME_FOOTBALL_CATS,
        GAME_HANDICAP_WAGERS,
        GAME_ONE_TEAM_SCORE_WAGERS,
        GAME_CHAMPION_WAGERS,
        BET_NORMAL_TYPE,
        BET_COMBO_PARLAY_TYPE,
        BET_SUPER8_PARLAY_TYPE,
        BET_PARLAYL_TYPE,
        active: 0,
        betHistoryData: [],
        weekData: [],
        todayDetails: [],
        pageData: {
          CurrentPage: 1,
          TotalPage: 1,
          PageSize: 100,
          Count: 0,
        },
        lastGoWeekTime: '',
      };
    },
    computed: {
      totalAmount() {
        let total = 0;
        this.betHistoryData.forEach((item) => {
          total += item.Amount;
        });
        return total;
      },
      totalWinAmount() {
        let total = 0;
        this.betHistoryData.forEach((item) => {
          total += item.ToWin;
        });
        return total;
      },
      getBetHistoryData() {
        this.betHistoryData.forEach((item, i) => {
          if (item.BetType === 1) {
            if (item.dataBet[0].WagerPosName === this.$t('Common.HomeTeam')) {
              this.betHistoryData[i].dataBet[0].betname = item.dataBet[0].HomeTeam;
            } else if (item.dataBet[0].WagerPosName === this.$t('Common.AwayTeam')) {
              this.betHistoryData[i].dataBet[0].betname = item.dataBet[0].AwayTeam;
            } else {
              this.betHistoryData[i].dataBet[0].betname = item.dataBet[0].WagerPosName;
            }
          } else {
            item.dataBet.forEach((betList, y) => {
              if (betList.WagerPosName === this.$t('Common.HomeTeam')) {
                this.betHistoryData[i].dataBet[y].betname = betList.HomeTeam;
              } else if (betList.WagerPosName === this.$t('Common.AwayTeam')) {
                this.betHistoryData[i].dataBet[y].betname = betList.AwayTeam;
              } else {
                this.betHistoryData[i].dataBet[y].betname = item.dataBet[y].WagerPosName;
              }
            });
          }
        });
        return this.betHistoryData;
      },
      gettodayDetails() {
        return this.todayDetails;
      },
      gettotal() {
        const total = { Amounts: 0, ResultAmounts: 0, canwins: 0 };

        this.gettodayDetails.forEach((item) => {
          total.Amounts = this.$lib.trunc(total.Amounts + item.Amounts);
          total.ResultAmounts = this.$lib.trunc(total.ResultAmounts + item.ResultAmounts);
          total.canwins = this.$lib.trunc(total.canwins + item.canwins);
        });
        return total;
      },
    },
    methods: {
      toCurrency,
      getBet97Sets,
      openBet97(item, type) {
        const { href: url } = this.$router.resolve({
          path: `/HistoryRecord97/${item.TicketID}/${type}`,
        });
        if (url) window.open(url, 'bet97');
      },
      isShowScore(catID, GameType) {
        return (catID === 1 || catID === 31) && GameType === 2;
      },
      refreshAPI() {
        if (this.active === 0) {
          this.updateTickets();
        } else if (this.active === 1) {
          this.updateTicketStats();
        } else if (this.active === 2) {
          this.getBetHistoryDetail(
            this.lastGoWeekTime + ' 00:00:00',
            this.lastGoWeekTime + ' 23:59:59'
          );
        }
      },
      sumClassColorJudge(num) {
        if (parseInt(num) < 0) {
          return 'ScoreColor';
        } else if (parseInt(num) === 0) {
          return 'whiteColor';
        } else {
          return 'resultScore';
        }
      },
      sumBlueClassColorJudge(num) {
        if (parseInt(num) < 0) {
          return 'ScoreColor';
        } else if (parseInt(num) === 0) {
          return 'linkColor';
        } else {
          return 'resultScore';
        }
      },
      handleSizeChange(val) {
        this.pageData.CurrentPage = 1;
        this.pageData.PageSize = val;
        this.updateTickets();
      },
      handleCurrentChange(val) {
        this.pageData.CurrentPage = val;
        this.updateTickets();
      },
      upactive(CatName) {
        this.gettodayDetails.forEach((item) => {
          if (item.CatName === CatName) {
            item.active = !item.active;
          }
        });
        this.$forceUpdate();
      },
      goThisWeek(time) {
        this.active = 2;
        this.lastGoWeekTime = time;
        this.getBetHistoryDetail(time + ' 00:00:00', time + ' 23:59:59');
      },
      updateTickets() {
        this.$store.commit('setLoading', true);
        let postData = {};
        postData = {
          page: this.pageData.CurrentPage,
          pagesize: this.pageData.PageSize,
        };
        this.$store
          .dispatch('History/updateTickets', {
            isset: false,
            ...postData,
          })
          .then((res) => {
            this.betHistoryData = res.data.list;
            this.pageData.CurrentPage = res.data.CurrentPage;
            this.pageData.TotalPage = res.data.TotalPage;
            this.pageData.PageSize = res.data.PageSize;
            this.pageData.Count = res.data.Count;
            this.$nextTick(() => {
              this.$refs.Record_main.scrollTop = 0;
            });
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      updateTicketStats() {
        this.$store
          .dispatch('History/updateTicketStats', {
            isset: true,
          })
          .then((res) => {
            this.weekData = res.data;
            this.$forceUpdate();
          });
      },
      getBetHistoryDetail(startTime, endTime) {
        const postData = {
          starttime: startTime,
          endtime: endTime,
          isset: true,
          normal: false,
        };
        this.todayDetails.length = 0;
        this.todayDetails = [];

        this.$store.dispatch('History/updateTicketStats', postData).then((res) => {
          this.todayDetails = res.data
            .map((it) => {
              return {
                Amounts: it.amount,
                EffectiveAmount: it.effectAmount,
                ResultAmounts: it.ResultAmount,
                active: false,
                canwins: it.ToWin,
                CatName: it.CatName,
                data: it.Items.map((itemData) => {
                  itemData.dataBet = itemData.dataBet.map((dataBet) => {
                    if (dataBet.WagerPosName === this.$t('Common.HomeTeam')) {
                      dataBet.betname = dataBet.HomeTeam;
                    } else if (dataBet.WagerPosName === this.$t('Common.AwayTeam')) {
                      dataBet.betname = dataBet.AwayTeam;
                    } else {
                      dataBet.betname = dataBet.WagerPosName;
                    }
                    return dataBet;
                  });
                  return itemData;
                }),
              };
            })
            .filter((it, index) => index !== res.data.length - 1);
        });
      },
    },
    async created() {
      this.updateTickets(false);
      this.updateTicketStats();
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/theme/mixin.scss';
  @import '../../assets/sass/global.scss';
  #app[data-theme^='light'] {
    #HistoryRecord {
    }
  }

  .header1 {
    border-bottom: 1px solid #777;
    border-right: 1px solid #bbb;
  }

  .my-bigger-wrap {
    display: inline-block;
    padding: 4px 16px;
    background-color: #eee;
    border-radius: 8px;
  }

  .my-bigger {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    padding-right: 8px;
  }

  .my-bigger2 {
    font-weight: bold;
    font-size: 16px;
  }

  #app[data-theme^='dark'] {
    #HistoryRecord {
      background: #585858;
    }
  }
  #HistoryRecord {
    & * {
      user-select: text !important;
    }
    height: 100%;
    .whiteColor {
      color: white !important;
    }
    .ScoreColor {
      color: red !important;
      margin-right: 1px;
    }
    .linkColor {
      color: #409eff !important;
    }
    .betTeamColor {
      color: #0077ff;
    }
    .oddColor {
      color: #f00;
    }
    .Remarks {
      color: #f00;
    }
    .DeleteBG {
      background-color: #d5d5d5;
    }
    .textLine > div > ul > li,
    .textLine > ul > li {
      text-decoration: line-through;
    }
    .cancel {
      text-decoration: line-through;
    }
    .betSumTotal {
      color: #ffe900 !important;
    }
    .resultScore {
      color: #0a9c00 !important;
    }
    .startGameTime {
      color: #666;
    }
    .HomeTeamSign {
      color: #ff8800;
    }
    .Record_head {
      width: 100%;
      height: 70px;
      // position: fixed;
      top: 0;
      // background-color: #3fa381;
      @include base_background();
      h3 {
        color: #fff; //#81f0ca;
        font-size: 21px;
        display: inline-block;
        margin-left: 20px;
      }
    }
    .Record_top {
      background-color: #404040;
      color: #bbb;
      width: 100%;
      // position: fixed;
      top: 70px;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      font-size: 13px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      ul {
        display: flex;
        li {
          margin-right: 20px;
          cursor: pointer;
        }
        li.active {
          color: #ffe900;
        }
      }
    }
    .Record_main {
      height: calc(100% - 70px - 40px);
      overflow: auto;
      .Record_mainContainer {
        height: fit-content;
        .footerPageBlock {
          display: flex;
          justify-content: center;
          padding: 15px 0;
          background: #e5e5e5;
        }
        .rt_info {
          color: #666;
        }
        .NoData {
          margin: 20px auto;
          text-align: center;
          font-size: 14px;
          width: 100%;
        }
        table {
          width: 100%;
          font-size: 13px;
          border: none;
          background-color: #e5e5e5;
          th {
            background: #ccc;
            font-size: 14px;
            //border-bottom: 1px solid #bbb;
            height: 48px;
          }

          tr:nth-child(odd) {
            background-color: #eee;
          }
          tr:nth-child(even) {
            background-color: #ddd;
          }

          td {
            border-bottom: 1px solid #bbb;
            border-right: 1px solid #bbb;
            padding: 10px;

            & > div:nth-child(odd) {
              background-color: #ddd;
            }
            & > div:nth-child(even) {
              background-color: #eee;
            }

            ul {
              li {
                line-height: 20px;
              }
            }
          }

          td.rt_fs {
            padding: 0;
            .rt_fs_list {
              display: flex;
              align-items: center;
              min-height: 100px;
              border-bottom: 1px solid #bbb;
              > div {
                min-height: 100px;
                width: 50px;
                text-align: center;
                line-height: 100px;
                border-right: 1px solid #bbb;
              }
              > ul {
                padding: 0 20px;
              }
            }
            .rt_fs_list:last-child {
              border: none;
            }
          }
          td.rt_betval {
            text-align: right;
            font-weight: bold;
            font-size: 17px;
          }
          .rt_foot {
            text-align: right;
            // position: fixed;
            bottom: 0;
            width: 100%;
            td {
              background-color: #404040;
              color: #ffffff;
              font-size: 14px;
              flex: 1;
            }
          }
        }
      }
    }
    .weektable {
      tr {
        td {
          text-align: right;
        }
        td:first-child {
          text-align: left;
          padding: 0 20px;
        }
      }
      .week {
        td {
          background: #404040;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        td:nth-child(2) {
          color: #ffe900;
        }
        td:nth-child(3) {
          color: #20b616;
        }
        td:nth-child(4) {
          color: #20b616;
        }
      }
    }
    .collapsetable {
      tr {
        td {
          background: #aaa;
          text-align: right;
          font-weight: bold;
          font-size: 17px;
        }
        td:first-child {
          cursor: pointer;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .sell {
    color: #0dae05;
  }
  .bet97-link {
    color: #409eff;
    cursor: pointer;
  }
</style>
