<template>
  <div class="mGameBetting" :class="isExpanded ? '' : 'closed'">
    <table>
      <thead @click="$emit('toggleCollapse')">
        <tr>
          <th v-for="rowIndex in activeColumns" :key="rowIndex">
            {{ BestHeadWithFilterLimit[rowIndex].showName }}</th
          >
        </tr>
      </thead>

      <tbody v-show="isExpanded">
        <template v-for="(teamData, teamIndex) in source.Team">
          <template v-if="teamData.EvtStatus === 1">
            <template v-if="isFix">
              <tr :key="`${teamIndex}-fix2`">
                <td
                  v-for="(wagerData, wagerIndex) in getWagers(teamData)"
                  :key="wagerIndex"
                  class="wager-container"
                >
                  <ul
                    class="wager-group"
                    :set="
                      ((sportData = $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, 0)),
                      (GameID = wagerRoIndexToGameID(wagerData, 0)))
                    "
                  >
                    <template v-if="wagerData.isNoData">
                      <li class="wager-cell fix2" />
                    </template>
                    <template v-else>
                      <template v-if="wagerData.isNoData">
                        <li class="wager-cell fix2" />
                      </template>
                      <template v-else>
                        <li
                          class="wager-cell fix2"
                          :class="
                            WagerRowIsSelectInCartCSS(
                              GameID,
                              sportData.drewPlayOdd,
                              $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, 0)
                                .drewWagerPos
                            )
                          "
                          @click="
                            goBet(
                              $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, 0)
                                .drewPlayOdd,
                              teamData,
                              wagerData,
                              0,
                              3
                            )
                          "
                        >
                          <Odd :OddValue="sportData.drewPlayOdd" :UniqueID="`${GameID}`" />
                        </li>
                      </template>
                    </template>
                  </ul>
                </td>
              </tr>
            </template>
            <!-- 冠軍 -->
            <template v-if="selectCatID === GAME_CHAMPION_CAT">
              <tr
                v-for="(teamDataRowNum, rowIndex) in teamData.Row"
                v-if="rowIndex === 0"
                :key="`${teamIndex}-${rowIndex}`"
              >
                <td
                  class="wager-container"
                  v-for="(wagerData, wagerIndex) in getWagers(teamData)"
                  :key="wagerIndex"
                >
                  <ul
                    class="wager-group"
                    :set="
                      ((sportData = $SportLib.WagerDataToShowData(
                        teamData.SetFlag,
                        wagerData,
                        rowIndex
                      )),
                      (isShowDrewOdd = teamData.hasDrewOdds && rowIndex === 0),
                      (GameID = wagerRoIndexToGameID(wagerData, rowIndex)))
                    "
                  >
                    <template v-if="wagerData.isNoData">
                      <li class="wager-cell"> </li>
                    </template>
                    <template v-else>
                      <li
                        class="wager-cell"
                        :class="
                          WagerRowIsSelectInCartCSS(
                            GameID,
                            sportData.topPlayOdd,
                            $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                              .topWagerPos
                          )
                        "
                        @click="
                          goBet(
                            $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                              .topPlayOdd,
                            teamData,
                            wagerData,
                            rowIndex,
                            $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                              .topWagerPos
                          )
                        "
                      >
                        <Odd :OddValue="sportData.topPlayOdd" :UniqueID="`${GameID}-0`" />
                      </li>
                    </template>
                  </ul>
                </td>
              </tr>
            </template>
            <template v-else>
              <template v-for="(teamDataRowNum, rowIndex) in teamData.Row">
                <tr v-if="isFixLineShow || isLineShow(rowIndex)" :key="`${teamIndex}-${rowIndex}`">
                  <td
                    class="wager-container"
                    v-for="wagerIndex in activeColumns"
                    :key="wagerIndex"
                    :set="(wagerData = getWagers(teamData)[wagerIndex])"
                  >
                    <div v-if="isExpanded && tabs > 1 && wagerIndex % 2 === 0" class="tab-dots">
                      <div
                        v-for="tab in tabs"
                        :key="tab"
                        class="tab-dot"
                        :class="{ active: tab === activeTab }"
                      />
                    </div>
                    <ul
                      class="wager-group"
                      :set="
                        ((sportData = $SportLib.WagerDataToShowData(
                          teamData.SetFlag,
                          wagerData,
                          rowIndex
                        )),
                        (isShowDrewOdd = teamData.hasDrewOdds && rowIndex === 0),
                        (GameID = wagerRoIndexToGameID(wagerData, rowIndex)))
                      "
                    >
                      <template v-if="wagerData.isNoData">
                        <!-- 如果有和 -->
                        <template v-if="isShowDrewOdd">
                          <li class="wager-cell"> </li>
                          <li class="wager-cell"> </li>
                          <li class="wager-cell"> </li>
                        </template>
                        <template v-else>
                          <li class="wager-cell"> </li>
                          <li class="wager-cell"> </li>
                        </template>
                      </template>

                      <template v-else>
                        <!-- 只有單一個Odd Layout -->
                        <template v-if="sportData.layoutType === 'single'">
                          <li
                            class="wager-cell"
                            :class="
                              WagerRowIsSelectInCartCSS(
                                GameID,
                                sportData.topPlayOdd,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).topWagerPos
                              )
                            "
                            @click="
                              goBet(
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).topPlayOdd,
                                teamData,
                                getWagers(teamData)[wagerIndex],
                                rowIndex,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).topWagerPos
                              )
                            "
                          >
                            <Odd
                              v-if="sportData.topPlayOdd > limit"
                              :OddValue="sportData.topPlayOdd"
                              :UniqueID="`${GameID}-0`"
                            />
                          </li>
                          <li
                            class="wager-cell"
                            :class="
                              WagerRowIsSelectInCartCSS(
                                GameID,
                                sportData.bottomPlayOdd,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).bottomWagerPos
                              )
                            "
                            @click="
                              goBet(
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).bottomPlayOdd,
                                teamData,
                                getWagers(teamData)[wagerIndex],
                                rowIndex,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).bottomWagerPos
                              )
                            "
                          >
                            <Odd
                              v-if="sportData.bottomPlayOdd > limit"
                              :OddValue="sportData.bottomPlayOdd"
                              :UniqueID="`${GameID}-1`"
                            />
                          </li>
                        </template>
                        <!-- 其他正常Layout -->
                        <template v-else>
                          <li
                            class="wager-cell"
                            :class="
                              WagerRowIsSelectInCartCSS(
                                GameID,
                                sportData.topPlayOdd,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).topWagerPos
                              )
                            "
                            @click="
                              goBet(
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).topPlayOdd,
                                teamData,
                                getWagers(teamData)[wagerIndex],
                                rowIndex,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).topWagerPos
                              )
                            "
                          >
                            <div class="cell-left"> {{ sportData.topPlayMethod }} </div>
                            <div class="cell-right">
                              <Odd
                                v-if="sportData.topPlayOdd > limit"
                                :OddValue="sportData.topPlayOdd"
                                :UniqueID="`${GameID}-0`"
                              />
                            </div>
                          </li>
                          <li
                            class="wager-cell"
                            :class="
                              WagerRowIsSelectInCartCSS(
                                GameID,
                                sportData.bottomPlayOdd,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).bottomWagerPos
                              )
                            "
                            @click="
                              goBet(
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).bottomPlayOdd,
                                teamData,
                                getWagers(teamData)[wagerIndex],
                                rowIndex,
                                $SportLib.WagerDataToShowData(
                                  teamData.SetFlag,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex
                                ).bottomWagerPos
                              )
                            "
                          >
                            <div class="cell-left"> {{ sportData.bottomPlayMethod }} </div>
                            <div class="cell-right">
                              <Odd
                                v-if="sportData.bottomPlayOdd > limit"
                                :OddValue="sportData.bottomPlayOdd"
                                :UniqueID="`${GameID}-1`"
                            /></div>
                          </li>
                        </template>

                        <!-- '和' 玩法顯示 -->
                        <template v-if="isShowDrewOdd">
                          <template v-if="wagerData.isNoData">
                            <li class="wager-cell"> </li>
                          </template>
                          <template v-else>
                            <li
                              class="wager-cell"
                              :class="
                                WagerRowIsSelectInCartCSS(
                                  GameID,
                                  sportData.drewPlayOdd,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    getWagers(teamData)[wagerIndex],
                                    rowIndex
                                  ).drewWagerPos
                                )
                              "
                              @click="
                                goBet(
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    getWagers(teamData)[wagerIndex],
                                    rowIndex
                                  ).drewPlayOdd,
                                  teamData,
                                  getWagers(teamData)[wagerIndex],
                                  rowIndex,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    getWagers(teamData)[wagerIndex],
                                    rowIndex
                                  ).drewWagerPos
                                )
                              "
                            >
                              <Odd
                                v-if="sportData.drewPlayOdd > limit"
                                :OddValue="sportData.drewPlayOdd"
                                :UniqueID="`${GameID}-2`"
                              />
                            </li>
                          </template>
                        </template>
                      </template>
                    </ul>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>
    <img src="@/assets/img/mobile/btn_arrow_w.svg" class="arrow" />
  </div>
</template>

<script>
  import Odd from '@/components/Odd';
  import { GAME_CHAMPION_CAT } from '@/config/index.js';

  export default {
    name: 'mGameBetting',
    components: {
      Odd,
    },
    props: {
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      bestHead: {
        type: Array,
        default() {
          return [];
        },
      },
      isExpanded: {
        type: Boolean,
        default() {
          return false;
        },
      },
      tabs: {
        type: Number,
        required: true,
      },
      activeTab: {
        type: Number,
        required: true,
      },
      activeColumns: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        limit: 0.01,
        GAME_CHAMPION_CAT,
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
      boldFilterType() {
        return this.$store.state.Game.boldFilterType;
      },
      BestHeadWithFilterLimit() {
        if (this.isShowMenu) {
          if (this.boldFilterType) {
            return this.bestHead[1];
          } else {
            return this.bestHead[2];
          }
        } else {
          return this.bestHead[0];
        }
      },
      isFix() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 4:
              case 5:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isShowMenu() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 2:
              case 4:
                return true;
              default:
                return false;
            }
          case 102:
            switch (this.selectWagerTypeKey) {
              case 6:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isFixLineShow() {
        const { selectCatID, selectWagerTypeKey } = this;
        return (
          (selectCatID === 102 && selectWagerTypeKey === 6) ||
          (selectCatID === 101 && selectWagerTypeKey === 5)
        );
      },
    },
    watch: {
      isExpanded(isExpanded) {
        if (!isExpanded) {
          this.$el.scrollTo(0, 0);
        }
      },
      dotStatus: {
        deep: true,
        handler(status) {
          this.$emit('dotStatusChanged', status);
        },
      },
    },
    methods: {
      isLineShow(rowIndex) {
        const linesShow = this.$store.state.Setting.UserSetting.tableLines;
        if (rowIndex === 0) return true;
        return linesShow;
      },
      WagerRowIsSelectInCartCSS(GameID, showOdd, wagerPos) {
        let appendCSS = '';
        if (showOdd !== '' && showOdd !== undefined) {
          appendCSS = ' interactive';
        }
        const compareData = this.betCartList.find((cartData) => cartData.GameID === GameID);
        if (compareData && compareData.wagerPos === wagerPos) {
          appendCSS += ' isSelected';
        }
        return appendCSS;
      },
      wagerRoIndexToGameID(wagerData, rowIndex) {
        if (
          wagerData?.isNoData ||
          wagerData.Odds[rowIndex] === undefined ||
          wagerData.Odds[rowIndex].Status !== 1
        ) {
          return null;
        } else {
          return wagerData.Odds[rowIndex].GameID;
        }
      },
      goBet(showOdd, teamData, wagerData, rowIndex, wagerPos) {
        if (showOdd === '' || showOdd === undefined) {
          return;
        }

        let HomeTeamStr = teamData?.HomeTeamStr;
        let AwayTeamStr = teamData?.AwayTeamStr;
        const { selectWagerTypeKey } = this;

        if (
          !teamData.SetFlag &&
          this.selectCatID !== GAME_CHAMPION_CAT &&
          ![131, 132].includes(wagerData.WagerTypeID)
        ) {
          HomeTeamStr = teamData?.AwayTeamStr;
          AwayTeamStr = teamData?.HomeTeamStr;
        }

        const { GameType } = wagerData;
        const GameTypeLabel = this.$store.state.Game.GameTypeList.find(
          (it) => it.key === GameType
        )?.value;

        const WagerGrpName = this.bestHead[0].find((headData) => {
          return (
            headData.WagerGrpIDs.indexOf(wagerData.WagerGrpID) !== -1 &&
            headData.WagerTypeIDs.indexOf(wagerData.WagerTypeID) !== -1
          );
        })?.WagerGrpName;

        let WagerTypeName = this.$store.getters['Game/selectWagerTypeName'];
        if (this.selectCatID === 888888) {
          WagerTypeName = '';
        }
        let ItemName = '';
        if (this.selectWagerTypeKey !== 1) {
          // 首尾分
          if ([117, 118].includes(wagerData.WagerTypeID)) {
            ItemName = this.bestHead[0].find((headData) => {
              return (
                headData.WagerGrpIDs.indexOf(wagerData.WagerGrpID) !== -1 &&
                headData.WagerTypeIDs.indexOf(wagerData.WagerTypeID) !== -1
              );
            })?.showName;
          } else if (WagerGrpName && WagerGrpName !== '') {
            // 籃球 下半場
            if ([3, 102].includes(this.selectCatID) && selectWagerTypeKey === 2) {
              ItemName = `${WagerGrpName}`;
            } else if (WagerTypeName) {
              ItemName = `${WagerGrpName} ${WagerTypeName}`;
            } else {
              ItemName = WagerGrpName;
            }
          } else {
            if (wagerData.WagerGrpID === 11) {
              ItemName = `上半 ${WagerTypeName} `;
            } else {
              ItemName = `${WagerTypeName}`;
            }
          }
        } else {
          ItemName = WagerGrpName;
        }
        const { LiveTV = '', LiveTVLink = '' } = teamData;
        const betInfoData = {
          LeagueID: this.source.LeagueID,
          HomeID: teamData.HomeID,
          AwayID: teamData.AwayID,
          TimeStr: teamData.ScheduleTimeStr,
          OriginShowOdd: parseFloat(showOdd),
          wagerPos,
          GameTypeID: GameType,
          GameTypeLabel: GameTypeLabel,
          GameID: wagerData.Odds[rowIndex].GameID,
          CatID: this.source.CatID,
          CatNameStr: this.source.CatNameStr,
          ModID: this.source.ModID,
          LeagueNameStr: this.source.LeagueNameStr,
          HomeTeamStr,
          AwayTeamStr,
          WagerTypeID: wagerData.WagerTypeID,
          WagerGrpID: wagerData.WagerGrpID,
          EvtID: teamData.EvtID,
          EvtStatus: teamData.EvtStatus,
          SetFlag: teamData.SetFlag,
          ItemName,
          ParlayFlag: teamData.ParlayFlag,
          LiveTV,
          LiveTVLink,
          ...wagerData.Odds[rowIndex],
        };

        this.$store.dispatch('BetCart/addCart', betInfoData);
      },
      moreGameClickHandler(teamData) {
        this.$store.dispatch('MoreGame/openMoreGameList', {
          teamData,
        });
      },

      getWagers(teamData) {
        if (this.isShowMenu) {
          if (this.boldFilterType) {
            return teamData.Wager1;
          } else {
            return teamData.Wager2;
          }
        } else {
          return teamData.Wager;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $row-height: 2.4rem;
  $font-size: 1rem;

  .mGameBetting {
    position: relative;
    &::-webkit-scrollbar {
      /*隱藏滾輪*/
      display: none;
    }
    &.closed {
      width: 100%;
      position: sticky;
      left: 0;
      overflow-x: hidden;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background-color: #ccc;
        z-index: 1;
      }

      table thead {
        tr th {
          color: transparent !important;
        }
      }
      .arrow {
        display: block;
      }
    }

    table {
      position: relative;
      width: 100%;
      border-spacing: 0;
      font-size: $font-size;
      background-color: #fff;

      tr {
        position: relative;
      }

      th {
        min-width: 6.666rem;
        height: $row-height;
        background-color: #e8e8e8;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: normal;
        color: #444;
        cursor: pointer;
      }

      td {
        min-width: 6.666rem;
        height: $row-height;
        user-select: none;
      }

      td.wager-container {
        position: relative;

        .wager-group {
          .wager-cell {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            height: $row-height;
            border-style: solid;
            border-color: #e8e8e8;
            border-width: 0 1px 1px 0;
            text-align: center;
            line-height: $row-height;

            &.fix2 {
              height: $row-height * 3;
            }

            &.interactive {
              cursor: pointer;
            }
            &.isSelected {
              background-color: #ffd5d5;
            }

            .cell-left {
              flex: 1;
              text-align: right;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .cell-right {
              flex: 1;
              text-align: left;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: bold;
            }

            .Odd {
              flex: 1;
            }
          }
        }
      }

      .moreGame-holder {
        width: 35px !important;
        min-width: 35px;
        max-width: 1rem !important;
        padding: 0 0.5rem;
        text-align: center;
        // border-bottom: 1px solid #e8e8e8;
      }

      td.moreGameBtn {
        position: sticky;
        right: 0;
        top: 0;
        width: 35px !important;
        min-width: 35px;
        background-color: #fff;
        border: 1px solid #e8e8e8;
        border-width: 0px 0 1px 1px;
        text-align: center;

        span {
          font-size: 1rem;
          line-height: 1;
        }

        img {
          display: block;
          width: 14px;
          height: 14px;
          margin: 5px auto;
          // vertical-align: text-bottom;
        }

        &:active {
          background-color: #ddd;
        }
      }

      tr td:nth-last-child(2) {
        li {
          // border-right: none !important;
        }
      }
    }

    .arrow {
      display: none;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.1rem;
      filter: invert(30%);
    }
  }

  .tab-dots {
    display: flex;
    position: absolute;
    width: fit-content;
    right: 0;
    bottom: 4px;
    z-index: 1;
    transform: translate(50%);

    .tab-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #ddd;
      margin-right: 5px;

      &.active {
        background-color: #a3d3c3;
      }
    }
  }

  #app[data-theme^='light'] {
    .mGameBetting {
      table {
        background-color: #ffffff;
        th {
          background-color: #e8e8e8;
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    .mGameBetting {
      table {
        background-color: #585858;
        th {
          background-color: #696969;
          color: #ffffff;
        }
        td {
          .wager-cell {
            color: #ffffff;
            border-color: #5f5f5f;
            &.isSelected {
              background-color: #ffffff;
              color: #000;
            }
          }
          &.moreGameBtn {
            background-color: #585858;
            border-color: #5f5f5f;
            color: #ffffff;
          }
        }
      }
      &.closed::after {
        background-color: #4f4f4f;
      }
    }
  }
</style>
