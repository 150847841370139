<template>
  <div id="GameTableList" ref="GameTableList">
    <template v-if="ok">
      <template v-if="multipleMode">
        <template v-if="isMultipleEmpty">
          <div class="EmptyGameTable">
            <div class="EmptyCenterItemBlock">
              <img src="@/assets/img/pc/icon_noGame.svg" />
              <p>{{ $t('Common.NoGame') }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <GameTableHeader
            :top="true"
            :CatName="topName"
            :isCollapse="isTopCollapse()"
            :BestHead="[[]]"
            :isShowMoreGameEntryBtn="false"
            :columnLimit="columnLimit"
            @ArrowClick="toggleTopCollapse()"
          />
          <div class="ScrollViewContainer" ref="ScrollViewContainer">
            <div
              v-for="(tableItem, tableIndex) in tableList"
              :key="tableIndex"
              class="gameContainer"
            >
              <GameTableHeader
                :tableItem="selectTableItem"
                :isCollapse="isTableCollapse(tableItem)"
                :CatName="tableItem.CatName"
                :CatID="tableItem.CatID"
                :BestHead="tableItem.Items.BestHead"
                :isShowMoreGameEntryBtn="tableItem.Items.hasMoreCount"
                :columnLimit="columnLimit"
                @ArrowClick="toggleTableCollapse(tableItem)"
              />
              <div v-for="(leagueItem, leagueIndex) in tableItem.Items.List" :key="leagueIndex">
                <GameCollapse
                  :index="leagueIndex"
                  :source="leagueItem"
                  :isCollapse="isLeagueCollapse(tableItem, leagueItem)"
                  :columnLimit="columnLimit"
                  :BestHead="tableItem.Items.BestHead"
                  @collapseChange="toggleLeagueCollapse(tableItem, leagueItem)"
                  @AddToCart="$emit('AddToCart')"
                />
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <template v-if="isEmpty">
          <div class="EmptyGameTable">
            <div class="EmptyCenterItemBlock">
              <img src="@/assets/img/pc/icon_noGame.svg" />
              <p>{{ $t('Common.NoGame') }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <GameTableHeader
            :tableItem="selectTableItem"
            :isCollapse="isTableCollapse(selectTableItem)"
            :CatName="selectTableItem.CatName"
            :CatID="selectTableItem.CatID"
            :BestHead="selectTableItem.Items.BestHead"
            :isShowMoreGameEntryBtn="selectTableItem.Items.hasMoreCount"
            :columnLimit="columnLimit"
            @ArrowClick="toggleTableCollapse(selectTableItem)"
          />
          <div class="ScrollViewContainer" ref="ScrollViewContainer">
            <div class="gameContainer">
              <div
                v-for="(leagueItem, leagueIndex) in selectTableItem.Items.List"
                :key="leagueIndex"
              >
                <GameCollapse
                  :index="leagueIndex"
                  :source="leagueItem"
                  :isCollapse="isLeagueCollapse(selectTableItem, leagueItem)"
                  :columnLimit="columnLimit"
                  :BestHead="selectTableItem.Items.BestHead"
                  @collapseChange="toggleLeagueCollapse(selectTableItem, leagueItem)"
                  @AddToCart="$emit('AddToCart')"
                />
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
  import GameTableHeader from './GameTableHeader.vue';
  import GameCollapse from './GameCollapse.vue';
  import { GAME_FAVORITE_CAT, GAME_HOT_CAT } from '@/config/index.js';

  export default {
    props: {
      ok: { type: Boolean, value: false },
    },
    components: {
      GameTableHeader,
      GameCollapse,
    },
    data() {
      return {
        tableCollapseList: [],
        leagueCollapseMap: {},
        columnLimit: 10,
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      selectTableItem() {
        return this.tableList[0];
      },
      tableList() {
        const { selectCatID } = this;
        if (this.$store.state.Game.searchMode) return this.gameStore.GameList;
        if ([GAME_FAVORITE_CAT, GAME_HOT_CAT].includes(selectCatID)) return this.gameStore.GameList;
        return this.$store.getters['Game/GameListFilterBySelectLeague'];
      },
      isEmpty() {
        const { tableList } = this;
        return tableList.length === 0;
      },
      multipleMode() {
        const { selectCatID, selectWagerTypeKey } = this;
        return (
          this.$store.state.Game.searchMode ||
          selectCatID === GAME_FAVORITE_CAT ||
          (selectCatID === GAME_HOT_CAT && selectWagerTypeKey === GAME_HOT_CAT)
        );
      },
      isMultipleEmpty() {
        const { tableList } = this;
        if (tableList.length > 0) {
          for (const tableItem of tableList) {
            if (tableItem.Items.List.length > 0) return false;
          }
        }
        return true;
      },
      topName() {
        const { selectCatID } = this;
        if (this.$store.state.Game.searchMode) {
          return '關鍵字查詢: ' + this.$store.state.Game.searchKey;
        }
        if (selectCatID === GAME_FAVORITE_CAT) return this.$t('Common.Collect');
        if (selectCatID === GAME_HOT_CAT) {
          const typeMenu = this.$store.getters['Game/selectMenuCatList'];
          const hotMenu = typeMenu.find((item) => item.catid === GAME_HOT_CAT);
          return hotMenu ? hotMenu.catName : this.$t('Common.HotGame');
        }
        return '';
      },
      isShowMoreGame() {
        return this.$store.state.MoreGame.isShowMoreGame;
      },
      isShowGameBet() {
        return this.$store.state.Game.isShowGameBet;
      },
    },
    watch: {
      selectGameType() {
        this.leagueCollapseMap.length = 0;
        this.clearColapse();
        this.scrollToTop();
      },
      selectCatID() {
        this.leagueCollapseMap.length = 0;
        this.clearColapse();
        this.scrollToTop();
      },
      selectWagerTypeKey() {
        this.leagueCollapseMap.length = 0;
        this.clearColapse();
        this.scrollToTop();
        this.$store.commit('Game/setBoldFilterType', true);
      },
      isShowMoreGame() {
        this.updateColumnLimit();
      },
      isShowGameBet() {
        this.updateColumnLimit();
      },
    },
    methods: {
      scrollToTop() {
        const { ScrollViewContainer } = this.$refs;
        if (ScrollViewContainer) {
          ScrollViewContainer.scrollTop = 0;
        }
      },
      clearColapse() {
        this.tableCollapseList = [];
        this.leagueCollapseMap = {};
      },
      updateColumnLimit() {
        const { isShowMoreGame, isShowGameBet } = this;
        if (isShowMoreGame && isShowGameBet) {
          this.columnLimit = 2;
        } else if (isShowGameBet) {
          this.columnLimit = 6;
        } else if (isShowMoreGame) {
          this.columnLimit = 3;
        } else {
          this.columnLimit = 10;
        }
      },
      isTopCollapse() {
        const { tableCollapseList, tableList } = this;
        return tableCollapseList.length === tableList.length;
      },
      isTableCollapse(tableItem) {
        const { CatName: tableKey } = tableItem;
        const { leagueCollapseMap } = this;
        if (!leagueCollapseMap[tableKey]) return false;
        const collapseList = leagueCollapseMap[tableKey];
        return collapseList.length === tableItem.Items.List.length;
      },
      isLeagueCollapse(tableItem, leagueItem) {
        const { leagueCollapseMap } = this;
        const { CatName: tableKey } = tableItem;
        const { LeagueID: leagueKey } = leagueItem;
        if (!leagueCollapseMap[tableKey]) return false;
        const collapseList = leagueCollapseMap[tableKey];
        return collapseList.includes(leagueKey);
      },
      toggleTopCollapse() {
        const { tableCollapseList, leagueCollapseMap, tableList } = this;
        if (tableCollapseList.length === tableList.length) {
          tableCollapseList.length = 0;
          tableList.forEach((item) => {
            const { CatName: tableKey } = item;
            leagueCollapseMap[tableKey].length = 0;
          });
        } else {
          tableCollapseList.length = 0;
          tableList.forEach((tableItem) => {
            const { CatName: tableKey } = tableItem;
            tableCollapseList.push(tableKey);
            if (!leagueCollapseMap[tableKey]) {
              leagueCollapseMap[tableKey] = [];
            }
            const collapseList = leagueCollapseMap[tableKey];
            collapseList.length = 0;
            tableItem.Items.List.forEach((item) => {
              collapseList.push(item.LeagueID);
            });
          });
        }
        this.$forceUpdate();
      },
      toggleTableCollapse(tableItem) {
        const { CatName: tableKey } = tableItem;
        const { tableCollapseList, leagueCollapseMap } = this;
        if (!leagueCollapseMap[tableKey]) {
          leagueCollapseMap[tableKey] = [];
        }
        const collapseList = leagueCollapseMap[tableKey];
        if (collapseList.length === tableItem.Items.List.length) {
          collapseList.length = 0;
          const tableIndex = tableCollapseList.indexOf(tableKey);
          if (tableIndex !== -1) {
            tableCollapseList.splice(tableIndex, 1);
          }
        } else {
          collapseList.length = 0;
          tableItem.Items.List.forEach((item) => {
            const { LeagueID: leagueKey } = item;
            collapseList.push(leagueKey);
          });
          const tableIndex = tableCollapseList.indexOf(tableKey);
          if (tableIndex === -1) {
            tableCollapseList.push(tableKey);
          }
        }
        this.$forceUpdate();
      },
      toggleLeagueCollapse(tableItem, leagueItem) {
        const { CatName: tableKey } = tableItem;
        const { LeagueID: leagueKey } = leagueItem;
        const { tableCollapseList, leagueCollapseMap } = this;
        if (!leagueCollapseMap[tableKey]) {
          leagueCollapseMap[tableKey] = [];
        }
        const collapseList = leagueCollapseMap[tableKey];
        const leagueIndex = collapseList.indexOf(leagueKey);
        if (leagueIndex === -1) {
          collapseList.push(leagueKey);
          if (collapseList.length === tableItem.Items.List.length) {
            tableCollapseList.push(tableKey);
          }
        } else {
          collapseList.splice(leagueIndex, 1);
          const tableIndex = tableCollapseList.indexOf(tableKey);
          if (tableIndex !== -1) {
            tableCollapseList.splice(tableIndex, 1);
          }
        }
        this.$forceUpdate();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  @import './GameTable.scss';

  #app[data-theme^='light'] {
    #GameTableList {
      background-color: #d5d5d5;
    }
  }

  #app[data-theme^='dark'] {
    #GameTableList {
      background-color: #585858;
    }
  }

  #GameTableList {
    @include main_bg_border_color();

    flex: 1 1 fit-content;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;

    .EmptyGameTable {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .ScrollViewContainer {
      height: calc(100% - 35px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
</style>
