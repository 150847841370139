<template>
  <div
    id="standalone-live"
    :class="{
      moving: isMoving,
      resizing: resing,
      min: showMode === minMode,
      max: showMode === maxMode,
      full: showMode === fullscreenMode,
    }"
  >
    <div v-if="isMoving" class="live-bg" />
    <div class="live-bar">
      <div
        class="live-title"
        @mousedown.stop="beginMoving($event)"
        @touchstart.stop="beginMoving($event)"
        @click.stop="restoreRect()"
      >
        <img class="title-icon" src="@/assets/img/common/btn_GDV_live.svg" />
        <span class="title-name">{{ standaloneName }}</span>
      </div>
      <div class="live-buttons">
        <img
          class="button minimize"
          src="@/assets/live/minimize.svg"
          :title="$t('live.min')"
          @click.stop="minimize()"
        />
        <img
          class="button maximize"
          src="@/assets/live/maximize.svg"
          :title="$t('live.max')"
          @click.stop="maximize()"
        />
        <img
          v-if="isSupportFullscreen"
          class="button fullscreen"
          src="@/assets/live/fullscreen.svg"
          :title="$t('live.fullscreen')"
          @click.stop="fullscreen()"
        />
        <img
          class="button close"
          src="@/assets/live/close.svg"
          :title="$t('live.close')"
          @click.stop="close()"
        />
      </div>
    </div>
    <iframe ref="liveVideoIframeRef" class="live-iframe" :src="standaloneUrl" />
    <div
      class="resize-helper"
      @mousedown.stop="beginResizing($event)"
      @touchstart.stop="beginResizing($event)"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { isSupportFullscreen } from '@/config';

  const normalMode = 0;
  const minMode = 1;
  const maxMode = 2;
  const fullscreenMode = 3;

  export default {
    data() {
      return {
        minMode,
        maxMode,
        fullscreenMode,
        showMode: normalMode,
        isMoving: false,
        isSupportFullscreen,
        resing: false,
        touchX: 0,
        touchY: 0,
      };
    },
    computed: {
      ...mapState('MoreGame', [
        'isShowStandalone',
        'standaloneUrl',
        'standaloneName',
        'standaloneLeft',
        'standaloneTop',
        'standaloneWidth',
        'standaloneHeight',
      ]),
    },
    methods: {
      ...mapMutations('MoreGame', [
        'showStandaloneLive',
        'hideStandaloneLive',
        'setStandaloneLiveRect',
      ]),
      getDefaultWidth() {
        return 320;
      },
      getDefaultHeight() {
        return 240;
      },
      getDefaultLeft(width) {
        const { innerWidth } = window;
        const left = parseInt((innerWidth - width) / 2);
        return left > 0 ? left : 0;
      },
      getDefaultTop(height) {
        const { innerHeight } = window;
        const top = parseInt((innerHeight - height) / 2);
        return top > 0 ? top : 0;
      },
      backupRect() {
        const { $el } = this;
        const { left, top, width, height } = $el.style;
        this.setStandaloneLiveRect({ left, top, width, height });
      },
      restoreRect() {
        if (this.showMode !== normalMode) {
          const {
            $el,
            standaloneLeft: left,
            standaloneTop: top,
            standaloneWidth: width,
            standaloneHeight: height,
          } = this;
          const { style } = $el;
          this.showMode = normalMode;
          style.left = left + 'px';
          style.right = 'unset';
          style.top = top + 'px';
          style.bottom = 'unset';
          style.width = width + 'px';
          style.height = height + 'px';
        }
      },
      minimize() {
        this.backupRect();
        this.showMode = minMode;
      },
      maximize() {
        this.backupRect();
        this.showMode = maxMode;
      },
      fullscreen() {
        const { $refs } = this;
        const { liveVideoIframeRef } = $refs;
        if (liveVideoIframeRef) {
          this.backupRect();
          this.showMode = fullscreenMode;
          liveVideoIframeRef.requestFullscreen();
        }
      },
      close() {
        this.backupRect();
        this.hideStandaloneLive();
      },
      beginMoving(event) {
        const selection = window.getSelection;
        if (selection) {
          if (selection.empty) {
            selection.empty();
          } else if (selection.removeAllRanges) {
            selection.removeAllRanges();
          }
        } else if (document.selection) {
          document.selection.empty();
        }
        if (event.type === 'touchstart') {
          const touch = event.touches[0];
          this.touchX = touch.pageX;
          this.touchY = touch.pageY;
        }
        this.isMoving = true;
        window.addEventListener('touchmove', this.updateMoving, { passive: false, capture: true });
        window.addEventListener('mousemove', this.updateMoving, { passive: false, capture: true });
        window.addEventListener('mouseup', this.endMoving, { passive: false, capture: true });
        window.addEventListener('touchend', this.endMoving, { passive: false, capture: true });
      },
      endMoving() {
        this.isMoving = false;
        window.removeEventListener('touchmove', this.updateMoving, {
          passive: false,
          capture: true,
        });
        window.removeEventListener('mousemove', this.updateMoving, {
          passive: false,
          capture: true,
        });
        window.removeEventListener('mouseup', this.endMoving, { passive: false, capture: true });
        window.removeEventListener('touchend', this.endMoving, { passive: false, capture: true });
      },
      updateMoving(event) {
        if (this.isMoving) {
          event.preventDefault();
          const { movementX, movementY } = event;
          const { innerWidth, innerHeight } = window;
          const { $el } = this;
          const { style } = $el;
          const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = $el;
          let newLeft;
          let newTop;
          if (event.type === 'touchmove') {
            const touch = event.touches[0];
            newLeft = offsetLeft + (touch.pageX - this.touchX);
            newTop = offsetTop + (touch.pageY - this.touchY);
            this.touchX = touch.pageX;
            this.touchY = touch.pageY;
          } else {
            newLeft = offsetLeft + movementX;
            newTop = offsetTop + movementY;
          }
          if (newLeft + offsetWidth > innerWidth) {
            newLeft = innerWidth - offsetWidth;
          }
          if (newLeft < 0) {
            newLeft = 0;
          }
          if (newTop + offsetHeight > innerHeight) {
            newTop = innerHeight - offsetHeight;
          }
          if (newTop < 0) {
            newTop = 0;
          }
          style.left = newLeft + 'px';
          style.top = newTop + 'px';
        }
      },
      beginResizing(event) {
        if (event.type === 'touchstart') {
          const touch = event.touches[0];
          this.touchX = touch.pageX;
          this.touchY = touch.pageY;
        }
        this.resing = true;
        window.addEventListener('touchmove', this.updateResizing, {
          passive: false,
          capture: true,
        });
        window.addEventListener('mousemove', this.updateResizing, {
          passive: false,
          capture: true,
        });
        window.addEventListener('mouseup', this.endResizing, { passive: false, capture: true });
        window.addEventListener('touchend', this.endResizing, { passive: false, capture: true });
      },
      updateResizing(event) {
        if (this.resing) {
          event.preventDefault();
          const { movementX, movementY } = event;
          const { innerWidth, innerHeight } = window;
          const { $el } = this;
          const { style } = $el;
          const left = parseInt(style.left);
          const top = parseInt(style.top);
          const width = parseInt(style.width);
          const height = parseInt(style.height);
          let x;
          let y;
          if (event.type === 'touchmove') {
            const touch = event.touches[0];
            x = touch.pageX - this.touchX;
            y = touch.pageY - this.touchY;
            this.touchX = touch.pageX;
            this.touchY = touch.pageY;
          } else {
            x = movementX;
            y = movementY;
          }
          if (left + x + width > innerWidth) {
            x = innerWidth - width - left;
          }
          if (top + y + height > innerHeight) {
            y = innerHeight - height - top;
          }
          style.width = width + x + 'px';
          style.height = height + y + 'px';
        }
      },
      endResizing(event) {
        this.resing = false;
        window.removeEventListener('touchmove', this.updateResizing, {
          passive: false,
          capture: true,
        });
        window.removeEventListener('mousemove', this.updateResizing, {
          passive: false,
          capture: true,
        });
        window.removeEventListener('mouseup', this.endResizing, { passive: false, capture: true });
        window.removeEventListener('touchend', this.endResizing, { passive: false, capture: true });
      },
    },
    mounted() {
      const {
        $el,
        standaloneLeft: left,
        standaloneTop: top,
        standaloneWidth: width,
        standaloneHeight: height,
      } = this;
      const { style } = $el;
      const newWidth = !width && width !== 0 ? this.getDefaultWidth() : width;
      const newHeight = !height && height !== 0 ? this.getDefaultHeight(width) : height;
      const newLeft = !left && left !== 0 ? this.getDefaultLeft(newWidth) : left;
      const newTop = !top && top !== 0 ? this.getDefaultTop(newHeight) : top;
      style.width = newWidth + 'px';
      style.height = newHeight + 'px';
      style.left = newLeft + 'px';
      style.top = newTop + 'px';
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #standalone-live {
    $barHeight: 32px;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    position: fixed;
    min-width: 240px;
    min-height: $barHeight;
    z-index: 9999;
    overflow: hidden;
    //resize: both;
    border: 1px solid #ddd;
    box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.4);
    background-color: #444;

    > .live-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.12);
    }

    > .live-bar {
      @include nav-headrtBgcolor();
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      position: relative;
      height: $barHeight;
      padding: 0 8px;

      > .live-title {
        flex: 0 1 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: #ddd;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: move;

        > .title-icon {
          width: 24px;
          margin-right: 4px;
        }

        > .title-name {
          display: block;
          line-height: 32px;
        }

        &:hover {
          color: #fff;
        }
      }

      > .live-buttons {
        flex: 0 0 fit-content;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        > .button {
          position: relative;
          width: 20px;
          height: 24px;
          margin-left: 8px;
          transition: transform 400ms ease;
          cursor: pointer;

          &:hover,
          &:active {
            transform: scale(1.2);
          }

          &.minimize {
            &:hover,
            &:active {
              transform: scale(0.8);
            }
          }
        }
      }
    }

    > .live-iframe {
      flex: 1 1 fit-content;
      border-width: 0;
    }

    > .resize-helper {
      position: absolute;
      width: 64px;
      height: 64px;
      right: -32px;
      bottom: -32px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
    }

    &.moving {
      user-select: none;

      > .live-iframe {
        pointer-events: none !important;
      }

      > .resize-helper {
        display: none;
      }
    }

    &.resizing {
      user-select: none;

      > .live-iframe {
        pointer-events: none !important;
      }

      > .resize-helper {
        background-color: rgba(255, 255, 255, 1);
      }
    }

    &.min {
      height: 32px !important;
      left: 0 !important;
      top: unset !important;
      bottom: 0 !important;
      resize: none;

      > .live-bar {
        > .live-title {
          cursor: pointer;
        }
      }

      > .resize-helper {
        display: none;
      }
    }

    &.max {
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
      resize: none;

      > .live-bar {
        > .live-title {
          cursor: pointer;
        }
      }

      > .resize-helper {
        display: none;
      }
    }

    &:active {
      border: 2px solid #fff;
      box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.8);
    }
  }
</style>
