<template>
  <div v-if="promotionShow" id="promotion">
    <div class="promotion-bg" @click="close" />
    <!--
    <section v-if="olympicShow" class="promotion-section">
      <img
        class="promotion-image"
        src="@/assets/promotion/olympic/popup.png"
        @load="olympicImageOk = true"
      />
      <div v-if="olympicImageOk" class="close-wrap">
        <label class="close-checkbox-label">
          <input v-model="olympicClosed" type="checkbox" class="close-checkbox" tabindex="-1" />
          <span class="close-text">{{ $t('Promotion.NotShow') }}</span>
        </label>
        <div class="close-button" @click="close" />
      </div>
    </section>
    -->
  </div>
</template>

<script>
  const olympicKey = 'promotion-olympic';

  export default {
    data() {
      return {
        olympicOpen: false,
        olympicClosed: false,
        olympicImageOk: false,
      };
    },
    computed: {
      promotionShow() {
        const { olympicShow } = this;
        return olympicShow;
      },
      olympicShow() {
        const { olympicOpen } = this;
        if (!olympicOpen) return false;
        const date = localStorage.getItem(olympicKey);
        if (date && new Date(date).toDateString() === new Date().toDateString()) return false;
        if (this.userSettings.olympicPromotionClose) return false;
        return true;
      },
      userSettings() {
        return this.$store.state.Setting.UserSetting;
      },
    },
    methods: {
      close() {
        const { olympicShow } = this;
        if (olympicShow) {
          localStorage.setItem(olympicKey, new Date().toDateString());
          if (this.olympicClosed) {
            this.userSettings.olympicPromotionClose = true;
          } else {
            this.userSettings.olympicPromotionClose = false;
          }
          this.$store.dispatch('Setting/save');
          this.olympicOpen = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  #promotion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    transition: all 2s;

    .promotion-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.64);
    }

    .promotion-section {
      position: relative;
      width: 80%;
      max-width: 480px;

      .promotion-image {
        position: relative;
        width: 100%;
        height: auto;
      }

      .close-wrap {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        gap: 16px;
        position: relative;
        width: 100%;
        text-align: center;

        .close-checkbox-label {
          color: #eee;
          font-size: 24px;
          cursor: pointer;

          &:hover,
          &:active {
            color: #fff;
          }

          .close-checkbox {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }

          .close-text {
          }
        }

        .close-button {
          border-color: #eee;
          color: #eee;
          cursor: pointer;

          &:after {
            content: 'x';
            font-size: 20px;
            padding: 0 7px 2px;
            border: 2px solid #eee;
            border-radius: 14px;
          }

          &:hover {
            border-color: #fff;
            color: #fff;
          }
        }
      }

      &.olympic {
        .close-wrap {
          margin-top: -12px;
        }
      }
    }
  }
</style>
