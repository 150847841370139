import { render, staticRenderFns } from "./PersonalPanel.vue?vue&type=template&id=2d2c5485&scoped=true"
import script from "./PersonalPanel.vue?vue&type=script&lang=js"
export * from "./PersonalPanel.vue?vue&type=script&lang=js"
import style0 from "./PersonalPanel.vue?vue&type=style&index=0&id=2d2c5485&prod&lang=scss"
import style1 from "./PersonalPanel.vue?vue&type=style&index=1&id=2d2c5485&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2c5485",
  null
  
)

export default component.exports