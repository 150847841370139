<template>
  <div class="liveBoard">
    <div class="table-wrapper" :style="background(102)">
      <table>
        <thead>
          <tr>
            <td></td>
            <td> 1 </td>
            <td> 2 </td>
            <td> 3 </td>
            <td> 4 </td>
            <td> OT </td>
            <td>{{ $t('Common.TotalScore') }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="teamData.hasOwnProperty('HomeTeamStr')">{{ teamData.HomeTeamStr }}</td>
            <td>{{ keyNameToShow('S1', 0) }}</td>
            <td>{{ keyNameToShow('S2', 0) }}</td>
            <td>{{ keyNameToShow('S3', 0) }}</td>
            <td>{{ keyNameToShow('S4', 0) }}</td>
            <td>{{ keyNameToShow('OT', 0) }}</td>
            <td>{{ teamData.HomeScore }}</td>
          </tr>
          <tr>
            <td v-if="teamData.hasOwnProperty('AwayTeamStr')">{{ teamData.AwayTeamStr }}</td>
            <td>{{ keyNameToShow('S1', 1) }}</td>
            <td>{{ keyNameToShow('S2', 1) }}</td>
            <td>{{ keyNameToShow('S3', 1) }}</td>
            <td>{{ keyNameToShow('S4', 1) }}</td>
            <td>{{ keyNameToShow('OT', 1) }}</td>
            <td>{{ teamData.AwayScore }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import base from './LiveBoardMixin';
  export default {
    mixins: [base],
    name: 'BasketBall',
  };
</script>

<style lang="scss" scoped>
  @import './style.scss';
  .liveBoard {
    height: 100%;
    .table-wrapper {
      height: 100%;
      table {
        thead tr td:nth-child(1) {
          width: 40%;
        }
        tbody tr td:nth-child(7) {
          color: yellow;
        }
      }
    }
  }
</style>
