<template>
  <div class="mGameResultDetail" :class="isExpanded ? '' : 'closed'">
    <table>
      <thead @click="$emit('toggleCollapse')">
        <tr>
          <th v-for="(title, i) in titles" :key="i"> {{ title.Value }}</th>
        </tr>
      </thead>
      <tbody v-show="isExpanded">
        <tr v-for="(teamData, teamIndex) in source.List" :key="teamIndex">
          <!-- 分數 -->
          <td v-for="(title, index) in titles" :key="index">
            <ul
              class="score-group"
              :class="getWinnerClassByKey(teamData, title.Key)"
              style="height: 100%"
              v-if="title.Key !== 'Remarks'"
            >
              <template v-if="isSingle">
                <li style="height: 100%">{{ teamData[title.Key] }}</li>
              </template>
              <template v-else>
                <li>{{ parseScore(teamData, title.Key)[0] }}</li>
                <li>{{ parseScore(teamData, title.Key)[1] }}</li>
              </template>
            </ul>
            <div class="remarks" v-else>{{ teamData.Remarks }}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <img src="@/assets/img/mobile/btn_arrow_w.svg" class="arrow" />
  </div>
</template>

<script>
  export default {
    name: 'mGameResultDetail',
    components: {},
    props: {
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      titles: {
        type: Array,
        default() {
          return [];
        },
      },
      isExpanded: {
        type: Boolean,
        default() {
          return false;
        },
      },
      CatID: {
        type: Number,
      },
    },
    computed: {
      isSingle() {
        return [72, 84].includes(this.CatID);
      },
    },
    watch: {
      isExpanded(isExpanded) {
        if (!isExpanded) {
          this.$el.scrollTo(0, 0);
        }
      },
    },
    methods: {
      parseScore(teamData, key) {
        const str = teamData?.[key];

        if (str && str.indexOf(':') !== -1) {
          return str.split(':');
        }
        return ['', ''];
      },
      parseDate(str) {
        const d = new Date(str);
        if (d instanceof Date && !isNaN(d)) {
          const date =
            (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
            '-' +
            (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());

          const time =
            (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) +
            ':' +
            (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes());
          return [date, time];
        }
        return ['', ''];
      },
      getWinnerClassByKey(teamData, key) {
        let [hs, as] = this.parseScore(teamData, key);
        hs = parseInt(hs);
        as = parseInt(as);
        return hs > as ? 'homeWin' : hs < as ? 'awayWin' : '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  $row-height: 2.8rem;
  $font-size: 1rem;

  .mGameResultDetail {
    position: relative;
    // overflow-x: auto;
    &::-webkit-scrollbar {
      /*隱藏滾輪*/
      display: none;
    }
    &.closed {
      width: 100%;
      position: sticky;
      left: 0;
      overflow-x: hidden;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background-color: #ccc;
        z-index: 1;
      }

      table thead {
        tr th {
          color: transparent !important;
        }
      }
      .arrow {
        display: block;
      }
    }

    table {
      position: relative;
      width: 100%;
      border-spacing: 0;
      font-size: $font-size;
      background-color: #fff;

      tr {
        position: relative;
      }

      th {
        min-width: 6.666rem;
        height: 2.4rem;
        background-color: #e8e8e8;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: normal;
        color: #444;
        cursor: pointer;
      }

      tbody {
        tr {
          border-bottom: 1px solid #e8e8e8;
          border-right: 1px solid;
        }
      }

      td {
        min-width: 6.666rem;
        height: $row-height * 2;

        ul.score-group {
          li {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            height: $row-height;
            border-style: solid;
            border-color: #e8e8e8;
            border-width: 0 1px 1px 0;
          }
          &.homeWin {
            li:nth-child(1) {
              color: #ff8800 !important;
              font-weight: 600;
            }
          }
          &.awayWin {
            li:nth-child(2) {
              color: #ff8800 !important;
              font-weight: 600;
            }
          }
        }

        .remarks {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }

    .arrow {
      display: none;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.1rem;
      filter: invert(30%);
    }
  }

  #app[data-theme^='dark'] {
    .mGameResultDetail {
      table {
        background-color: #585858;
        th {
          background-color: #696969;
          color: #ffffff;
        }
        td {
          li {
            color: #ffffff;
            border-color: #5f5f5f;
            &.isSelected {
              background-color: #ffffff;
              color: #000;
            }
          }
          .remarks {
            color: #ffffff;
            border-color: #5f5f5f;
          }
        }
      }
      &.closed::after {
        background-color: #4f4f4f;
      }
    }
  }
</style>
