import { render, staticRenderFns } from "./GamesTableList.vue?vue&type=template&id=82c04dee&scoped=true"
import script from "./GamesTableList.vue?vue&type=script&lang=js"
export * from "./GamesTableList.vue?vue&type=script&lang=js"
import style0 from "./GamesTableList.vue?vue&type=style&index=0&id=82c04dee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82c04dee",
  null
  
)

export default component.exports