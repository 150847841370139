<template>
  <ul id="m-cats" class="gameCatNav">
    <li
      v-if="hasFavorite"
      :class="['item', 'fav', { active: selectCatID == GAME_FAVORITE_CAT }]"
      @click="showFavorite()"
    >
      <img class="menu-icon" :src="getCatIcon(GAME_FAVORITE_CAT)" />
      <div class="item-text">{{ $t('Common.Collect') }}</div>
    </li>
    <li
      v-for="(catItem, index) in dynamicMenuList"
      :key="index"
      :class="['item', { active: selectCatID == catItem.catid }]"
      @click.stop="showCat(catItem)"
    >
      <img v-if="catItem.catid === 86" class="menu-icon champion" src="@/assets/img/champion.png" />
      <img v-else class="menu-icon" :src="getCatIcon(catItem.catid)" />
      <div class="item-text">{{ catItem.catName }}</div>
    </li>
  </ul>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { getMenuIconByCatID } from '@/utils/SportLib';

  export default {
    data() {
      return {
        cloneNum: 3,
        isOverflow: false,
        isPauseScrollEvent: false,
      };
    },
    computed: {
      ...mapGetters(['userSuper8Open']),
      GAME_FAVORITE_CAT() {
        return this.$conf.GAME_FAVORITE_CAT;
      },
      gameStore() {
        return this.$store.state.Game;
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      hasFavorite() {
        return this.$store.state.Setting.UserSetting.favorites.length > 0;
      },
      MenuList() {
        return this.$store.getters['Game/selectMenuCatList'];
      },
      dynamicMenuList() {
        let list = this.MenuList;
        /*
        if (this.userSuper8Open) {
          list = [super8Cat, ...list];
        }
        */
        list = [...list];
        if (this.isOverflow) {
          const clone = [...list];
          for (let i = 0; i < this.cloneNum; i++) {
            list = [...list, ...clone];
          }
        }
        return list;
      },
      orgChildrenWidth() {
        const children = Array.from(this.$el.children);
        children.length = this.MenuList.length;
        return children.reduce((acc, it) => (acc += it.clientWidth), 0);
      },
    },
    watch: {
      'MenuList.length': {
        handler() {
          this.$nextTick(() => {
            this.onScroll();
            this.setPositionToCurrentCat();
          });
        },
        immediate: false,
      },
    },
    methods: {
      getCatIcon(catId) {
        const icon = getMenuIconByCatID(catId);
        if (icon) return require('@/assets/img/common/menuIcon/' + icon);
        return '';
      },
      showFavorite() {
        const { GAME_FAVORITE_CAT, selectGameType } = this;
        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: GAME_FAVORITE_CAT,
          selectWagerTypeKey: null,
        });
        window.OddData.clear();
        this.$store.commit('MoreGame/closeMoreGameList');
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('Game/updateGameDetail', { clear: true })
          .then(() => {
            this.$store.commit('Game/changeCatReset', GAME_FAVORITE_CAT);
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      showCat(catItem) {
        const { catid: catID, Items: wagerList } = catItem;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const wagerKey = wagerList?.length ? wagerList[0].WagerTypeKey : null;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) return;
        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        window.OddData.clear();
        this.$store.commit('MoreGame/closeMoreGameList');
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('Game/updateGameDetail', { clear: true })
          .then(() => {
            this.$store.commit('Game/changeCatReset', selectCatID);
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      detectIsOverflow() {
        this.isOverflow = this.orgChildrenWidth > this.$el.clientWidth;
      },
      onScroll() {
        this.detectIsOverflow();
        if (this.isOverflow && !this.isPauseScrollEvent) {
          const { clientWidth, scrollWidth, scrollLeft } = this.$el;
          if (scrollLeft <= 0) {
            this.$el.scrollLeft = this.orgChildrenWidth * 2;
          } else if (scrollLeft >= scrollWidth - clientWidth) {
            this.$el.scrollLeft = this.orgChildrenWidth * 2 - clientWidth;
          }
        }
      },
      setPositionToCurrentCat() {
        const target = this.$el.getElementsByClassName('active')[0];
        if (target) {
          this.isPauseScrollEvent = true;
          this.$el.scrollLeft = target.offsetLeft;
          this.isPauseScrollEvent = false;
        }
      },
    },
    mounted() {
      window.addEventListener('resize', this.detectIsOverflow);
      this.$el.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.detectIsOverflow);
      this.$el.removeEventListener('scroll', this.onScroll);
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  ul.gameCatNav {
    @include base-background();
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    width: 100%;
    height: 56px;
    padding: 0;

    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    li.item {
      flex: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 64px;
      text-align: center;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.6);

      &.active {
        color: #fff;

        img.menu-icon {
          filter: brightness(1.1);

          &.champion,
          &.popular {
            filter: grayscale(0);
          }
        }
      }

      img.menu-icon {
        flex-grow: 1;
        flex-basis: 1;
        max-width: 24px;
        height: auto;
        object-fit: contain;
        opacity: 0.7;
        filter: grayscale(1) brightness(3);

        &.super8 {
          max-width: 30px;
          height: auto;
          filter: none;
          opacity: 1;
        }

        &.champion,
        &.popular {
          max-width: 30px;
          height: auto;
          filter: grayscale(0.9);
          opacity: 1;
        }
      }

      .item-text {
        flex-grow: 0;
        flex-basis: 0;
        font-size: 14px;
      }

      &.fav {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: #6da9e5;
        box-shadow: 1px 0px 6px rgb(0 0 0 / 10%);
      }
    }
  }
</style>
